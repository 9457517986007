import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { CreateNotificationTemplateContext } from "./CreateNotificationTemplateContext";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import "./UrlStep.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
const UrlStep = ({ active, setStep }) => {
  const { data, setData } = useContext(CreateNotificationTemplateContext);
  const [tempUrl, setTempUrl] = useState("");
  const handleCancel = () => {
    setStep();
  };
  const { Text, Title } = Typography;

  const [form] = Form.useForm();
  const axios = useAxiosPrivate();

  const handleChange = (e) => {
    setTempUrl(e.target.value);
  };

  const test = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    if (hasErrors) {
      message.error("Please enter a valid URL");
    } else {
      window.open(
        tempUrl,
        "popupWindow",
        "width=800,height=600,left=200,top=100,scrollbars=yes"
      );
    }
  };

  const handleOpen = () => {
    window.open(data?.url, "_blank");
  };

  const [loading, setLoading] = useState(false);
  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`/Notification/Templates/Assign-url`, {
      id: data?.id,
      url: e.Url,
    });

    if (response.data.success) {
      setData((prev) => ({ ...prev, url: e.Url }));
      setStep();
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    setTempUrl(data?.url);
    form.setFieldValue("Url", data?.url);
  }, [data?.url]);

  return (
    <>
      <Card
        size="small"
        className={`step-card no-shadow ${active ? "active-step" : ""}`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="center" justify="start" gap={12}>
            <div>
              {data?.url ? (
                <div className="step-evaluation-box-success">
                  <FaCheck />
                </div>
              ) : (
                <div className="step-evaluation-box-default"></div>
              )}
            </div>
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Url
              </Title>
              {data?.url ? (
                <Text
                  onClick={handleOpen}
                  type="link"
                  className="sender-preview-name"
                >
                  {data.url}
                </Text>
              ) : (
                <Text>
                  The URL where the user will be redirected when they click.
                </Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={handleCancel}
              className="flex-button"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <Button onClick={() => setStep(2)} size="large">
              Manage Url
            </Button>
          )}
        </Flex>

        {active && (
          <>
            <Form form={form} onFinish={submit}>
              <div  className="url-step-working-area">
                <Flex
                    className="w-100 h-100"
                    gap={6}
                    vertical
                    justify="center"
                  >
                    <Space direction="vertical" size={0}>
                      <Text className="thick-lable">Redirect URL</Text>

                      <Form.Item
                        className="no-error"
                        name="Url"
                        rules={[
                          {
                            required: true,
                          },
                          {
                            max: 300,
                          },
                          {
                            type: "url",
                          },
                        ]}
                      >
                        <Input
                          count={{
                            show: true,
                            max: 300,
                          }}
                          onChange={handleChange}
                          placeholder="example: https://tapzap.ai"
                          size="large"
                          className="w-100"
                        />
                      </Form.Item>
                    </Space>

                    <Flex align="center" justify="end">
                      <Button  onClick={test}>
                        Test
                      </Button>
                    </Flex>
                  </Flex>
                </div>
            </Form>

            <Flex
              align="center"
              justify="end"
              gap={6}
              className="url-step-working-area-footer"
            >
              <Button
                size="large"
                onClick={() => setStep()}
                             className="new-buttons large-font"
>
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
               className="new-buttons large-font"
              >
                Save
              </Button>
            </Flex>
          </>
        )}
      </Card>
    </>
  );
};

export default UrlStep;
