import {
    Button,
    Drawer,
    Flex,
    Form,
    Input,
    message,
    Select,
    Space,
    Typography,
  } from "antd";
  import { useState } from "react";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/bootstrap.css";
  import { useContext } from "react";
  import { AudienceContext } from "../AudienceContext";
  import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect } from "react";

const EditContactDrawer = ({open, setOpen, item, onEdit}) => {
    const { Text } = Typography;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const { tags } = useContext(AudienceContext);

  const axios = useAxiosPrivate();

  useEffect(() => {
    form.setFieldValue("name", item?.firstname)
    form.setFieldValue("lastname", item?.lastname)
    form.setFieldValue("email", item?.email)
    form.setFieldValue("phone", item?.phone)
    form.setFieldValue("tags",item?.tags?.map(c => c.id))
  },[item])
  
  const submit = async (e) => {
    setLoading(true);

    const response = await axios.put(`Contacts`, {
        email: e.email,
        firstname: e.name,
        lastname: e.lastname,
        phoneNumber: e.phone,
        tags: e.tags,
        id: item.id
    });

    if(response.data.success){
        onEdit(response.data);
        setOpen(false)
    }else{
        message.error(response.data.message);
    }
    
    setLoading(false);
  };

  const validateEmailOrPhone = (_, value) => {
    const phone = form.getFieldValue("phone");
    const email = form.getFieldValue("email");

    if (!phone && !email) {
      return Promise.reject(
        new Error("Either email or phone must be provided.")
      );
    }
    return Promise.resolve();
  };

    return <>
       <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title="Edit contact form"
      >
        <Flex vertical justify="space-between" style={{ height: "100%" }}>
          <Form form={form} onFinish={submit}>
            <Space direction="vertical" size={0} className="w-100">
              <Space direction="vertical" size={0} className="w-100">
                <Text>Name</Text>
                <Form.Item name="name">
                  <Input placeholder="Enter name" size="large" />
                </Form.Item>
              </Space>
              <Space direction="vertical" size={0} className="w-100">
                <Text>Lastname</Text>
                <Form.Item name="lastname">
                  <Input placeholder="Enter lastname" size="large" />
                </Form.Item>
              </Space>

              <Space direction="vertical" size={0} className="w-100">
                <Text>Email</Text>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid email!",
                    },
                    {
                      validator: validateEmailOrPhone,
                    },
                  ]}
                >
                  <Input placeholder="Enter email" size="large" />
                </Form.Item>
              </Space>
              <Space direction="vertical" size={0} className="w-100">
                <Text>Phone</Text>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      validator: validateEmailOrPhone,
                    },
                  ]}
                >
                  <PhoneInput
                    inputStyle={{ width: "100%", height: 50 }}
                    containerStyle={{ width: "100%" }}
                    country={"us"}
                    className="w-100"
                  />
                </Form.Item>
              </Space>

              <Space direction="vertical" size={0} className="w-100">
                <Text>Tags</Text>

                <Form.Item name="tags">
                  <Select
                    mode="multiple"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={tags?.map((c) => ({
                      label: c.name,
                      value: c.id,
                    }))}
                    className="w-100"
                    placeholder="Enter tags"
                    size="large"
                  />
                </Form.Item>
              </Space>
            </Space>
          </Form>

          <Flex align="center" justify="end" gap={6}>
            <Button
            size="large"
              className="new-buttons large-font" 
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => form.submit()}
              type="primary"
              size="large"
               className="new-buttons large-font"
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
}

export default EditContactDrawer