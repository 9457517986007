import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import InviteUser from "./InviteUser";
import {
  Avatar,
  Button,
  Card,
  Empty,
  Flex,
  Progress,
  Space,
  Table,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import TableLoading from "../../../shared/TableLoading";
import { SearchContext } from "../../../context/SearchContext";
import UserOptions from "./UserOptions";
import UpdateAllowances from "../UserActions/UpdateAllowances";
import UserRoles from "./UserRoles";
import CreateSenderModal from "../Senders/CreateSenderModal";
import "./UserList.css";
import { useNavigate } from "react-router-dom";

const UserList = () => {
  const { Title, Text } = Typography;
  const axiosPrivate = useAxiosPrivate();

  const [currentUser, setCurrentUser] = useState(() => null);
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(7);
  const [page, setPage] = useState(1);
  const { debouncedValue } = useContext(SearchContext);

  const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);

  const [authorizationFilter, setAuthorizationFilter] = useState(() => []);
  const [banFilter, setBanFilter] = useState(() => []);
  const [orderBy, setOrderBy] = useState(() => "");
  const [direction, setDirection] = useState(() => "");

  const [allowanceModalOpen, setAllowanceModalOpen] = useState(() => false);
  const [userRolesModalOpen, setUserRolesModalOpen] = useState(() => false);
  const [senderModal, setSenderModal] = useState(() => false);

  const handleCloseInviteModal = () => {
    setInviteModalIsOpen(false);
  };

  const handleDelete = (id) => {
    setUsers((prev) => prev.filter((c) => c.id != id));

    setTotalRows((prev) => prev - 1);
  };

  const navigate = useNavigate();

  const columns = [
    {
      title: "Data",
      render: (row) => (
        <Space>
          <Avatar
            size="large"
            icon={<UserOutlined />}
            src={`${process.env.REACT_APP_FILE_URL}${row.profileImagePath}`}
          />
          <Space className="w-100" direction="vertical" size={0}>
            <Text type="link" onClick={() => navigate(`/user/details/${row.id}`)} className="team-title">
              {row.firstname ?? "-"} {row.lastname ?? "-"}
            </Text>
            <Text>{row.email}</Text>
          </Space>
        </Space>
      ),
    },
    {
      title: "Emails",
      width: 150,
      render: (data, row) => (
        <UserAllowanceProgress value={data.emailAllowance} />
      ),
    },
    {
      title: "SMS",
      width: 150,
      render: (data, row) => (
        <UserAllowanceProgress value={data.smsAllowance} />
      ),
    },
    {
      title: "Whatsapp",
      width: 150,
      render: (data, row) => (
        <UserAllowanceProgress value={data.whatsappAllowance} />
      ),
    },
    {
      width: "50px",
      render: (row) => (
        <>
          <UserOptions
            user={row}
            setAllowanceOpen={setAllowanceModalOpen}
            allowanceOpen={allowanceModalOpen}
            onDelete={handleDelete}
            handleBan={handleBlocked}
            handleUnban={handleUnblocked}
            setCurrentUser={setCurrentUser}
            userobject={row}
            setRolesOpen={setUserRolesModalOpen}
            rolesOpen={userRolesModalOpen}
            senderModalOpen={senderModal}
            setSenderModalOpen={setSenderModal}
          />
        </>
      ),
    },
  ];

  const fetchUsers = async (page) => {
    setLoading(true);

    const response = await axiosPrivate.post(`Users`, {
      pageSize: perPage,
      page: page,
      authorizations: authorizationFilter,
      banStatus: banFilter,
      orderBy: orderBy,
      orderDirection: direction,
      search: debouncedValue,
    });

    setUsers(
      response.data.data.map((c) => {
        return { ...c, key: c.id };
      })
    );
    setTotalRows(response.data.count);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1);
  }, [authorizationFilter, banFilter, orderBy, direction, debouncedValue]);

  useEffect(() => {
    fetchUsers(page);
  }, [page, perPage]);

  const handleBlocked = (user) => {
    const updatedData = users.map((item) =>
      item.id === user ? { ...item, lockoutEnabled: true } : item
    );

    setUsers(updatedData);
  };
  const handleUnblocked = (user) => {
    const updatedData = users.map((item) =>
      item.id === user ? { ...item, lockoutEnabled: false } : item
    );

    setUsers(updatedData);
  };


  const onAllowanceUpdate = (id, emailValue, whatsappValue, smsValue) => {
    setUsers((prev) =>
      prev.map((c) =>
        c.id == id
          ? {
              ...c,
              emailAllowance: emailValue,
              smsAllowance: smsValue,
              whatsappAllowance: whatsappValue,
            }
          : c
      )
    );
  };

  const handleIsSender = (id) => {
    setUsers((prev) =>
      prev.map((c) => (c.id == id ? { ...c, isSender: true } : c))
    );
  };
  return (
    <>
      {allowanceModalOpen && (
        <UpdateAllowances
          open={allowanceModalOpen}
          id={currentUser}
          setOpen={setAllowanceModalOpen}
          onAllowanceUpdate={onAllowanceUpdate}
        />
      )}
      {userRolesModalOpen && (
        <UserRoles
          isOpen={userRolesModalOpen}
          setIsOpen={setUserRolesModalOpen}
          userId={currentUser}
          userRoles={users.find((c) => c.id == currentUser)?.userRoles}
          setUserRoles={(roles) => {
            setUsers((prev) =>
              prev.map((c) =>
                c.id == currentUser
                  ? {
                      ...c,
                      userRoles: roles,
                    }
                  : c
              )
            );
          }}
        />
      )}

      {senderModal && (
        <CreateSenderModal
          setIsOpen={setSenderModal}
          isOpen={senderModal}
          user={users?.find((c) => c.id == currentUser)}
          dataAdded={handleIsSender}
        />
      )}
      <InviteUser isOpen={inviteModalIsOpen} onClose={handleCloseInviteModal} />

      <Space style={{padding:12}} className="w-100" size={24} direction="vertical">
        <Flex align="center" justify="space-between">
          <Title level={4} style={{ margin: 0 }}>
            Team ({totalRows})
          </Title>

          <Button
            type="primary"
            size="large"
           className="new-buttons large-font"
            onClick={() => setInviteModalIsOpen(true)}
          >
            New member
          
          </Button>
        </Flex>

        <Card className="zero-margin-padding no-shadow">
          <Table
            columns={columns}
            style={{ marginTop: 1 }}
            dataSource={users}
            locale={{
              emptyText: loading ? (
                <TableLoading />
              ) : (
                <Empty description="Looks like you have reached the end" />
              ),
            }}
            pagination={{
              current: page,
              pageSize: perPage,
              total: totalRows,
              onChange: (page, pageSize) => {
                setPage(page);
                setPerPage(pageSize);
              },
            }}
          />
        </Card>
      </Space>
    </>
  );
};

const UserAllowanceProgress = ({ value }) => {
  const { Text } = Typography;
  return (
    <Flex align="center" justify="start" gap={5}>
      <Text strong>{value}%</Text>
      <div style={{ width: "70px" }}>
        <Progress showInfo={false} percent={value} strokeColor={"#422afb"} />
      </div>
    </Flex>
  );
};
export default UserList;
