import { Card, Flex, Button, Space, Tag } from "antd";
import { useContext } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "./CampaignSteps.css";
import Desing from "./Design";
import Subject from "./Subject";
import Recipients from "./Recipients";
import Sender from "./Sender";
import CreateCampaignPreviewTest from "./CreateCampaignPreviewTest";
import Schedule from "./Schedule";
import EditableName from "./EditableName";

const CampaignSteps = () => {
  const navigate = useNavigate();
  const { senders, data, setData } = useContext(CreateCampaignContext);

  const [step, setStep] = useState();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);

  const onNameUpdate = (e) => {
    setData((prev) => ({ ...prev, name: e }));
  };
  return (
    <>
      <Schedule open={scheduleOpen} setOpen={setScheduleOpen} />
      <CreateCampaignPreviewTest
        senders={senders}
        data={data}
        open={previewOpen}
        setOpen={setPreviewOpen}
      />
      <Space className="w-100" size={24} direction="vertical">
      <Flex align="center" justify="center">
        <Flex style={{width:"750px"}} align="center" justify="space-between">
          <Flex align="center" justify="start" gap={6}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              icon={<FaArrowLeft />}
              className="flex-button"
            ></Button>
            <EditableName
              onUpdate={onNameUpdate}
              id={data?.id}
              name={data?.name}
            />
            <Tag className="campaign-status-tag">Draft</Tag>
          </Flex>

          <Flex align="center" justify="end" gap={6}>
            <Button
              size="large"
              className="new-buttons large-font"
              onClick={() => setPreviewOpen(true)}
              disabled={
                !data?.senderFinished ||
                !data?.subjectFinished ||
                !data?.designStepFinished
              }
            >
              Preview & Test
            </Button>
            <Button
              onClick={() => setScheduleOpen(true)}
              type="primary"
              size="large"
              className="new-buttons large-font"
              disabled={
                !data?.senderFinished ||
                !data?.subjectFinished ||
                !data?.designStepFinished ||
                !data?.recipientsStepFinished
              }
            >
              Schedule
            </Button>
          </Flex>
        </Flex>
        </Flex>

        <Flex align="center" justify="center">
          <Space direction="vertical" size={0} className="step-wrapper">
            <Sender active={step == 1} setStep={setStep} />
            <Recipients active={step == 2} setStep={setStep} />
            <Subject active={step == 3} setStep={setStep} />
            <Desing
              active={step == 4}
              setStep={setStep}
              handleOpenPreview={() => setPreviewOpen(true)}
            />
          </Space>
        </Flex>
      </Space>
    </>
  );
};

export default CampaignSteps;
