import { Button, Divider, Flex, Form, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useDealsDetailsCtx from "../../../hooks/useDealsDetailsCtx";
import { PlusOutlined } from "@ant-design/icons";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import AddCompanyModal from "../Modals/AddCompanyModal";
import { PiBuildingsFill } from "react-icons/pi";
import QueryString from "qs";

const CompaniesMultiselect = ({
  name = "companies",
  label = "Associate Deal to Companies",
  placeholder,
  onAddNewCompany,
  showAdd,
  setShowAdd
}) => {
  const {Text} = Typography
  const axios = useAxiosPrivate();
  
  const [options, setOptions] = useState([]);
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [loading, setLoading] = useState(false)
  useEffect(() => { 
    const fetch = async () => {
    
    setLoading(true)
    const response = await axios.get('/Companies/List', {
      params:{
        from: null,
        to: null,
        page: 1,
        pageSize: 999
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, {
          arrayFormat: "repeat",
          skipNulls: true,
        });
      },
    }); 
    setOptions(response?.data?.items?.map((c, ind) => ({label: 
      <Flex align="center" justify="start" gap={5}> <PiBuildingsFill />{c.name}
      </Flex>,
       value: c.id})));
    setLoading(false)
    }
    fetch()
  }, []);

  return (
    <>
    <Space direction="vertical" size={0} className="w-100">
    <Text>{label}</Text>
      <Form.Item name={name} rules={[{ type: "array" }]}>
        <Select
          loading={loading}
          mode="multiple" size="large"
          placeholder={placeholder}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Flex style={{ padding: "0 8px 4px" }}>
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  style={{ width: "100%" }}
                  onClick={() => setAddCompanyModal(true)}
                  >
                  Create New Company
                </Button>
              </Flex>
            </>
          )}
        >
          {options.map((option) => {
            return (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
          </Space>
      <AddCompanyModal open={addCompanyModal || showAdd} setOpen={(e) => {
        setAddCompanyModal(e);
        
        if(setShowAdd){
          setShowAdd(e)
        }
        }} setOptions={setOptions} onAdd={onAddNewCompany}/>
    </>
  );
};

export default CompaniesMultiselect;
