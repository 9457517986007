import { Layout } from "antd";
import { useState } from "react";
import SideMenu from "./Navigation/SideMenu";
import { Outlet } from "react-router-dom";
import AppFooter from "./Navigation/Footer/Footer";
import ApplyCouponModal from "../pages/audience/subscription/ApplyCouponModal";
import Header from "./Navigation/Header/Header";
import { AuthorContextProvider } from "../context/AuthorsContext";
import { CallingContextProvider } from "../context/CallingContext";
import {
  CurrentUserContextProvider,
} from "../context/CurrentUserContext";

const ApplicationLayout = () => {
  const { Content } = Layout;

  const [collapsed, setCollapsed] = useState(() => true);

  const updateCollapse = (e) => {
    setCollapsed(e);
  };

  return (
    <AuthorContextProvider>
      <CallingContextProvider>
        <CurrentUserContextProvider>
          <Layout className="min-max-height">
            <SideMenu collapsed={collapsed} setCollapsed={updateCollapse} />

            <Layout className="min-max-height">
              <Header collapsed={collapsed} setCollapsed={updateCollapse} />

              <Content style={{ margin: "24px" }}>
                <Outlet />
              </Content>
              <AppFooter />
            </Layout>
            <ApplyCouponModal />
          </Layout>
        </CurrentUserContextProvider>
      </CallingContextProvider>
    </AuthorContextProvider>
  );
};

export default ApplicationLayout;
