import { ProductListContextProvider } from "./ProductListContext";
import ProductList from "./ProductList";

const ProductLayout = ({dataToAdd}) => {
  return (
    <ProductListContextProvider>
      <ProductList dataToAdd={dataToAdd}/>
    </ProductListContextProvider>
  );
};

export default ProductLayout;
