import { useContext } from "react";
import { SmsListContext } from "../SmsListContext";
import DateFilter from "../../../../shared/DateFilter";

const SmsToDateFilter = () =>{
    const {filters, setFilters} = useContext(SmsListContext)
  return (
    <DateFilter filters={filters} setFilters={setFilters}/>
  );
}


export default SmsToDateFilter