import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { SearchContext } from "../../../context/SearchContext";
import { useParams } from "react-router-dom";

export const SmsListContext = createContext({
  stats: {
    total: 0
  },
  setStats: () => {},

  filters: {
    from: null,
    to: null,
  },
  setFilter: () => {},

  loading: false,
  setLoading: () => {},

  page: 1,
  setPage: () => {},
  pageSize: 25,
  setPageSize: () => {},

  templates: [],
  setTemplates: () => {},
});

export const SmsListContextProvider = ({ children }) => {
  const { debouncedValue } = useContext(SearchContext);

  const [stats, setStats] = useState();
  const [filters, setFilters] = useState(() => ({
  }));
  const [loading, setLoading] = useState(() => false);
  const [templates, setTemplates] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const axios = useAxiosPrivate();

  const {authorId} = useParams();

  useEffect(() => {
    const fetch = async () => {
    setLoading(true);

    const response = await axios.post(`/SmsTemplate/List`, {
      page: page,
      pageSize: pageSize,

      search: debouncedValue,
      authors: filters?.selectedAuthors,

      from: filters?.from,
      to: filters?.to,

      orderBy: filters?.orderBy,
      sortDirection: filters?.orderDirection,
      authorId: authorId
    });

    setTemplates(
      response.data.items.map((c) => ({
        name: c.name,
        author: c.authorName,
        createdAt: c.createdAt,
        profile: c.authorProfile,
        text: c.text,
        id: c.id,
        lastUpdatedAt: c.lastUpdatedAt
      }))
    );

    setStats((prev) => ({
      ...prev,
      total: response.data.total,
      first: response.data.first,
      last: response.data.last,
      authorName: response.data.authorName,
      authorProfile: response.data.authorImage,
    }));
    setLoading(false);
  }
  fetch();
  }, [page, filters, debouncedValue, pageSize]);

  return (
    <SmsListContext.Provider
      value={{
        stats,
        setStats,

        filters,
        setFilters,

        loading,
        setLoading,

        page,
        setPage,

        templates,
        setTemplates,

        pageSize,
        setPageSize
      }}
    >
      {children}
    </SmsListContext.Provider>
  );
};
