import { useContext} from "react";
import { CampaignContext } from "../CampaignContext";
import DateFilter from "../../../shared/DateFilter";

const CreateFromDateFilter = () => {
  
  const { filters, setFilters } = useContext(CampaignContext);
  return <DateFilter filters={filters} setFilters={setFilters}/>;
};

export default CreateFromDateFilter;
