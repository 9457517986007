const DateFormat = {
    year: "numeric",
    month: "short",
    day: "numeric",
   
}
const DateFormatNoDay = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}

const DateFormatWithHour = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}
export {DateFormat, DateFormatNoDay, DateFormatWithHour}