import {
  Button,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";

const AddTagModal = ({ open, setOpen, onAdd }) => {
  const { Title, Text } = Typography;

  const [form] = Form.useForm();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post("Tag", {
      name: e.name,
    });

    if (response.data.success) {
      onAdd(response.data);
      setOpen(false);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        title={
          <Title level={4} className="zero-margin-padding">
            Create a new tag
          </Title>
        }
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <Flex align="center" justify="end" gap={6}>
            <Button size="large" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              Save changes
            </Button>
          </Flex>
        }
      >
        <Form form={form} onFinish={submit}>
          <Space className="w-100" size={0} direction="vertical">
            <Text className="bold">Name</Text>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter tag name"
                allowClear
                count={{
                  show: true,
                  max: 100,
                }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default AddTagModal;
