import { Button, Grid, Empty } from "antd";

const NoDataFound = ({ onAdd, addText, title, description }) => {
  return (
    <>
      <Empty imageStyle={{
        height:200
      }} description={description}>
        {addText && (
          <Button
            size="large"
            className="new-buttons large-font"
            onClick={onAdd}
          >
            {addText}
          </Button>
        )}
      </Empty>
    </>
  );
};

export default NoDataFound;
