import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import QueryString from "qs";
import { SearchContext } from "../../../context/SearchContext";
import { EmailTemplateTypes, OrderDirection } from "../../../shared/enums";
import NoDataFound from "../../../shared/NoDataFound";
import {
  Button,
  Card,
  Col,
  Flex,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import "./MyTemplates.css";
import { MdOutlineZoomOutMap } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CardsLoading from "./CardsLoading";
import { FaEye } from "react-icons/fa";

const MyTemplates = ({openPreview, onUseTemplate, isForSelect}) => {
  const navigate= useNavigate();

  const axios = useAxiosPrivate();
  const [page, setPage] = useState(1);
  const perpage = 9;
  const [templates, setTemplates] = useState();
  const { debouncedValue } = useContext(SearchContext);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState(OrderDirection.Dsc);

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { Text, Title } = Typography;
  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTemplates = async () => {
      const response = await axios.get(`/Templates/Email`, {
        params: {
          page: page,
          pageSize: perpage,
          search: debouncedValue,
          orderBy: orderBy,
          sortDirection: orderDirection,
          type: EmailTemplateTypes.UserCreated
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setLoading(false);
      setTotal(response.data.total);
      setTemplates(response.data.items);
    };

    fetchTemplates();
    return () => controller.abort();
  }, [page, debouncedValue, orderBy, orderDirection]);

  const handleOpenPreview = (e, id) => {
    e.stopPropagation();
    openPreview(id, EmailTemplateTypes.UserCreated)
  }


  const handleClick = (id, simple) => {
    if(simple){
      navigate(`/Templates/CreateSimple/${id}`)
    }else{
      navigate(`/Templates/Create/${id}`)
    }
  }

  const handleUseTemplate = (e, id) =>{
    e.stopPropagation();
    onUseTemplate(id, EmailTemplateTypes.UserCreated)
  }

  return (
    <div className="main-wrapper">
      {loading ? (
        <CardsLoading num={9} />
      ) : templates && templates.length > 0 ? (
        <Space direction="vertical" size="large">
          <Flex align="center" justify="space-between">
            <Title level={4} className="zero-padding-margin">
              Create using older templates
            </Title>
            <Flex align="center" justify="end" gap={6}>
              <Select
                size="large"
                defaultValue="createdAt"
                onChange={(e) => setOrderBy(e)}
                options={[
                  {
                    value: "createdAt",
                    label: "Template created date",
                  },
                  {
                    value: "name",
                    label: "Template name",
                  },
                ]}
              />
              <Select
                size="large"
                defaultValue={OrderDirection.Dsc}
                value={orderDirection}
                onChange={(e) => setOrderDirection(e)}
                options={[
                  {
                    value: OrderDirection.Asc,
                    label: "A - Z",
                  },
                  {
                    value: OrderDirection.Dsc,
                    label: "Z - A",
                  },
                ]}
              />
            </Flex>
          </Flex>
          <Row gutter={[12, 12]}>
            {templates.map((c, i) => (
              <Col span={8}>
                <Card className="no-shadow template-card" size="small" onClick={() => handleClick(c.id, c.simple)}>
                <div className="preivew-wrapper">
                      <div className="preview-overlay">


                      {
                          isForSelect ? <>
                          <Flex align="center" justify="center" vertical className="h-100">
                            <Space direction="vertical">
                            <Button block size="large" type="primary" onClick={(e) => handleUseTemplate(e, c.id)}>Use template</Button>
                            <Button block size="large" className="flex-button" icon={<FaEye />} onClick={(e) => handleOpenPreview(e, c.id)}>Preview</Button>
                            </Space>
                          </Flex>
                          </> : 

                        <Flex
                        className="margin-10"
                        align="center"
                        justify="end"
                        >
                          <Button
                            size="large"
                            onClick={(e) => handleOpenPreview(e, c.id)}
                            icon={<MdOutlineZoomOutMap className="icon" />}
                            className="flex-button"
                            ></Button>
                        </Flex>
                          }
                      </div>
                  {c.simple ? (
                    <Flex
                      wrap="wrap"
                      align="center"
                      justify="center"
                      className="simple-wrapper"
                    >
                      <Title
                        level={3}
                        className="center-text zero-margin-padding"
                      >
                        No preview available
                      </Title>
                    </Flex>
                  ) : (
                   
                      <img
                        className="img-preview"
                        src={`${process.env.REACT_APP_FILE_URL}${c.preview}`}
                      />
                  )}
                  </div>
                </Card>
                <Text className="title">
                  <span className="tag">#{`${i + 1} `}</span>
                  {c.name}
                </Text>
              </Col>
            ))}
          </Row>

          <Flex align="center" justify="end">
            <Pagination
              total={total}
              current={page}
              onChange={(pPage) => setPage(pPage)}
            />
          </Flex>
        </Space>
      ) : (
        <NoDataFound
          addText="Add new template"
          title="No email templates avaliable"
          description="You can create templates using the button found on Designs/Email Add desing"
        />
      )}
    </div>
  );
};

export default MyTemplates;
