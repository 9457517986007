import { Button, Card, Col, Flex, Row, Space, Typography } from "antd";
import { AiOutlineMessage } from "react-icons/ai";
import { FaGlobeAfrica } from "react-icons/fa";
import { IoMdRecording } from "react-icons/io";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./PhoneCalls.css"

const PhoneCalls = () => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();
  const handleGoToPhoneClick = () => {
    navigate('/dial/number')
  }

  return (
    <Card size="small">
      <Space className="w-100" size={24} direction="vertical">
        <Space className="w-100" size={0} direction="vertical">
          <Title className="zero-margin-padding" level={4}>
            Phone Calls
          </Title>
          <Text>
            Make, receive, and manage phone calls directly from Desktop & Mobile
          </Text>
        </Space>

        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Card onClick={handleGoToPhoneClick} size="small" className="no-shadow phone-calls-cards">
              <Flex vertical align="center" justify="center">
                <AiOutlineMessage className="home-follow-up-icon" />
                <Title level={4} className="zero-margin-padding">
                    Make calls globally
                </Title>
                <Text className="text-center">Tapzap supports most countries when it comes to calls</Text>
              </Flex>
            </Card>
          </Col>
          <Col span={12}>
            <Card onClick={handleGoToPhoneClick} size="small" className="no-shadow phone-calls-cards">
              <Flex vertical align="center" justify="center">
                <MdOutlinePhoneInTalk className="home-follow-up-icon" />
                <Title level={4} className="zero-margin-padding">
                  All in one place
                </Title>
                <Text className="text-center">The tapzap dialer is created for easy and seamless use</Text>
              </Flex>
            </Card>
          </Col>
          <Col span={12}>
            <Card onClick={handleGoToPhoneClick} size="small" className="no-shadow phone-calls-cards">
              <Flex vertical align="center" justify="center">
                <IoMdRecording className="home-follow-up-icon" />
                <Title level={4} className="zero-margin-padding">
                  Record your calls
                </Title>
                <Text className="text-center">Record all your calls and take a look at them later!</Text>
              </Flex>
            </Card>
          </Col>
          <Col span={12}>
            <Card onClick={handleGoToPhoneClick} size="small" className="no-shadow phone-calls-cards">
              <Flex vertical align="center" justify="center">
                <FaGlobeAfrica className="home-follow-up-icon" />
                <Title level={4} className="zero-margin-padding">
                  Transcribe in most languages
                </Title>
                <Text className="text-center">Tapzap will transcribe all your calls on the language of the call!</Text>
              </Flex>
            </Card>
          </Col>
        </Row>

        <Flex className="w-100" align="center" justify="center">
          <Button onClick={handleGoToPhoneClick} type="link" className="bold" size="large">
            Go to phone
          </Button>
        </Flex>
      </Space>
    </Card>
  );
};

export default PhoneCalls;
