import { useState, useEffect } from "react";
import { Button, Drawer, Space, Select, Typography, notification, Flex } from "antd";

const AudioSettingsDrawer = ({ open, setOpen }) => {
  const { Text } = Typography;
  const [inputDevices, setInputDevices] = useState([]);
  const [outputDevices, setOutputDevices] = useState([]);
  const [selectedInput, setSelectedInput] = useState("");
  const [selectedOutput, setSelectedOutput] = useState("");

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const inputs = devices.filter((device) => device.kind === "audioinput");
        const outputs = devices.filter(
          (device) => device.kind === "audiooutput"
        );

        setInputDevices(inputs);
        setOutputDevices(outputs);

        const defaultInput = inputs.find((device) => device.deviceId === "default");
        const defaultOutput = outputs.find((device) => device.deviceId === "default");

        setSelectedInput(defaultInput?.deviceId || "");
        setSelectedOutput(defaultOutput?.deviceId || "");
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    fetchDevices();
  }, []);

  const setDefaultInput = async (deviceId) => {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: deviceId } },
      });
    
    } catch (error) {
      notification.error({
        message: "Error setting input device",
        description: error.message,
      });
    }
  };

  const setDefaultOutput = (deviceId) => {
    const audio = document.createElement("audio");
    if (audio.setSinkId) {
      audio
        .setSinkId(deviceId)
        .then(() => {
        })
        .catch((error) => {
          notification.error({
            message: "Error setting output device",
            description: error.message,
          });
        });
    } else {
      notification.error({
        message: "Not supported",
        description: "Your browser does not support setting output devices.",
      });
    }
  };

  const handleSave = () => {
    if (selectedInput) {
      setDefaultInput(selectedInput);
    }
    if (selectedOutput) {
      setDefaultOutput(selectedOutput);
    }
    setOpen(false);
  };

  return (
    <Drawer open={open} onClose={() => setOpen(false)} title="Audio settings">
      <Flex className="w-100 h-100" align="center" justify="space-between" vertical>

      <Space className="w-100" size="large" direction="vertical">
        <Text>Change your audio settings</Text>

        <Space className="w-100" direction="vertical" size={24}>
          <Space className="w-100" direction="vertical" size={0}>
            <Text className="bold">Input</Text>
            <Select
              className="w-100"
              size="large"
              value={selectedInput}
              onChange={(value) => setSelectedInput(value)}
            >
              {inputDevices.map((device) => (
                <Select.Option key={device.deviceId} value={device.deviceId}>
                  {device.label || "Unknown input device"}
                </Select.Option>
              ))}
            </Select>
          </Space>

          <Space className="w-100" direction="vertical" size={0}>
            <Text className="bold">Output</Text>
            <Select
              className="w-100"
              size="large"
              value={selectedOutput}
              onChange={(value) => setSelectedOutput(value)}
            >
              {outputDevices.map((device) => (
                <Select.Option key={device.deviceId} value={device.deviceId}>
                  {device.label || "Unknown output device"}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </Space>
      </Space>

      <Flex align="center" justify="end" className="w-100" gap={6}>
        <Button size="large" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button type="primary" size="large" onClick={handleSave}>
          Save changes
        </Button>
      </Flex>
      </Flex>
    </Drawer>
  );
};

export default AudioSettingsDrawer;
