import { Avatar, Flex, Tag } from "antd";

const InitilasAvatar = ({
  name,
  src,
  size,
  displayFull = false,
  large = false,
}) => {


  function getInitials(name) {
    if (!name) {
      return "!";
    }

    let names = name.split(" ");

    let initials = names.map((n) => n[0]).join("");

    return initials.toUpperCase();
  }

  return (
    <>
      {!large ? (
        <Avatar size={size} src={src}>
          {!displayFull ? getInitials(name) : name}
        </Avatar>
      ) : (
        <Tag className={"black-color-tag"} color={ "#e3e3e3"} style={{margin:0, padding:"0px 5px 0px 0px"}}>
          <Flex align="center" justify="start" gap={6} style={{padding:2, margin:0}}>
            <Avatar size="small" src={src} style={{color: "black"}}>
            {getInitials(name)}
            </Avatar>
            {name}
          </Flex>
        </Tag>
      )}
    </>
  );
};

export default InitilasAvatar;
