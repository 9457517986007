import { Button, Card, Flex, Space, Typography } from "antd";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const NotificationStep = ({active, setStep}) => {
    const { Text, Title } = Typography;

  const data = {}
  const handleCancel = () => {
    setStep()
  }

  return (
    <>
      <Card
        size="small"
        className={`step-card no-shadow last-card ${
          active ? "active-step" : ""
        }`}
      >
        <Flex className="w-100" align="center" justify="space-between">
          <Flex align="cesnter" justify="start" gap={12}>
            <div>
              {data?.header ? (
                <div className="step-evaluation-box-success">
                  <FaCheck />
                </div>
              ) : (
                <div className="step-evaluation-box-default"></div>
              )}
            </div>
            <Space direction="vertical" size={0}>
              <Title className="step-card-title zero-margin-padding" level={3}>
                Notification Design
              </Title>
              {data?.header ? (
                <Text className="sender-preview-name">{data.header}</Text>
              ) : (
                <Text>Notification to send to your client</Text>
              )}
            </Space>
          </Flex>
          {active ? (
            <Button
              type="text"
              onClick={handleCancel}
              disabled
              className="flex-button"
              size="large"
              icon={<MdClose className="icon" />}
            ></Button>
          ) : (
            <Button disabled onClick={() => setStep(2)} size="large">
              Start designing
            </Button>
          )}
        </Flex>
      </Card>
    </>
  );
}

export default NotificationStep;