import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { SearchContext } from "../../context/SearchContext";
import QueryString from "qs";
import { EmailTemplateTypes, OrderDirection } from "../../shared/enums";
import { AuthorContext } from "../../context/AuthorsContext";
import { DateFormat } from "../../dateformat";
import { useParams } from "react-router-dom";

export const TemplateContext = createContext({
  filters: {
    from: null,
    to: null,
    orderBy: "created",
    orderDirection: OrderDirection.Dsc,
  },
  setFilters: () => {},

  stats: {
    total: 0,
    simple: 0,
  },
  setStats: () => {},

  templates: [],
  setTemplates: () => {},

  page: 1,
  setPage: () => {},

  pageSize: 25,
  setPageSize: () => {},

  loading: false,
  setLoading: () => {},
});

export const TemplateContextProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => ({
    from: null,
    to: null,
    orderBy: "created",
    orderDirection: OrderDirection.Dsc,
  }));


  const [stats, setStats] = useState({
    total: 0,
    first: null,
    last: null,
    simple: 0,
  });
  const [templates, setTemplates] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();
  const { debouncedValue } = useContext(SearchContext);

  const [pageSize, setPageSize] = useState(25);

  const {authorId} = useParams();

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetch = async () => {
      let response = await axios.get(`/Templates/Email`, {
        params: {
          search: debouncedValue,
          orderBy: filters?.orderBy,
          sortDirection: filters?.orderDirection,
          page: page,
          pageSize: pageSize,
          type: EmailTemplateTypes.UserCreated,
          authors: filters?.selectedAuthors,
          from: filters?.from ? new Date(filters.from) : null,
          to: filters?.to ? new Date(filters?.to) : null,
          authorId: authorId
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setStats((prev) => ({
        total: response.data.total,
        simple: response.data.simple,
      }));

      setTemplates(response.data.items);
      setLoading(false);
    };

    fetch();

    return () => controller.abort();
  }, [filters, page, debouncedValue, pageSize]);
  return (
    <TemplateContext.Provider
      value={{
        filters,
        setFilters,
        loading,
        setLoading,
        templates,
        setTemplates,
        page,
        setPage,
        stats,
        setStats,
        pageSize,
        setPageSize
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};
