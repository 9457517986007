import { Button, Flex, Select, Space, Typography } from "antd";
import { MdArrowDropDown } from "react-icons/md";
import { useContext, useState } from "react";
import { AudienceContext } from "../AudienceContext";
import { FiPlus } from "react-icons/fi";
import AddTagModal from "./AddTagModal";

const ContactTagsFilter = () => {
  const { filters, setFilters, tags, setTags } = useContext(AudienceContext);
  const { Text } = Typography;

  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      tags: e,
    }));
  };

  const [addTagOpen, setAddTagOpen] = useState(false);

  const onTagAdd = (e) => {
    setTags(prev => [...prev, e])
  }
  return (
    <>
      <AddTagModal onAdd={onTagAdd} open={addTagOpen} setOpen={setAddTagOpen} />
       <Flex align="center" justify="start" gap={6}>
          <Select
            size="large"
            style={{width:200}}
            maxTagCount="responsive"
            suffixIcon={
              <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
            }
            placeholder="Select tags"
            mode="multiple"
            options={tags?.map((c) => ({
              label: c.name,
              value: c.id,
            }))}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={filters?.tags}
            value={filters?.tags}
            onChange={handleChanged}
          />
            <Button onClick={() => setAddTagOpen(true)} type="link" className="bold">
            Add tag
          </Button>
        </Flex>
    </>
  );
};

export default ContactTagsFilter;
