import { createContext, useContext, useEffect, useState } from "react";
import { OrderDirection } from "../../../../shared/enums";
import { AuthorContext } from "../../../../context/AuthorsContext";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { SearchContext } from "../../../../context/SearchContext";
import QueryString from "qs";
import { DateFormat } from "../../../../dateformat";

export const SmsSenderListContext = createContext({
  filters: {
    from: null,
    to: null,
    selectedAuthors: [],
    orderBy: "created",
    orderDirection: OrderDirection.Dsc,
  },
  setFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    local: 0,
  },
  setStats: () => {},

  senders: [],
  setSenders: () => {},

  page: 1,
  setPage: () => {},

  loading: false,
  setLoading: () => {},
});

export const SmsSenderListContextProvider = ({ children }) => {
  const { you } = useContext(AuthorContext);

  const [filters, setFilters] = useState(() => ({
    selectedAuthors: [you],
    from: null,
    to: null,
    orderBy: "created",
    orderDirection: OrderDirection.Dsc,
  }));

  const [stats, setStats] = useState({
    total: 0,
    first: null,
    last: null,
    local: 0,
  });

  const [senders, setSenders] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const perPage = 7;
  const axios = useAxiosPrivate();
  const { debouncedValue } = useContext(SearchContext);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const fetch = async () => {
      let response = await axios.get(`/phone-numbers`, {
        params: {
          search: debouncedValue,
          orderBy: filters?.orderBy,
          sortDirection: filters?.orderDirection,
          page: page,
          pageSize: perPage,
          authors: filters?.selectedAuthors,
          from: filters?.from ? new Date(filters.from) : null,
          to: filters?.to ? new Date(filters?.to) : null,
        },
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });

      setStats((prev) => ({
        total: response.data.total,
        first: response.data.first
          ? new Date(response.data.first * 1000).toLocaleDateString(
              "en-us",
              DateFormat
            )
          : "-",
        last: response.data.last
          ? new Date(response.data.last * 1000).toLocaleDateString(
              "en-us",
              DateFormat
            )
          : "-",
        local: response.data.local,
      }));

      setSenders(response.data.items);
      setLoading(false);
    };

    fetch();

    return () => controller.abort();
  }, [filters, page, debouncedValue]);

  return (
    <SmsSenderListContext.Provider
      value={{
        filters,
        setFilters,
        loading,
        setLoading,
        senders,
        setSenders,
        page,
        setPage,
        stats,
        setStats,
      }}
    >
      {children}
    </SmsSenderListContext.Provider>
  );
};
