import { Button, Dropdown, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import { TbTrash, TbUserCheck } from "react-icons/tb";
import DeleteSender from "./DeleteSender";
import { BsBookmarkFill } from "react-icons/bs";
import { GiTestTubes } from "react-icons/gi";
import { IoEllipsisHorizontal } from "react-icons/io5";

const SetDefaultSender = ({
  id,
  handleDefaultChanged,
  disabled,
  confirmed,
  handleDeleted,
}) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const items = [
    {
      icon: <BsBookmarkFill className="drop-down-icon"/>,
      key: "1",
      label: <p className="drop-down-text">Set as default</p>,
      disabled: disabled,
    },
    {
      key: "2",
      disabled:true,
      icon: <GiTestTubes className="drop-down-icon"/>,
      label: <p className="drop-down-text">Recieve test</p>,
    },
    {
      key: "3",
      icon: <TbUserCheck className="drop-down-icon"/>,
      label: <p className="drop-down-text">Confirm</p>,
      disabled: confirmed,
    },
    {
      type:"divider"
    },
    {
      key: "4",
      icon: <TbTrash />,
      label: <DeleteSender id={id} deleted={handleDeleted} />,
      danger: true,
    },
  ];

  const handleConfirm = async () => {
    setLoading(true);
    const response = await axios.post("/Sender/GetSenderConfirmation", {
      id: id,
    });

    if (response.data.success) {
      message.success("Confirmation message was sent!");
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const submit = async () => {
    setLoading(true);
    const response = await axios.post(`/Sender/SetDefault`, { id: id });

    if (response.data.success) {
      handleDefaultChanged(id);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  const handleMenuClick = async (e) => {
    if (e.key == "1") {
      await submit();
    } else if (e.key == "2") {
      await sendTestEmail();
    } else if (e.key == "3") {
      await handleConfirm();
    }
  };

  const sendTestEmail = async () => {
    const response = await axios.post("/Sender/SendTestEmail", {
      id: id,
    });

    if (response) {
      message.success("Test email was sent!");
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      trigger={["click"]}
    >
      <Button
        type="text"
        size="large"
        className="flex-button"
        icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
      ></Button>
    </Dropdown>
  );
};

export default SetDefaultSender;
