import {
  Col,
  Flex,
  List,
  Popover,
  Row,
  Typography,
} from "antd";
import { HiMiniInformationCircle } from "react-icons/hi2";

const MoreDetails = ({ details }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Title level={3}>More data</Title>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <List
            bordered
            dataSource={[
              {
                name: "Successful deliveries",
                description: "Number of all successful deliveries",
                value: details.successfulDeliveries,
              },
              {
                name: "Total opens",
                value: details.opened,
                description: "Number of all opens",
              },
              {
                name: "Last opened",
                description: "Last time someone opened this email",
                value: details.lastOpened
                  ? new Date(details.lastOpened).toDateString()
                  : "-",
              },
              {
                name: "Processed",
                description: "Times the email got processed",
                value: details.processed,
              },
              {
                name: "Sender nickname",
                description: "Name of campaign sender",
                value: details.senderNickname,
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <Flex
                  style={{ width: "100%" }}
                  align="center"
                  justify="space-between"
                >
                  <Text className="icon-flex bold">
                    <Popover
                      title="Info"
                      content={<Text>{item.description}</Text>}
                    >
                      <HiMiniInformationCircle style={{ fontSize: 18 }} />
                    </Popover>
                    {item.name}
                  </Text>

                  <Text>{item.value}</Text>
                </Flex>
              </List.Item>
            )}
          />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <List
            bordered
            dataSource={[
              {
                name: "Unique opens",
                description: "Times an email got opened by unique users",
                value: details.uniqueOpens,
              },
              {
                name: "Total clicks",
                value: details.clicked,
                description: "Times an email got clicked",
              },
              {
                name: "Last clicked",
                description: "Last time an email got clicked",
                value: details.lastClicked
                  ? new Date().toDateString(details.lastClicked)
                  : "-",
              },
              {
                name: "Abuse reports",
                description: "Number of reported emails",
                value: details.abuseReports,
              },
              {
                name: "Sender email",
                description: "Email of campaign sender",
                value: details.senderEmail,
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <Flex
                  style={{ width: "100%" }}
                  align="center"
                  justify="space-between"
                >
                  <Text className="icon-flex bold">
                    <Popover
                      title="Info"
                      content={<Text>{item.description}</Text>}
                    >
                      <HiMiniInformationCircle style={{ fontSize: 18 }} />
                    </Popover>
                    {item.name}
                  </Text>

                  <Text>{item.value}</Text>
                </Flex>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};
export default MoreDetails;
