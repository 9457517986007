import { useContext } from "react";
import { CompanyListContext } from "../CompanyListContext";
import DateFilter from "../../../shared/DateFilter";
const CompanyToDateFilter = () => {
  const { filters, setFilters } = useContext(CompanyListContext);

  return (
    <>
      <DateFilter setFilters={setFilters} filters={filters} />
    </>
  );
};

export default CompanyToDateFilter;
