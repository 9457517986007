import { Button, Dropdown } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { PiNoteBlankFill } from "react-icons/pi";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DeleteList from "./DeleteList";
import { IoEllipsisHorizontal } from "react-icons/io5";

const ListOptions = ({
  id,
  deal,
  onDelete,
  setWonModal,
  setLostModal,
  setCurrent,
}) => {
  const navigate = useNavigate();
  const items = [
    {
      label: <p className="drop-down-text">Add note</p>,
      key: "2",
      icon: <PiNoteBlankFill className="drop-down-icon" />,
      onClick: () => navigate(`/Deals/Details/${id}#note`),
    },
    {
      type: "divider",
    },
    {
      label: <p className="drop-down-text">Move to won</p>,
      key: 3,
      icon: <FaThumbsUp className="drop-down-icon" />,
      onClick: () => {
        setWonModal(true);
        setCurrent(id);
      },
    },
    {
      label: <p className="drop-down-text">Move to lost</p>,
      key: 4,
      icon: <FaThumbsDown className="drop-down-icon" />,
      onClick: () => {
        setLostModal(true);
        setCurrent(id);
      },
    },
    {
      type: "divider",
    },
    {
      key: 1,
      label: <p className="drop-down-text">Delete</p>,
      danger: true,
      icon: <BsTrashFill className="drop-down-icon"/>,
    },
  ];
  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }} key={id}>
        <Button
          size="large"
          className="flex-button"
          type="text"
          icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default ListOptions;
