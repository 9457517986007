import { Button, Card, Flex, Space, Typography } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableLoading from "../../shared/TableLoading";
import CreateCampaignModal from "../campaigns/CreateCampaignModal";
import "./YourLastCampaigns.css";
import { GoDotFill } from "react-icons/go";
import NoDataFound from "../../shared/NoDataFound";

const YourLastCampaigns = () => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();

  const [data, setData] = useState();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(() => false);

  const handleGoToCampaignsClick = (e) => {

    navigate("/Campaigns");
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await axios.get(`/stats/campaigns/last`);

      setData(response.data.items);
      setLoading(false);
    };
    fetch();
  }, []);

  const [createOpen, setCreateOpen] = useState(false);

  const handleCreateClicked = () => {
    setCreateOpen(true);
  };

  const calculatePercentage = (num, den) => {
    if (den === 0) {
      return "0%";
    }
    return Math.round((num / den) * 100) + "%";
  };

  const handleNavigateToDetails = (id) => {
    navigate(`/Campaigns/Details/${id}`)
  }
  return (
    <>
      <CreateCampaignModal open={createOpen} setOpen={setCreateOpen} />
      <Card size="small" bodyStyle={{padding:0, margin:0}}>
          <Flex align="center" justify="space-between" className="p-10">
            <Title style={{ margin: 0 }} level={4}>
              Your last campaigns
            </Title>

            <Button
              onClick={handleCreateClicked}
              type="link"
              className="bold"
              size="large"
            >
              Create a campaign
            </Button>
          </Flex>
          {loading ? (
            <TableLoading height={55} />
          ) : data && data?.length > 0 ? (
            <Space className="w-100" direction="vertical" size={0}>
              {data?.map((c, i) => (
                <Card onClick={() => handleNavigateToDetails(c.id)} className="no-shadow last-camps-camp-card" size="small" key={i}>
                  <Flex
                    align="center"
                    justify="space-between"
                    className="w-100"
                  >
                    <Space direction="vertical">
                      <Space direction="vertical" size={0}>
                        <Text className="last-camps-camp-name">{c.name}</Text>
                        <Text className="last-camps-camp-data">
                          #{i} • Created at{" "}
                          {new Date(c.createdAt).toLocaleDateString()}
                        </Text>
                      </Space>
                      <Flex align="center" justify="start" gap={6}>
                        <div className="send-tag-campaigns">
                          <GoDotFill className="success" /> Sent
                        </div>
                        <div className="last-camps-camp-type">Email</div>
                      </Flex>
                    </Space>

                    <Flex align="center" justify="end" gap={12}>
                      <Space direction="vertical" align="center">
                        <Text className="last-camps-camp-data">Opened</Text>
                        <Text className="last-camps-camp-percentages">
                          {calculatePercentage(c.open, c.requests)}
                        </Text>
                      </Space>
                      <Space direction="vertical" align="center">
                        <Text className="last-camps-camp-data">Clicked</Text>
                        <Text className="last-camps-camp-percentages">
                          {calculatePercentage(c.clicks, c.requests)}
                        </Text>
                      </Space>
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </Space>
          ) : <NoDataFound/>}

          <Flex align="center" justify="center" className="w-100 p-10">
            <Button
              onClick={handleGoToCampaignsClick}
              type="link"
              className="bold"
              size="large"
            >
              Go to campaigns
            </Button>
          </Flex>
      </Card>
    </>
  );
};

export default YourLastCampaigns;
