import { Button, Flex, Form, Input, message, Typography } from "antd";
import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import "./EditableName.css";
import { IoMdClose } from "react-icons/io";
import { MdCheck } from "react-icons/md";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const EditableName = ({ id, name, onUpdate }) => {
  const { Title } = Typography;

  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const handleEditChange = (show) => {
    setEdit(show);
    form.setFieldValue("name", name);
  };

  const submit = async (e) => {
    setLoading(true)

    const response = await axios.put(`/Campaign/Email/Update-name`, {
        name: e.name,
        id : id  
    });

    if(response.data.success){
        setEdit(false)
        onUpdate(e.name)
    }else{
        message.error(response.data.message)
    }

    setLoading(false)
  }
  return (
    <>
      {edit ? (
        <>
          <Form form={form} onFinish={submit}>
            <Flex align="center" justify="start" gap={6}>
              <Form.Item
                name="name"
                className="no-error"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 128,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter name"
                  count={{
                    show: true,
                    max: 128,
                  }}
                />
              </Form.Item>

              <Button loading={loading} onClick={() => form.submit()} type="text" className="flex-button icon" icon={<MdCheck />}></Button>
              <Button type="text" onClick={() => handleEditChange(false)} className="flex-button icon" icon={<IoMdClose />}></Button>
            </Flex>
          </Form>
        </>
      ) : (
        <>
          <Title className="zero-margin-padding" level={1}>
            {name}
          </Title>

          <Button
            size="large"
            type="link"
            onClick={() => handleEditChange(true)}
            className="flex-button"
            icon={<AiFillEdit className="edit-icon" />}
          ></Button>
        </>
      )}
    </>
  );
};

export default EditableName;
