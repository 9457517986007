import { Button, Checkbox, Flex, Form, Input, message, Modal, Typography } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreatefollowupModal = ({ open, setOpen }) => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();

  const navigation = useNavigate();
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    setLoading(true);

    let response = await axios.post(`/Followup`, {
        name: e.name,
        useDefaultSteps: e.useDefault
    });
    if(response.data.success){
        navigation(`/Followup/details/${response.data.id}`)
    }else{
        message.error(response.data.message)
    }

    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={3}>
          Create a follow up
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button
             className="new-buttons large-font"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
             className="new-buttons large-font"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={submit}>
        <Text>Name</Text>
        <Form.Item className="no-error"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
            {
              min: 3,
              message:"Name should contain more than 2 characters"
            },
            {
              max: 128,
              name:"Name should be less than 128 characters"
            }
          ]}
          name="name"
        >
          <Input placeholder="Follow up name" size="large" count={{
            show:true,
            max:128
          }} />
        </Form.Item>

        <Form.Item name="useDefault" valuePropName="checked">
          <Checkbox size="large">Use default steps</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatefollowupModal;
