import { useContext } from "react";
import { TemplateContext } from "./TemplateContext";
import { Flex, Tag } from "antd";

const TemplateStats = () => {
  const { stats } = useContext(TemplateContext);
  return (
    <>
      <Flex align="center" justify="start">
        <Tag bordered={true} size="small" className="large-font-text">
          {stats?.total ?? 0} Total
        </Tag>
        <Tag bordered={true} size="small" className="large-font-text">
          {stats?.simple ?? 0} Simple
        </Tag>
      </Flex>
    </>
  );
};

export default TemplateStats;
