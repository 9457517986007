import { Button, Card, Flex, Space, Tabs, Typography } from "antd";
import "./CreateTemplate.css";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CreateYourOwn from "./CreateYourOwn";
import MyTemplates from "./MyTemplates";
import Layouts from "./Layouts";
import Gallery from "./Gallery";
import CampaignTemplates from "./CampaignTemplates";
import PreviewTemplateModal from "../PreviewTemplateModal";
import { useContext, useState } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { EmailTemplateTypes } from "../../../shared/enums";
import { CreateFollowupContext } from "../../followup/create/CreateFollowupContext";

const CreateTemplateLayout = () => {
  const { data, assingTemplateToCampaign } = useContext(CreateCampaignContext);

  const { details, assingEmailTemplateToStep } = useContext(CreateFollowupContext);

  const { Title } = Typography;

  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);
  const [currentPreview, setCurrentPreivew] = useState();

  const handleOpenPreview = (id, type) => {
    setCurrentPreivew({ id, type });
    setPreviewOpen(true);
  };

  const handleUseTemplate = (id, type) => {
    if (type == EmailTemplateTypes.UserCreated) {
      if(details?.id){
        assingEmailTemplateToStep(id)
      }else{
        assingTemplateToCampaign(id);
      }
    } else {

      if(data?.id){

        navigate(
          `/Templates/Create/${id}?returnUrl=Campaigns/Edit/${data?.id}&cmpId=${data?.id}`
        );
      }else{
        navigate(`/Templates/Create/${id}?returnUrl=Followup/details/${details?.followUpId}/step/${details.id}&stepId=${details?.id}`);
      }
    }
  };
  return (
    <>
      <PreviewTemplateModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        id={currentPreview?.id}
        type={currentPreview?.type}
      />
      <Space direction="vertical" className="w-100" size={24}>
        <Card size="small">
          <Flex align="center" justify="start" gap={6}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              icon={<FaArrowLeft />}
              className="flex-button"
            ></Button>
            <Title className="zero-margin-padding" level={4}>
              {data?.id || details?.id ? "Select a template" : "Desing your email"}
            </Title>
          </Flex>
          <Flex justify="center">
            <Tabs
              centered
              className="w-100"
              size="large"
              items={[
                {
                  key: 1,
                  label: "Layouts",
                  children: (
                    <Flex justify="center">
                      {" "}
                      <Layouts
                        onUseTemplate={handleUseTemplate}
                        openPreview={handleOpenPreview}
                        isForSelect={data?.id || details?.id}
                      />
                    </Flex>
                  ),
                },
                {
                  key: 2,
                  label: "Template gallery",
                  children: (
                    <Flex justify="center">
                      <Gallery
                        openPreview={handleOpenPreview}
                        isForSelect={data?.id || details?.id}
                        onUseTemplate={handleUseTemplate}
                      />
                    </Flex>
                  ),
                },
                {
                  key: 3,
                  label: "My templates",
                  children: (
                    <Flex justify="center">
                      <MyTemplates
                        onUseTemplate={handleUseTemplate}
                        openPreview={handleOpenPreview}
                        isForSelect={data?.id || details?.id}
                      />
                    </Flex>
                  ),
                },
                {
                  key: 4,
                  label: "Campaigns",
                  children: (
                    <Flex justify="center">
                      {" "}
                      <CampaignTemplates
                        onUseTemplate={handleUseTemplate}
                        isForSelect={data?.id || details?.id}
                        openPreview={handleOpenPreview}
                      />
                    </Flex>
                  ),
                },
                {
                  key: 5,
                  label: "Create your own",
                  children: (
                    <Flex justify="center">
                      <CreateYourOwn
                        isForSelect={data?.id || details?.id}
                        returnUrl={data?.id ? `?returnUrl=Campaigns/Edit/${data?.id}&cmpId=${data?.id}` : details ? `?returnUrl=Followup/details/${details?.followUpId}/step/${details?.id}&stepId=${details?.id}` : null}
                      />
                    </Flex>
                  ),
                },
              ]}
            ></Tabs>
          </Flex>
        </Card>
      </Space>
    </>
  );
};

export default CreateTemplateLayout;
