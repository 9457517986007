import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  message,
  Typography,
  Pagination,
  Avatar,
  Tooltip,
} from "antd";
import NotificationTemplatesStats from "./NotificationTemplatesStats";
import { useContext, useState } from "react";
import { MdEdit } from "react-icons/md";
import NotificationTemplatesToDateFilter from "./Filters/NotificationTemplatesToDateFilter";
import { NotificationTemplatesContext } from "./NotificationTemplatesContext";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import { useNavigate } from "react-router-dom";
import NotificationTemplatresOptions from "./NotificationTemplatesOptions";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DateFormat } from "../../../dateformat";
import "./NotificationTemplatesList.css";
import { AiFillEdit } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import CreateNewNotificationTemplateModal from "./CreateNewNotificationTemplateModal";
import { RiNotificationBadgeFill } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import NotificationPreviewModal from "./NotificationTemplatePreviewModal";
import SimplePagination from "../../../shared/SimplePagination";

const NotificationTemplatesList = () => {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  const { Text } = Typography;
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [current, setCurrent] = useState();

  const { pageSize, setPageSize, loading, stats, page, setPage, groups, setGroups, setStats } =
    useContext(NotificationTemplatesContext);

  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    let response = await axios.delete(
      `/Notification/Notification-Template/${item}`
    );

    if (response.data.success) {
      setGroups((prev) => prev.filter((c) => c.id != item));
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
    } else {
      message.error(response.data.message);
    }
  };

  const [addOpen, setAddOpen] = useState(false);

  const handleDuplicate = async (id) => {
    const response = await axios.post("/Notification/Template/Duplicate", {
      id: id,
    });

    if (response.data.success) {
      setStats((prev) => ({ ...prev, total: prev.total + 1 }));
      setGroups((prev) => [response.data, , ...prev]);
    } else {
      message.error(response.data.message);
    }
  };

  const [previewOpen, setPreviewOpen] = useState(false);

  const handleOpenPreview = (e, item) => {
    setCurrent(item);
    setPreviewOpen(true);
  };
  return (
    <>
      <NotificationPreviewModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        id={current}
      />
      <CreateNewNotificationTemplateModal open={addOpen} setOpen={setAddOpen} />
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current?.id}
      />

      <Space direction="vertical" size={24} className="w-100">
        <NotificationTemplatesStats />

        <Flex align="center" justify="space-between">
          <NotificationTemplatesToDateFilter />

          <SimplePagination page={page} pageSize={pageSize} total={stats?.total} setPage={setPage}/>
        </Flex>
        <Space className="w-100" direction="vertical">
          {loading ? (
            <TableLoading />
          ) : !groups || groups.length == 0 ? (
            <NoDataFound
              addText="New notification desing"
              onAdd={() => navigate("/Templates/Notification/create")}
              description="Click the New Design button on the Notification desing page to create a new notification template"
              title="You have not created any notification templates yet"
            />
          ) : (
            <>
              {groups?.map((c, ind) => (
                <Card
                  size="small"
                  key={ind}
                  className="no-shadow gray-border-hoverable"
                >
                  <Row gutter={[6, 6]} style={{ height: "100%" }}>
                    <Col span={18}>
                      <Flex align="center" justify="start" gap={12}>
                        <Avatar
                          icon={<RiNotificationBadgeFill />}
                          shape="square"
                          size={"large"}
                          src={`${process.env.REACT_APP_FILE_URL}${c.path}`}
                        />
                        <Space size={0} direction="vertical" className="w-100">
                          <Text
                            type="link"
                            onClick={() =>
                              navigate(`/Templates/Notification/Edit/${c.id}`)
                            }
                            className="notification-list-title"
                          >
                            {c.name}
                          </Text>
                          <Text>
                          {`#${(page - 1) * pageSize + ind + 1} • `} 
                            {new Date(c.createdAt * 1000).toLocaleDateString(
                              "en-us",
                              DateFormat
                            )}
                            {c.lastEdit
                              ? ` • Last edited at ${new Date(
                                  c.lastEdit * 1000
                                ).toLocaleDateString("en-us", DateFormat)}`
                              : ""}
                          </Text>
                        </Space>
                      </Flex>
                    </Col>

                    <Col span={3}>
                      <Flex className="h-100" align="center" justify="start">
                        {c.saveStatus == 1 ? (
                          <Text className="notification-saved">
                            <IoMdCheckmark /> Done
                          </Text>
                        ) : (
                          <Text className="notification-draft">
                            <MdEdit /> Draft
                          </Text>
                        )}
                      </Flex>
                    </Col>
                    <Col span={3}>
                      <Flex
                        gap={12}
                        className="h-100"
                        align="center"
                        justify="end"
                      >
                        <Tooltip title="Edit">
                          <Button
                            size="large"
                            className="flex-button"
                            type="link"
                            onClick={() =>
                              navigate(`/Templates/Notification/Edit/${c.id}`)
                            }
                            icon={
                              <AiFillEdit className="template-options-icon" />
                            }
                          ></Button>
                        </Tooltip>

                        <Tooltip title="Preview">
                          <Button
                            size="large"
                            className="flex-button"
                            onClick={(e) => handleOpenPreview(e, c.id)}
                            type="text"
                            icon={<FaEye className="template-options-icon" />}
                          ></Button>
                        </Tooltip>

                        <NotificationTemplatresOptions
                          onDuplicate={handleDuplicate}
                          template={c}
                          setCurrent={setCurrent}
                          openEdit={() => setEditOpen(true)}
                          openDelete={() => setDeleteOpen(true)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
            <SimplePagination showChanger page={page} setPerPage={setPageSize} pageSize={pageSize} total={stats?.total} setPage={setPage}/>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default NotificationTemplatesList;
