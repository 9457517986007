import { Button, Dropdown } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { FaBookmark, FaLockOpen } from "react-icons/fa";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { RiChatFollowUpFill } from "react-icons/ri";

const TaskOptions = ({
  id,
  onDelete,
  taskDone,
  onMarkTaskAsDone,
  onCreateFollowUp,
  onReopenTask,
}) => {
  const items = [
    {
      label: <p className="drop-down-text">Create follow-up</p>,
      key: 4,
      icon: <RiChatFollowUpFill className="drop-down-icon" />,
      disabled: !taskDone,
      onClick: () => onCreateFollowUp(id),
    },
    {
      icon: <FaLockOpen className="drop-down-icon" />,
      label: <p className="drop-down-text">Reopen task</p>,
      key: 3,
      disabled: !taskDone,
      onClick: () => onReopenTask(id),
    },
    {
      icon: <FaBookmark className="drop-down-icon" />,
      label: <p className="drop-down-text">Mark as done</p>,
      key: 2,
      disabled: taskDone,
      onClick: () => onMarkTaskAsDone(id),
    },
    {
      type: "divider",
    },
    {
      label: <p className="drop-down-text">Delete</p>,
      key: 1,
      icon: <BsTrashFill className="drop-down-icon" />,
      danger: true,
      onClick: () => onDelete(id),
    },
  ];
  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          className="flex-button"
          type="text"
          icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default TaskOptions;
