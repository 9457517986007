import { Select, Button, Col, Flex, Row, Typography, Card, Space } from "antd";
import "./DialerLayout.css";
import { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaCog } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import AudioSettingsDrawer from "./AudioSettingsDrawer";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Call from "./Call";
import Calling from "./Calling";
import sound1 from "../../assets/audio/1.mp3";
import secondSound from "../../assets/audio/second.mp3";
import thirdSound from "../../assets/audio/third.mp3";
import { useNotification, TelnyxRTCContext } from "@telnyx/react-client";
import RecentCalls from "./RecentCalls";

const DialerLayout = () => {
  const client = useContext(TelnyxRTCContext);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [number, setNumber] = useState(() => urlParams.get("number"));
  const [id, setId] = useState(() => urlParams.get("id"));

  const [numbers, setNumbers] = useState();

  const axios = useAxiosPrivate();
  const [selectedNumber, setSelectedNumber] = useState();
  const notification = useNotification();
  const activeCall = notification && notification.call;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      const response = await axios.get(`/phone-numbers/get-numbers-only`, {
        signal: signal,
      });

      setNumbers(response.data.items);

      if (id) {
        setSelectedNumber(response.data.items.find((c) => c.id == id)?.id);
      } else {
        setSelectedNumber(response.data.items.find((c) => c.default)?.id);
      }
    };

    fetch();

    return () => controller.abort();
  }, []);

  const { Text, Title } = Typography;
  const navigate = useNavigate();

  const updateNumber = (num) => {
    playSound(num);
    if (number) {
      setNumber((prev) => `${prev}${num}`);
    } else {
      setNumber((prev) => `${num}`);
    }
  };

  const clearLast = () => {
    if (number.length > 0) {
      setNumber((prev) => prev.substring(0, prev.length - 1));
    }
  };

  const addPlus = () => {
    if (number.length == 0) {
      return;
    }

    if (number[0] == "+") {
      setNumber("+");
    }

    setNumber((prev) => `+${prev}`);
  };

  const [settingsOpen, setSettingsOpen] = useState(false);

  const [view, setView] = useState(1);

  const handleHangup = () => {
    setView(1);

    activeCall?.hangup();
  };

  const playSound = (num) => {
    if (num == 1 || num == 7 || num == 0 || num == 4) {
      let audio = new Audio(sound1);
      audio.play();
    } else if (num == 2 || num == 5 || num == 8) {
      let audio = new Audio(secondSound);
      audio.play();
    } else if (num == 3 || num == 6 || num == 9) {
      let audio = new Audio(thirdSound);
      audio.play();
    }
  };

  const [beingCalled, setBeingCalled] = useState(false);

  useEffect(() => {
    if (activeCall?.state == "ringing") {
      setView(2);
      setBeingCalled(true);
    }

    if (activeCall?.state == "destroy") {
      setView(1);
    }

    if (activeCall?.state == "active") {
      setView(3);
    }
  }, [activeCall?.state]);

  const startCall = (numberToCall) => {
    const tapzapNum = numbers.find((c) => c.id == selectedNumber)?.number;

    client.newCall({
      destinationNumber: numberToCall ? numberToCall : number,
      callerNumber: tapzapNum,
      audio: true,
      video: false,
    });

    setBeingCalled(false);
    setView(2);
  };

  const handleOnCallClick = (e) => {
    setNumber(e);
    startCall(e);
  };

  return (
    <>
      {view == 1 ? (
        <>
          <AudioSettingsDrawer open={settingsOpen} setOpen={setSettingsOpen} />
          <Flex
            className="w-100 h-100"
            style={{maxHeight: "100vh", height:"100vh", overflow:"hidden"}}
            align="center"
            justify="space-between"
            vertical
          >
            <Card size="small" className="w-100 no-shadow">
              <Row>
                <Col span={8}>
                  <Flex align="center" justify="start" gap={12}>
                    <Button
                      onClick={() => navigate(-1)}
                      type="text"
                      icon={<FaArrowLeft />}
                      className="flex-button"
                    ></Button>
                    <img src={logo} width={40} />
                    <Title
                      className="zero-margin-padding phone-dialer-title"
                      level={2}
                    >
                      Phone
                    </Title>
                  </Flex>
                </Col>

                <Col span={8}>
                  <Flex align="center" gap={6}>
                    <Select
                      className="w-100"
                      size="large"
                      placeholder="Select your number"
                      defaultValue={selectedNumber}
                      value={selectedNumber}
                      onChange={(e) => setSelectedNumber(e)}
                      options={numbers?.map((c) => ({
                        value: c.id,
                        label: c.number,
                      }))}
                    />
                    <Button
                      type="primary"
                      size="large"
                      className="new-buttons large-font"
                      onClick={() => navigate(`/phone-number/buy`)}
                    >
                      Buy more
                    </Button>
                  </Flex>
                </Col>

                <Col span={8}>
                  <Flex align="center" justify="end">
                    <Button
                      shape="circle"
                      size="large"
                      className="flex-button"
                      icon={<FaCog />}
                      onClick={() => setSettingsOpen(true)}
                    ></Button>
                  </Flex>
                </Col>
              </Row>
            </Card>

            <Row className="w-100 h-100">
              <Col span={12}>
                <Flex style={{background:"whitesmoke"}} className="w-100 h-100" align="center" justify="center">
                  <div className="dialer-wrapper">
                    <div className="dialer-number-preview">
                      <p>{number}</p>
                    </div>
                    <Row gutter={[6, 6]}>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(1)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          1
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(2)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          2
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(3)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          3
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(4)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          4
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(5)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          5
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(6)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          6
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(7)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          7
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(8)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          8
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(9)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          9
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                          onClick={addPlus}
                        >
                          +
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={() => updateNumber(0)}
                          size="large"
                          className="bold dialer-button"
                          shape="circle"
                        >
                          0
                        </Button>
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={clearLast}
                          size="large"
                          disabled={number?.length == 0}
                          className="bold dialer-button"
                          shape="circle"
                        >
                          <FaArrowLeft className="dialer-icon" />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Flex>
              </Col>

              <Col span={12}>
                <RecentCalls onCallClicked={handleOnCallClick} />
              </Col>
            </Row>
          </Flex>

          <div className="call-button-wrapper">
            <Button className="class-button-dialer" onClick={() => startCall()}>
              <MdCall />
            </Button>
          </div>
        </>
      ) : view == 2 ? (
        <Calling
          activeCall={activeCall}
          beingCalled={beingCalled}
          onHangup={handleHangup}
        />
      ) : (
        <Call
          beingCalled={beingCalled}
          activeCall={activeCall}
          onHangup={handleHangup}
        />
      )}
    </>
  );
};

export default DialerLayout;
