import React from 'react';
import { Avatar } from 'antd';
const AvatarGroup = ({items, size}) => (
  <>

    <Avatar.Group size={size}
      max={{count:2}}
    >
      {items?.map((c,ind) => <Avatar key={ind} size={size} src={`${process.env.REACT_APP_FILE_URL}${c.profileUrl}`}>
        {c.name[0]}{c.lastName[0]}
      </Avatar>)}
    </Avatar.Group>
    
  </>
);
export default AvatarGroup;