import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FaArrowLeft, FaCog } from "react-icons/fa";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import TextArea from "antd/es/input/TextArea";
import "./SmsPhoneLayout.css";
import { DateFormat } from "../../dateformat";
import RecentSms from "./RecentSms";
import { MdCall } from "react-icons/md";

const SmsPhoneLayout = () => {
  const { Text, Title } = Typography;
  const navigate = useNavigate();

  const sms = [
    {
      date: new Date(),
      conversations: [
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: false,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit.",
          you: false,
          date: new Date(),
        },
        {
          text: "Hello world, How are you doing?",
          you: true,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: false,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: false,
          date: new Date(),
        },
      ],
    },
    {
      date: new Date(),
      conversations: [
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris luctus vel dui vel rutrum. Curabitur et orci pretium, tincidunt est a, viverra ligula. Suspendisse vitae lectus dui. Curabitur egestas elit nunc, eget tincidunt magna ultrices ultricies. In arcu arcu, lobortis eget dui a, ornare iaculis libero. Phasellus non nibh lacus. Pellentesque pharetra tortor metus, nec euismod velit lacinia tempor. Quisque dapibus volutpat tortor, vel cursus turpis tempus quis. Donec at leo eget nisl mattis mollis. Duis convallis ex neque.",
          you: false,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: false,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: true,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: true,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: true,
          date: new Date(),
        },
      ],
    },
    {
      date: new Date(),
      conversations: [
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris luctus vel dui vel rutrum. Curabitur et orci pretium, tincidunt est a, viverra ligula. Suspendisse vitae lectus dui. Curabitur egestas elit nunc, eget tincidunt magna ultrices ultricies. In arcu arcu, lobortis eget dui a, ornare iaculis libero. Phasellus non nibh lacus. Pellentesque pharetra tortor metus, nec euismod velit lacinia tempor. Quisque dapibus volutpat tortor, vel cursus turpis tempus quis. Donec at leo eget nisl mattis mollis. Duis convallis ex neque.",
          you: false,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: false,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: true,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: true,
          date: new Date(),
        },
        {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          you: true,
          date: new Date(),
        },
      ],
    },
  ];

  const lastSms = [];
  return (
    <div
      style={{
        maxHeight: "100vh",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card size="small" className="w-100 no-shadow sms-phone-card">
        <Row>
          <Col span={8}>
            <Flex align="center" justify="start" gap={12}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                icon={<FaArrowLeft />}
                className="flex-button"
              ></Button>
              <img src={logo} width={40} />
              <Title
                className="zero-margin-padding phone-dialer-title"
                level={2}
              >
                Phone
              </Title>
            </Flex>
          </Col>

          <Col span={8}>
            <Flex align="center" gap={6}>
              <Select
                className="w-100"
                size="large"
                placeholder="Select your number"
              />
              <Button
                type="primary"
                size="large"
                className="new-buttons large-font"
                onClick={() => navigate(`/phone-number/buy`)}
              >
                Buy more
              </Button>
            </Flex>
          </Col>

          <Col span={8}>
            <Flex align="center" justify="end">
              <Button
                shape="circle"
                size="large"
                className="flex-button"
                icon={<FaCog />}
              ></Button>
            </Flex>
          </Col>
        </Row>
      </Card>

      <Row style={{ flex: 1 }}>
        <Col span={12} style={{ background: "whitesmoke" }}>
          <Flex
            vertical
            justify="space-between"
            className="w-100 h-100 border-right"
          >
          <Card size="small" className="contact-sms-phone-wrapper">
            <Flex align="center" justify="space-between">
              <Text className="contact-name-sms">Enver Miftari</Text>
              <Button
                type="text"
                size="large"
                icon={<MdCall className="icon" style={{ color: "#1cc88a" }} />}
              ></Button>
            </Flex>
          </Card>
            <div
              style={{
                overflowY: "auto",
                flex: 1,
                maxHeight: "calc(100vh - 384px)",
                height: "calc(100vh - 384px)",
              }}
              className="w-100"
            >
              {sms.map((c, i) => (
                <Space className="w-100 mt-10" direction="vertical">
                  <Flex justify="center" align="center">
                    <Tag className="sms-phone-tag">
                      {c.date.toLocaleDateString("en-us", DateFormat)}
                    </Tag>
                  </Flex>
                  {c.conversations.map((d, j) => (
                    <Flex align="center" justify={d.you ? "end" : "start"}>
                      <Card
                        key={j}
                        size="small"
                        className={`no-shadow gray-border ${
                          d.you
                            ? "your-sms sms-flex-wrapper-your"
                            : "sms-flex-wrapper"
                        }`}
                      >
                        <Text className={d.you ? "your-sms-text" : "sms-text"}>
                          {d.text}
                        </Text>
                      </Card>
                    </Flex>
                  ))}
                </Space>
              ))}
            </div>

            <div className="keyborad-input-space" size={0} direction="vertical">
              <TextArea
                size="large"
                placeholder="Enter your sms message"
                className="sms-phone-sms-text-area"
              ></TextArea>
              <div className="keyboard-wrapper">
                <Keyboard
                  theme="hg-theme-default hg-theme-ios"
                  layout={{
                    default: [
                      "q w e r t y u i o p {bksp}",
                      "a s d f g h j k l {enter}",
                      "{shift} z x c v b n m , . {shift}",
                      "{alt} {smileys} {space} {altright} {downkeyboard}",
                    ],
                    shift: [
                      "Q W E R T Y U I O P {bksp}",
                      "A S D F G H J K L {enter}",
                      "{shiftactivated} Z X C V B N M , . {shiftactivated}",
                      "{alt} {smileys} {space} {altright} {downkeyboard}",
                    ],
                    alt: [
                      "1 2 3 4 5 6 7 8 9 0 {bksp}",
                      `@ # $ & * ( ) ' " {enter}`,
                      "{shift} % - + = / ; : ! ? {shift}",
                      "{default} {smileys} {space} {back} {downkeyboard}",
                    ],
                    smileys: [
                      "😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}",
                      `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
                      "😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}",
                      "{default} {smileys} {space} {altright} {downkeyboard}",
                    ],
                  }}
                  display={{
                    "{alt}": ".?123",
                    "{smileys}": "\uD83D\uDE03",
                    "{shift}": "⇧",
                    "{shiftactivated}": "⇧",
                    "{enter}": "return",
                    "{bksp}": "⌫",
                    "{altright}": ".?123",
                    "{downkeyboard}": "🞃",
                    "{space}": " ",
                    "{default}": "ABC",
                    "{back}": "⇦",
                  }}
                />
              </div>
            </div>
          </Flex>
        </Col>
        <Col span={12}>
          <RecentSms />
        </Col>
      </Row>
    </div>
  );
};

export default SmsPhoneLayout;
