import { Modal, Space, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./TranscriptionModal.css"

const TranscriptionModal = ({ open, setOpen, id }) => {
  const axios = useAxiosPrivate();
  const audioPlayerRef = useRef(null);

  const [audioString, setAudioString] = useState();
  const [trans, setTrans] = useState();

  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      const response = await axios.get(`/Call/Transcriptions/${id}`, {
        signal: signal,
      });

      setAudioString(response.data.recording);
      setTrans(response.data.items);
    };

    if (id) {
      fetch();
    }

    return () => {
      controller.abort();
    };
  }, [id]);

 
  const handleClose = () => {
    setOpen(false);
  };

  const {Text, Title} = Typography

  const handleTextClick = (intervalStart) => {
    if(audioPlayerRef.current){
      audioPlayerRef.current.audio.current.currentTime = intervalStart;
    }
  }

  return (
    <Modal title={<Title level={4} className="zero-margin-padding">
        Call transcription
    </Title>} footer={false} open={open} onCancel={handleClose}>
      {audioString && (
        <>
        <Space className="w-100" direction="vertical" size={24}>

          <Space  direction="vertical" className="transcription-wrapper">
            {trans?.map((transcription, index) => {
              const isActive =
                currentTime >= transcription.start &&
                currentTime <= transcription.end;
              return (
                <Text
                  key={index}
                  className={isActive ? "trans-p active-trans" : "trans-p normal-trans"}
                  style={{
                    fontWeight: isActive ? "bold" : "normal",
                    color: isActive ? "#000" : "#aaa",
                    transition: "color 0.3s, font-weight 0.3s",
                    cursor:'pointer'
                  }}
                  onClick={() => handleTextClick(transcription.start)}
                >
                  {transcription.text}
                </Text>
              );
            })}
          </Space>
          <div>
            <AudioPlayer
              ref={audioPlayerRef}
              currentTime={currentTime}
              onListen={(e) => setCurrentTime(e.target.currentTime)}
              src={`data:audio/mp3;base64,${audioString}`}
            />
          </div>
        </Space>

        </>
      )}
    </Modal>
  );
};

export default TranscriptionModal;
