import {
  Avatar,
  Button,
  Card,
  Flex,
  message,
  Space,
  Timeline,
  Typography,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";
import CreateCommentModal from "./CreateCommentModal";
import { FiPlus } from "react-icons/fi";
import { DateFormat } from "../../../dateformat";
import EditCommentDrawer from "./EditCommentDrawer";

const ContactCommentsList = ({ id }) => {
  const [take, setTake] = useState(() => 7);

  const [comments, setComments] = useState();
  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const [total, setTotal] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchComments = async () => {
      setLoading(true);
      const response = await axios.get(
        `Email-Comment?ContactId=${id}&take=${take}`,
        {
          signal: signal,
        }
      );

      setComments(response.data.items);
      setLoading(false);
      setTotal(response.data.total);
    };

    fetchComments();

    return () => controller.abort();
  }, [take, id]);

  const [addComment, setAddComment] = useState(false);

  const onAdd = (e) => {
    setComments((prev) => [e, ...prev]);
  };

  const remove = (id) => {
    setComments((prev) => prev.filter((c) => c.id !== id));
  };

  const [editOpen, setEditOpen] = useState(false);
  const [current, setCurrent] = useState();

  const onEditClick = (e) => {
    setEditOpen(true);

    setCurrent(comments.find((c) => c.id == e));
  };

  const onUpdate = (id, comment) => {
    setComments((prev) =>
      prev.map((c) => (c.id == id ? { ...c, comment: comment } : c))
    );
  };

  const {Title} = Typography
  return (
    <>
      <EditCommentDrawer
        onUpdate={onUpdate}
        comment={current}
        open={editOpen}
        setOpen={setEditOpen}
      />
      <CreateCommentModal
        id={id}
        onAdded={onAdd}
        open={addComment}
        setOpen={setAddComment}
      />
  <Space className="w-100" direction="vertical" size={24}>

      <Flex align="center" justify="space-between">

      <Title level={3} className="zero-margin-padding">
            All comments
          </Title>
        <Button
          size="large"
          onClick={() => setAddComment(true)}
           className="new-buttons large-font"
        >
          Create comment
        </Button>
      </Flex>
      {loading ? (
        <TableLoading />
      ) : comments && comments.length > 0 ? (
        <Space style={{padding:12}} className="w-100" direction="vertical">
          <Flex align="center" justify="start">
            <Timeline
              className="w-100"
              items={comments?.map((c) => ({
                dot: (
                  <div className="comunication-dot"></div>
                ),
                children: (
                  <Comment
                    onEditClick={onEditClick}
                    id={c.id}
                    onRemoved={remove}
                    date={c.createdAt}
                    author={c.authorName}
                    comment={c.comment}
                    disabled={!c.canEdit}
                  />
                ),
              }))}
            />
          </Flex>

          {total > comments?.length && (
            <Flex align="center" justify="center">
              <Button
                onClick={() => setTake((prev) => prev + 7)}
                loading={loading}
                size="large"
                type="primary"
              >
                Show more
              </Button>
            </Flex>
          )}
        </Space>
      ) : (
        <NoDataFound
          onAdd={() => setAddComment(true)}
          addText="Add comment"
          title="No comments found for this contact"
          description="This contact contains no comment, click the add comment button below to add one!"
        />
      )}
  </Space>
    </>
  );
};

const Comment = ({
  onEditClick,
  onRemoved,
  disabled,
  comment,
  id,
  date,
  author,
}) => {
  const { Text } = Typography;
  const axios = useAxiosPrivate();

  const [removing, setRemoving] = useState(false);

  const removeComment = async () => {
    setRemoving(true);

    const response = await axios.delete(`/Email-Comment/${id}`);

    if (response.data.success) {
      onRemoved(id);
    } else {
      message.error(response.data.message);
    }
    setRemoving(false);
  };
  return (
    <>
      <Space className="w-100" direction="vertical" size={0}>
        <Text style={{fontSize:16}} strong>Comment created</Text>
        <Text>
          {new Date(date * 1000).toLocaleDateString("en-us", DateFormat)} •{" "}
          {author}
        </Text>

        <Card size="small" style={{ boxShadow: "none" }}>
          <Text>{comment}</Text>
          <Flex gap={6} align="end" justify="end">
            <Button disabled={disabled} onClick={() => onEditClick(id)}>
              Edit
            </Button>
            <Button
              loading={removing}
              onClick={() => removeComment()}
              danger
              type="text"
              disabled={disabled}
            >
              Remove
            </Button>
          </Flex>
        </Card>
      </Space>
    </>
  );
};

export default ContactCommentsList;
