import {
  Card,
  Col,
  Flex,
  Row,
  Space,
  Typography,
  Checkbox,
  message,
  Select,
  Avatar,
} from "antd";
import { useContext, useEffect, useState } from "react";
import TableLoading from "../../shared/TableLoading";
import { DateFormat } from "../../dateformat";
import UpdateProduct from "./UpdateProduct";
import ProductListOptions from "./ProductListOptions";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../shared/NoDataFound";
import { ProductListContext } from "./ProductListContext";
import DateToProductFilter from "./Filters/DateToProductFilter";
import ProductStats from "./Stats";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import "./ProductList.css";
import SimplePagination from "../../shared/SimplePagination";

const ProductList = ({dataToAdd}) => {
  const axios = useAxiosPrivate();

  const [followUps, setFollowUps] = useState();
  const {
    stats,
    setStats,
    page,
    setPage,
    loading,
    products,
    setProducts,
    pageSize,
    setPageSize
  } = useContext(ProductListContext);

  const { Text } = Typography;

  const navigate = useNavigate();

  const [showEdit, setShowEdit] = useState(false);

  const [current, setCurrent] = useState();

  useEffect(() => {
    const fetch = async () => {
      let response = await axios.get(`/Followup/List`, {
        params: {
          pageSize: 9999,
          page: 1,
        },
      });

      setFollowUps(
        response.data.data?.map((c) => ({
          value: c.id,
          label: c.name,
        }))
      );
    };
    fetch();
  }, []);

useEffect(() => {
  if(dataToAdd){
    onAdd(dataToAdd)
  }
}, [dataToAdd])

  const onAdd = (prod) => {
    setStats((prev) => ({ ...prev, total: prev.total + 1 }));

    setProducts((prev) => [prod, ...prev]);
  };

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    setProducts((prev) => prev.filter((c) => c.id != id));
  };

  const onUpdate = (prod) => {
    setProducts((prev) =>
      prev.map((c) =>
        c.id == prod.id
          ? {
              ...c,
              name: prod.name,
              autoFollowupEmails: prod.autoFollowupEmails,
              autoFollowupNotifications: prod.autoFollowupNotifications,
              owners: prod.owners,
            }
          : c
      )
    );
  };

  const handleUpateFollowups = async (id, emails, notifications) => {
    let response = await axios.put(`/Product/Update/FolloUps`, {
      id: id,
      emails: emails,
      notifications: notifications,
    });

    if (response.data) {
      setProducts((prev) =>
        prev.map((c) =>
          c.id == id
            ? {
                ...c,
                autoFollowupEmails: emails,
                autoFollowupNotifications: notifications,
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const handleFollowupUpdate = async (id, followUpId) => {
    let response = await axios.put("/Product/Update/Followup", {
      id: id,
      followUpId: followUpId,
    });

    if (response.data.success) {
      setProducts((prev) =>
        prev.map((c) =>
          c.id == id
            ? {
                ...c,
                followUpId: followUpId,
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <ProductStats />

        <Flex align="center" justify="space-between">
          <DateToProductFilter />
          <SimplePagination
            page={page}
            pageSize={pageSize}
            setPerPage={setPageSize}
            total={stats?.total}
            setPage={setPage}
          />
        </Flex>

        <Space direction="vertical" className="w-100">
          {loading ? (
            <TableLoading />
          ) : !products || products.length == 0 ? (
            <Card size="small">
              <NoDataFound
                title="You have not created any products yet"
                description="Click the new product button on Dealing/Product to create a new product"
                addText="New Product"
              />
            </Card>
          ) : (
            <>
              {products?.map((c, i) => (
                <Card size="small" key={i} className="no-shadow gray-border-hoverable">
                  <Row gutter={[6, 6]}>
                    <Col span={20} className="center-table-column">
                      <Space size={6} className="w-100" direction="vertical">
                        <Flex gap={24}>
                          <Text className="product-title">{c.name}</Text>
                          <Space>
                            <Checkbox
                              size="small"
                              onChange={(e) =>
                                handleUpateFollowups(
                                  c.id,
                                  e.target.checked,
                                  c.autoFollowupNotifications
                                )
                              }
                              checked={c?.autoFollowupEmails}
                            >
                              Email
                            </Checkbox>

                            <Checkbox
                              onChange={(e) =>
                                handleUpateFollowups(
                                  c.id,
                                  c.autoFollowupEmails,
                                  e.target.checked
                                )
                              }
                              checked={c?.autoFollowupNotifications}
                            >
                              Notifications
                            </Checkbox>
                          </Space>
                        </Flex>

                        <Flex align="center" justify="start" gap={6}>
                          <Select
                            defaultValue={c.followUpId}
                            value={c.followUpId}
                            onChange={(e) => handleFollowupUpdate(c.id, e)}
                            options={followUps}
                            placeholder="Select followup"
                            style={{ width: 150 }}
                          />
                          <div>
                            {c.productDeals?.map((d, ind) => (
                              <Text
                                className="deal-tag"
                                key={ind}
                                type="link"
                                onClick={() =>
                                  navigate(`/Deals/Details/${d.id}`)
                                }
                              >
                                {d.name}
                              </Text>
                            ))}

                            {c.subscribersCount > 2 && (
                              <Text type="link" className="deal-tag">
                                +{c.subscribersCount - 2} more
                              </Text>
                            )}
                          </div>
                        </Flex>
                        <Text>
                          {new Date(c.createdAt).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}
                          {c.lastEdit &&
                            ` • Last edit ${new Date(
                              c.lastEdit
                            ).toLocaleDateString("en-us", DateFormat)}`}
                        </Text>
                        {c?.tagName && <Text ellipsis>{c?.tagName}</Text>}

                        {c.owners && (
                          <Avatar.Group max={2} size={"small"}>
                            {c.owners?.map((d, ind) => (
                              <Avatar
                                size="small"
                                key={d.ind}
                                src={`${process.env.REACT_APP_FILE_URL}${d.profileUrl}`}
                              >
                                {d?.firstname[0]}
                                {d?.lastname[0]}
                              </Avatar>
                            ))}
                          </Avatar.Group>
                        )}
                      </Space>
                    </Col>

                    <Col span={4}>
                      <Flex align="center" justify="end" className="h-100">
                        <ProductListOptions
                          id={c.id}
                          onDelete={onDelete}
                          setShowEditing={(e) => setShowEdit(e)}
                          setCurrent={(e) => setCurrent(e)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
              <SimplePagination
                page={page}
                showChanger
                pageSize={pageSize}
                setPerPage={setPageSize}
                total={stats?.total}
                setPage={setPage}
              />
            </>
          )}
        </Space>
      </Space>
      {showEdit && (
        <UpdateProduct
          show={showEdit}
          setShow={setShowEdit}
          id={current}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default ProductList;
