import { Button, Col, Dropdown, Flex, Row, Space, Typography } from "antd";
import "./Call.css";
import { FaHeadphones, FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import Timer from "./Timer";
import UserAvatar from "./UserAvatar";
import { ImPhoneHangUp } from "react-icons/im";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { useNotification, Audio, TelnyxRTCContext } from "@telnyx/react-client";
import { GoDotFill } from "react-icons/go";
import { IoEllipsisHorizontal } from "react-icons/io5";

const Call = ({ beingCalled, activeCall, onHangup }) => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();
  const [contact, setContact] = useState();
  useEffect(() => {
    const contrller = new AbortController();
    const signal = contrller.signal;

    const fetch = async () => {

      let number = beingCalled ? activeCall?.options?.remoteCallerNumber : activeCall?.options?.destinationNumber
      const response = await axios.get(
        `/Contact/getbyphone/${number}`,
        {
          signal: signal,
        }
      );

      if (response.data.success) {
        setContact(response.data);
      }
    };

      fetch();
  
    return () => contrller.abort();
  }, []);

  const [loading, setLoading] = useState(false);

  const handleHangup = () => {
    setLoading(true);
    onHangup();

  };
  const [muted, setMuted] = useState(false);

  const mute = () => {
    if(muted){
      activeCall.unmuteAudio()
    }else{
      activeCall.muteAudio();
    }

    setMuted(prev => !prev);
  }

  const [currentMic, setCurrentMic] = useState(null);
  const [currentSpeaker, setCurrentSpeaker] = useState(null);
  
  useEffect(() => {
    const getCurrentDevices = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioTracks = stream.getAudioTracks();
        const micDeviceId = audioTracks[0]?.getSettings()?.deviceId;

        const devices = await navigator.mediaDevices.enumerateDevices();

        const micDevice = devices.find(
          device => device.kind === 'audioinput' && device.deviceId === micDeviceId
        );

        const speakerDevice = devices.find(device => device.kind === 'audiooutput');

        setCurrentMic(micDevice?.label || 'Unknown Microphone');
        setCurrentSpeaker(speakerDevice?.label || 'Unknown Speaker');
      } catch (error) {
        console.error('Error accessing devices:', error);
      }
    };

    getCurrentDevices();
  }, []);

  return (
    <>

<div>
        <Audio stream={activeCall && activeCall.remoteStream} />
      </div>

      <Row className="w-100 h-100">
        <Col span={12}>
          <div className="data-call-wrapper">

            <div className="rec-wrapper">
            <GoDotFill/>  RECORDING
            </div>
            <div className="audio-output-wrapper">
              <FaHeadphones />
              {currentSpeaker}
            </div>
            <div className="audio-output-wrapper">
              <FaMicrophone />
              {currentMic}
            </div>
          </div>
          <div className="call-wrapper border-right">
            <UserAvatar isCaller phone={beingCalled ? activeCall?.options?.destinationNumber : activeCall?.options?.remoteCallerNumber} />
          </div>
        </Col>

        <Col span={12}>
        <div className="caller-operations-wrapper">
        <Dropdown trigger={["click"]} menu={{
          items: [
            {
              key: 1,
              disabled:true,
              label: <p className="drop-down-text">Create a new deal</p>
            },
            {
              key: 2,
              disabled:true,
              label: <p className="drop-down-text">Assign to contact</p>
            },
            {
              key: 3,
              disabled:true,
              label: <p className="drop-down-text">Send email</p>
            },
            {
              key: 4,
              disabled:true,
              label: <p className="drop-down-text">Comment</p>
            }
          ]
        }}>
        <Button  className="flex-button"
          size="large"
          shape="circle"
          icon={<IoEllipsisHorizontal style={{ fontSize: 26 }} />}>
            </Button>
        </Dropdown>
        </div>
          <div className="call-wrapper">
            <UserAvatar phone={beingCalled ? activeCall?.options?.remoteCallerNumber  : activeCall?.options?.destinationNumber } name={contact?.name} />
          </div>
        </Col>
      </Row>

      <div className="dissmis-button-wrapper">
        <Title className="zero-margin-padding">
          <Timer />
        </Title>

        <Flex gap={12} align="center" justify="end">
          <Button className="mic-call-button" onClick={mute}>
            {muted ? 
            <FaMicrophone />
          : <FaMicrophoneSlash/> }
          </Button>
          <Button className="dissmis-call-button" loading={loading} onClick={handleHangup}>
            <ImPhoneHangUp />
          </Button>
        </Flex>
      </div>
    </>
  );
};

export default Call;