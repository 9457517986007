import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Typography,
  Flex,
  Avatar,
  Tooltip,
} from "antd";
import { MdHandshake } from "react-icons/md";
import { RiTaskFill } from "react-icons/ri";
import { FaBuilding, FaProjectDiagram } from "react-icons/fa";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { BiSolidFactory } from "react-icons/bi";
import { ReactComponent as FollowUpIcon } from "../../assets/icons/FollowUpIcon.svg";
import { ReactComponent as CampaignIcon } from "../../assets/icons/campaings.svg";
import { ReactComponent as DealsIcon } from "../../assets/icons/deals.svg";
import { ReactComponent as AudienceIcon } from "../../assets/icons/audience.svg";
import { ReactComponent as DesignIcon } from "../../assets/icons/designs.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/telephone.svg";

import cleanLogo from "../../assets/images/logo.png";
import SideMenuIcon from "./SideMenuIcon";
import "./Sidemenu.css";

const SideMenu = () => {
  const [current, setCurrent] = useState();
  const [currentChildren, setCurrentChildren] = useState();

  const { Title, Text } = Typography;

  const { Sider } = Layout;

  const navigate = useNavigate();
  const location = useLocation();
  const axios = useAxiosPrivate();

  const [tenant, setTenant] = useState();

  useEffect(() => {
    menuItems.forEach((c) => {
      if (c.key == location.pathname) {
        setCurrent(c);
        return;
      }

      
    });

    const fetchTenant = async () => {
      const response = await axios.get(`/tenant/current`);
      setTenant(response.data);
    };

    fetchTenant();
  }, []);

  const menuItems = [
    {
      label: (
        <SideMenuIcon
          Icon={CampaignIcon}
          iconClass={"campaigns"}
          isActive={current?.key == "/Campaigns"}
        />
      ),
      key: "/Campaigns",
      text: "Campaigns",
    },
    {
      key: "/Designs",
      label: (
        <SideMenuIcon
          iconClass={"design"}
          Icon={DesignIcon}
          isActive={current?.key == "/Designs"}
        />
      ),
      text: "Designs"
    },
    {
      key: "/contacts",
      label: (
        <SideMenuIcon
          Icon={AudienceIcon}
          iconClass={"audience"}
          isActive={current?.key == "/contacts"}
        />
      ),
      text: "Contacts"
    },
    {
      key: "/Deals",
      label: (
        <SideMenuIcon
          iconClass="deal"
          Icon={DealsIcon}
          isActive={current?.key == "/Deals"}
        />
      ),
      text: "Leads"},
    {
      key: "/Followup",
      label: (
        <SideMenuIcon
          iconClass="followUp"
          Icon={FollowUpIcon}
          isActive={current?.key == "/Followup"}
        />
      ),
      text: "Follow up",
    },
    {
      key: "/dial/number",
      label: (
        <SideMenuIcon
          iconClass="phone"
          Icon={PhoneIcon}
          isActive={current?.key == "/dial/number"}
        />
      ),
      text: "Phone dialer",
    },
   
  ];

  const settings = {
    key: "/settings",
    label: (
      <SideMenuIcon
        Icon={SettingsIcon}
        iconClass={"settings"}
        isActive={current?.key == "/settings"}
      />
    ),
    text: "Settings"
  };
  return (
    <>
      <Sider
        trigger={null}
        width={80}
        className="sider"
      >
        <Flex
          className="auto-overflow full-height"
          vertical
          justify="space-between"
          align="center"
        >
          <div>
            <Flex align="center" justify="center" className="top-margin-10">
              <img onClick={() => navigate('/')} src={cleanLogo} className="logo" />
            </Flex>
            <Flex
              className="w-100 margin-top-12"
              align="center"
              vertical
              justify="center"
              gap={12}
            >
              {menuItems.map((c, i) => (
                <Tooltip placement="right" key={i} title={c.text}>
                  <Button
                    key={i}
                    disabled={c.disabled}
                    onClick={() => {
                      setCurrent(c);
                      if (!c.children) {
                        navigate(c.key);
                      } else {
                        navigate(c.children[0].key);
                        setCurrentChildren(c.children[0]);
                      }
                    }}
                    className={`flex-button ${
                      current?.key == c.key ? "active-menu" : ""
                    } zero-margin-padding head-color`}
                    classNames="zero-margin-padding "
                    type="text"
                    size="large"
                    icon={c.label}
                  ></Button>
                </Tooltip>
              ))}
            </Flex>
          </div>

          <Flex vertical align="center" className="bottom-margin-12" gap={14}>
            <Tooltip placement="right" title={settings.text}>
              <Button
                key={"settings"}
                onClick={() => {
                  setCurrent(settings);
                  if (!settings.children) {
                    navigate(settings.key);
                  } else {
                    navigate(settings.children[0].key);
                    setCurrentChildren(settings.children[0]);
                  }
                }}
                className={`flex-button ${
                  current?.key == settings.key ? "active-menu" : ""
                } zero-margin-padding head-color`}
                type="text"
                size="large"
                icon={settings.label}
              ></Button>
            </Tooltip>

            <Avatar
              size="large"
              shape="square"
              className="company-avatar"
              icon={<BiSolidFactory />}
              src={`data:${tenant?.logoMimeType};base64,${tenant?.logoBase64}`}
            />
          </Flex>
        </Flex>
      </Sider>
    </>
  );
};

export default SideMenu;
