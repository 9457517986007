import { Select } from "antd";
import { useContext } from "react";
import { DealListContext } from "../DealListContext";
import { MdArrowDropDown } from "react-icons/md";

const ProductFilter = () => {
  const { filters, setFilters } = useContext(DealListContext);

  const handleOnChanged = (e) => {
    setFilters((prev) => ({ ...prev, selectedProducts: e }));
  };
  return (
    <Select
      size="large"
      maxTagCount="responsive"
      placeholder="Select products"
      suffixIcon={
        <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
      }
      onChange={handleOnChanged}
      style={{ width: 250 }}
      defaultValue={filters?.selectedProducts}
      options={filters?.products}
      mode="multiple"
    />
  );
};
export default ProductFilter;
