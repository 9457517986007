import { Flex, Tag } from "antd";
import { useContext } from "react";
import { DealListContext } from "./DealListContext";

const DealStats = () => {
  const { stats } = useContext(DealListContext);

  return (
    <Flex align="center" justify="start" style={{ marginTop: 12 }}>
      <Tag bordered={false} size="small" className="large-font-text">
        {stats?.total ?? 0} Total
      </Tag>
      <Tag bordered={false} color="blue" size="small" className="large-font-text">
        {stats?.new ?? 0} New
      </Tag>
      <Tag bordered={false} color="orange" size="small" className="large-font-text">
        {stats?.qualifying ?? 0} Qualifying
      </Tag>
      <Tag bordered={false} color="orange" size="small" className="large-font-text">
        {stats?.demoScheduled ?? 0} Scheduled
      </Tag>
      <Tag bordered={false} color="orange" size="small" className="large-font-text">
        {stats?.pendingCommitment ?? 0} Pending
      </Tag>
      <Tag bordered={false} color="orange" size="small" className="large-font-text">
        {stats?.inNegotiation ?? 0} Negotiation
      </Tag>
      <Tag bordered={false} color="green" size="small" className="large-font-text">
        {stats?.won ?? 0} Won
      </Tag>
      <Tag bordered={false} color="red" size="small" className="large-font-text">
        {stats?.lost ?? 0} Lost
      </Tag>
    </Flex>
  );
};

export default DealStats;
