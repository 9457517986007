import { useState} from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { InboxOutlined } from "@ant-design/icons";
import { Button, Flex, Modal, Space, Typography, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
const CreateMedia = ({ isOpen, setIsOpen, onAdd}) => {
  const { Text, Title } = Typography;
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList: fileList,
    onChange(info) {
      const selectedFile = info?.fileList[0]?.originFileObj;

      if (selectedFile) {
        setFile(selectedFile);
      } else {
        setFile(null);
      }

      const { status } = info.file;

     
    },
    onDrop(e) {
    },
  };

  const axiosPrivate = useAxiosPrivate();

  const handleSubmit = async () => {
    setLoading(true);
    const form = new FormData();

    form.append("File", file);

    var response = await axiosPrivate.post("/Document/Create", form);

    setLoading(false);

    if (!response || !response.data.success) {
      return;
    }

    onAdd(response.data)
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Upload media
        </Title>
      }
      footer={
        <>
          <Flex align="center" justify="end" gap={10}>
            <Button size="large" onClick={() => setIsOpen(false)}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => handleSubmit()}
              loading={loading}
              size="large"
            >
              Save changes
            </Button>
          </Flex>
        </>
      }
    >
      <Space style={{ width: "100%" }} size="large" direction="vertical">
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support only for a single csv file. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
      </Space>
    </Modal>
  );
};
export default CreateMedia;
