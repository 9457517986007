import {
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  message,
  Progress,
  Select,
  Space,
  Typography,
} from "antd";
import "./Recipients.css";
import { MdClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { Option } from "antd/es/mentions";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";

const Recipients = ({ active, setStep }) => {
  const { Text, Title } = Typography;
  const { data, setData } = useContext(CreateCampaignContext);

  const axios = useAxiosPrivate();

  const [tags, setTags] = useState();
  const [total, setTotal] = useState(0);

  const [fetching, setFetching] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setFetching(true);
    const fetch = async () => {
      const response = await axios.get(`Campaign/Email/Possible-EmailTags`, {
        signal: signal,
      });
      setTotal(response.data.totalLeft);
      setTags(response.data.items);
      setFetching(false);
    };
    fetch();

    return () => controller.abort();
  }, []);

  const handleChanged = (e) => {
    let sum = 0;
    e.forEach((item) => {
      let tag = tags.find((c) => c.id == item);

      if (tag?.total) {
        sum += tag.total;
      }
    });

    setData((prev) => ({ ...prev, totalRecipients: sum }));
  };

  useEffect(() => {
    form.setFieldValue(
      "tags",
      data?.tags?.map((c) => c.id)
    );
    form.setFieldValue("verifiedOnly", data?.onlySendToVerifiedContacts);
  }, [data?.tags, data?.onlySendToVerifiedContacts]);

  const [submiting, setSubmiting] = useState(false);
  const submit = async (e) => {
    setSubmiting(true);

    const response = await axios.put(`/Campaign/Email/Assign/Tags`, {
      onlyVerified: e.verifiedOnly,
      tags: e.tags,
      campaignId: data?.id,
    });

    if (response.data.success) {
      setData((prev) => ({ ...prev, recipientsStepFinished: true }));
      setStep();
    } else {
      message.error(response.data.message);
    }
    setSubmiting(false);
  };
  return (
    <Card
      className={`step-card no-shadow ${
        active ? "active-step" : ""
      }`}
    >
      <Flex className="w-100" align="center" justify="space-between">
        <Flex align="center" justify="start" gap={12}>
          {data?.recipientsStepFinished ? (
            <div className="step-evaluation-box-success">
              <FaCheck />
            </div>
          ) : (
            <div className="step-evaluation-box-default"></div>
          )}
          <Space direction="vertical" size={0}>
            <Title className="step-card-title zero-margin-padding" level={3}>
              Recipients
            </Title>
            {data?.recipientsStepFinished ? <Text>{data?.totalRecipients} recipients • {total} remaining emails</Text> :  <Text>The people who receive your campaign</Text>}
          </Space>
        </Flex>

        {active ? (
          <Button
            type="text"
            onClick={() => setStep()}
            className="flex-button"
            size="large"
            icon={<MdClose className="icon" />}
          ></Button>
        ) : (
          <Button size="large" className="w-300" onClick={() => setStep(2)}>
            Add recipients
          </Button>
        )}
      </Flex>
      <Form form={form} onFinish={submit}>
        {active && (
          <>
            <div className="recipients-work-wrapper">
              <Space className="w-100" size={"large"} direction="vertical">
                <Space className="w-100" direction="vertical" size={0}>
                  <Text className="thick-lable">Send to</Text>
                  <Form.Item name="tags">
                    <Select
                      loading={fetching}
                      optionLabelProp="label"
                      showSearch
                      onChange={handleChanged}
                      size="large"
                      dropdownRender={(menu) => <div>{menu}</div>}
                      filterOption={(input, option) =>
                        (option?.data ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      className="w-100"
                      placeholder="Select tags"
                      mode="multiple"
                    >
                      {tags?.map((c) => (
                        <Option value={c.id} label={c.name} data={c.name}>
                          <Space
                            classNames="sender-email-wrapper"
                            className="w-100"
                            size={0}
                            direction="vertical"
                          >
                            <Text className="sender-email">{c.name}</Text>

                            <Text className="sender-nickname-domain">
                              Total contacts: {c.total ?? 0}
                            </Text>
                          </Space>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item name="verifiedOnly" valuePropName="checked">
                    <Checkbox>Only send to verified contacts</Checkbox>
                  </Form.Item>
                </Space>

                <Space className="w-100" size={0} direction="vertical">
                  <Flex
                    className="w-100"
                    align="center"
                    justify="space-between"
                  >
                    <Text className="recipients-amount-text">
                      {data?.totalRecipients ?? 0} recipients
                    </Text>
                    <Text className="recipients-amount-text">{total} left</Text>
                  </Flex>
                  <Progress
                    percent={(data?.totalRecipients / total) * 100}
                    showInfo={false}
                  />
                  <Text>
                    Send to as many recipients as you wish, within your plan
                    limits.
                  </Text>
                </Space>
              </Space>

              <Flex className="margin-top" align="center" justify="end" gap={6}>
                <Button
                  size="large"
                   className="new-buttons large-font"
                  onClick={() => setStep()}
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  loading={submiting}
                  onClick={() => form.submit()}
                  type="primary"
                  className="new-buttons large-font"
                >
                  Save
                 
                </Button>
              </Flex>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
};

export default Recipients;
