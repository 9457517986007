import { TelnyxRTCProvider } from "@telnyx/react-client";
import { Outlet } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const PhoneLayout = () => {
  const [credential, setCredential] = useState();

  const axios = useAxiosPrivate();

  useEffect(() => {
    const contrller = new AbortController();
    const signal = contrller.signal;

    const fetch = async () => {
      const response = await axios.get(`/tenant/current`, {
        signal: signal,
      });

      setCredential({
        login: response.data.user,
        password: response.data.pass,
      });
    };

    fetch();

    return () => contrller.abort();
  }, []);

  return (
    <>
      {credential && (
        <TelnyxRTCProvider credential={credential}>
          <Outlet />
        </TelnyxRTCProvider>
      )}
    </>
  );
};

export default PhoneLayout;
