import { Button, Flex, Space } from "antd";
import React from "react";
import DealStats from "./DealStats";
import CompanyFilter from "./Filters/CompanyFilter";
import ProductFilter from "./Filters/ProductFilter";
import CreatedDateToFilter from "./Filters/CreatedDateToFilter";
import { DealsTabs } from "../../shared/enums";
import { IoMdSettings } from "react-icons/io";

const Header = ({ activeTab, setActiveTab }) => {
  const handleViewChangeClick = () => {
    setActiveTab((prev) =>
      prev == DealsTabs.List ? DealsTabs.Kanban : DealsTabs.List
    );
  };
  return (
    <>
      <Space direction="vertical" className="w-100" size={24}>
        <Flex align="center" justify="space-between">
          <DealStats />
          <Button size="large" className="large-font" type="link">
            <IoMdSettings />
          </Button>
        </Flex>
        <Flex align="center" gap={6} justify="space-between">
          <Flex align="center" gap={6} justify="start">
            <CompanyFilter />
            <ProductFilter />
            <CreatedDateToFilter />
          </Flex>

          <Flex align="center" gap={6} justify="end">
            {/*<CardItemsFilter />
            <StatusFilter />*/}

            <Button
              onClick={handleViewChangeClick}
              size="large"
              className="large-font"
              type="link"
            >
              {activeTab == DealsTabs.List ? "View as table" : "View as list"}
            </Button>
          </Flex>
        </Flex>
      </Space>
    </>
  );
};

export default Header;
