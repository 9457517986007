import { Card, Flex, Space, Tabs, Typography } from "antd";
import YourApiKeys from "./api/YouApiKeys";
import useAuth from "../../hooks/useAuth";
import UserList from "../users/UserManagement/UserList";
import SignatureList from "../templates/Signatures/SignatureList";
import ManageCoupons from "../prices/ManageCoupons";
import ManagePrices from "../prices/ManagePrices";
import "./ApiProxyLayout.css";
import MediaLayout from "../media/MediaLayout";
import SenderLayout from "../users/Senders/SenderLayout";

const ApiProxyLayout = () => {
  const { Text, Title } = Typography;

  const { auth } = useAuth();

  const items = [
    {
      key: -2,
      label: "Media",
      children: <MediaLayout />,
    },
    {
      key: -1,
      label: "Senders",
      children: <SenderLayout />,
    },
    {
      key: 1,
      label: "Developers",
      children: <YourApiKeys />,
    },
    {
      key: 4,
      label: "Signatures",
      children: <SignatureList />,
    },
  ];

  if (auth?.roles?.find((c) => c == "SuperAdmin")) {
    items.push({
      key: 3,
      label: "Team Managment",
      children: <UserList />,
    });
  }

  if (auth?.roles?.find((c) => c == "ApplicationOwner")) {
    items.push({
      key: 8,
      label: "Prices & Limits",
      children: <ManagePrices />,
    });

    items.push({
      key: 9,
      label: "Coupons",
      children: <ManageCoupons />,
    });
  }

  return (
    <Space direction="vertical" className="w-100" size={24}>
      <Title className="zero-margin-padding" level={1}>
        Settings
      </Title>
      <Tabs size="large" items={items} />
    </Space>
  );
};

export default ApiProxyLayout;
