import { Button, Flex, Select, Typography } from "antd";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

const SimplePagination = ({
  page,
  pageSize,
  total,
  setPage,
  setPerPage,
  showChanger = false,
}) => {
  const { Text } = Typography;

  const increasePage = () => {
    setPage((prev) => prev + 1);
  };

  const decreasePage = () => {
    setPage((prev) => prev - 1);
  };

  return (
    <Flex align="center" justify="end" gap={6}>
      {showChanger && (
        <>
          Rows per page
          <Select
            onChange={(e) => setPerPage(e)}
            style={{ width: 75 }}
            value={pageSize}
            defaultValue={25}
            options={[
              {
                value: 25,
                label: 25,
              },
              {
                value: 50,
                label: 50,
              },
              {
                value: 100,
                label: 100,
              },
            ]}
          />
        </>
      )}
      <Text>
        <Text strong>
          {pageSize * page - pageSize}-
          {page * pageSize > total ? total : pageSize * page}
        </Text>{" "}
        of {total}
      </Text>

      <Flex align="center" justify="center">
        <Button
          className="flex-button"
          onClick={decreasePage}
          type="link"
          size="small"
          disabled={page == 1}
        >
          <MdNavigateBefore style={{ fontSize: 26 }} />
        </Button>
        <Text className="simple-pagination-page">{page}</Text>
        <Button
          className="flex-button"
          onClick={increasePage}
          type="link"
          size="small"
          disabled={page * pageSize > total}
        >
          <MdNavigateNext style={{ fontSize: 26 }} />
        </Button>
      </Flex>
    </Flex>
  );
};

export default SimplePagination;
