import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";

export const CurrentUserContext = createContext({
    user: {},
    setUser: () => {},

    auth: {},
    setAuth: () => {}
})

export const CurrentUserContextProvider = ({children}) => {
    const [user, setUser] = useState();
    
    const { setAuth } = useAuth();
    const axios = useAxiosPrivate();
    useEffect(() => {
        const fetch = async () => {

            const response = await axios.get("Users/CurrentUser");
            
            setUser(response.data.data);
            setAuth((prev) => ({
              ...prev,
              subType: response.data.data.subscriptionType,
            }));
          }
      
          fetch()
    },[])
    return <CurrentUserContext.Provider value={{
        user
    }}>
        {children}
    </CurrentUserContext.Provider>
}