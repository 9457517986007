import { useContext } from "react";
import { AudienceContext } from "../AudienceContext";
import DateFilter from "../../../shared/DateFilter";

const ContactToDateFilter = () => {
  const { filters, setFilters } = useContext(AudienceContext);
  return <DateFilter filters={filters} setFilters={setFilters} />;
};

export default ContactToDateFilter;
