import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useContext, useMemo } from "react";
import AvatarGroup from "./AvatarGroup";
import {
  Button,
  Card,
  Dropdown,
  Flex,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { FaMoneyBillWave } from "react-icons/fa6";
import { DateFormat } from "../../../dateformat";
import { useNavigate } from "react-router-dom";
import { FiMoreVertical } from "react-icons/fi";
import { MdContactEmergency } from "react-icons/md";
import { DealListContext } from "../DealListContext";
import { DealCardItems } from "../../../shared/enums";
function animateLayoutChanges(args) {
  const { isSorting, wasSorting } = args;
  if (isSorting || wasSorting) {
    return defaultAnimateLayoutChanges(args);
  }
  return true;
}

const KanbanCard = ({
  card,
  className = "",
  cardDropdownOptions,
  cardFromDropdownObj,
}) => {
  const { Title, Text } = Typography;
  const { filters } = useContext(DealListContext);

  const navigate = useNavigate();
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: card.id,
    animateLayoutChanges,
    data: {
      type: "card",
      card,
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    margin: "5px 0px",
    cursor: "pointer",
    boxShadow: "none",
  };

  function navigateToCardDetails(e) {
    e.stopPropagation()
    navigate(`/Deals/Details/${card.id}`);
  }
  const cardElement = useMemo(() => {
    return (
      <Card
        {...attributes}
        {...listeners}
        onClick={navigateToCardDetails}
        ref={setNodeRef}
        style={{ ...style }}
        className="zero-margin-padding-head mt-12 zero-margin-padding gray-border-hoverable"
        title={
          <Flex align="center" justify="space-between">
            <Space direction="vertical" size={0}>
              {filters?.cardItems[DealCardItems.Products] ? (
                <Space size={0}>
                  {card.products?.length > 0 && (
                    <Tag className="black-color-tag" color="#e3e3e3">
                      {card.products[0].name}
                    </Tag>
                  )}

                  {card.products?.length > 1 && (
                    <Tag color="#e3e3e3" className="black-color-tag">{` +${
                      card.products.length - 1
                    }`}</Tag>
                  )}
                </Space>
              ) : (
                <div></div>
              )}

              {filters?.cardItems[DealCardItems.Companies] ? (
                <Space size={0}>
                  {card.company && (
                    <Tag className="black-color-tag" color="#e3e3e3">
                      {card.company}
                    </Tag>
                  )}

                  {card.companyCount > 1 && (
                    <Tag color="#e3e3e3" className="black-color-tag">{` +${
                      card.companyCount - 1
                    }`}</Tag>
                  )}
                </Space>
              ) : (
                <div></div>
              )}
            </Space>

            {(filters?.cardItems[DealCardItems.Contact] ||
              filters?.cardItems[DealCardItems.Amount]) && (
              <Tag className="black-color-tag" color="#e3e3e3">
                <Space>
                  {filters?.cardItems[DealCardItems.Contact] && (
                    <Tooltip title="Contacts">
                      <Flex align="center" justify="start" gap={5}>
                        <MdContactEmergency />
                        <Text>{card.contact}</Text>
                      </Flex>
                    </Tooltip>
                  )}

                  {filters?.cardItems[DealCardItems.Amount] && (
                    <Tooltip title="Amount">
                      <Flex align="center" justify="start" gap={5}>
                        <FaMoneyBillWave />
                        <Text>{card.amount}</Text>
                      </Flex>
                    </Tooltip>
                  )}
                </Space>
              </Tag>
            )}
          </Flex>
        }
      >
        <Space
          style={{ width: "100%", padding: 10 }}
          direction="vertical"
          size="large"
        >
          <Space style={{ width: "100%" }} size={0} direction="vertical">
            <Text
              strong
              onClick={navigateToCardDetails}
              level={4}
              type="link"
              style={{ margin: 0, cursor: "pointer", fontSize: 17 }}
            >
              {card.title}
            </Text>
            <Space direction="vertical" className="w-100" size={0}>
              {filters.cardItems[DealCardItems.CreatedDate] && (
                <Text className="icon-flex">
                  Created{" "}
                  {new Date(card.createdOn * 1000).toLocaleDateString(
                    "en-US",
                    DateFormat
                  )}
                </Text>
              )}
              {filters.cardItems[DealCardItems.ClosedDate] && (
                <Text className="icon-flex">
                  {" "}
                  Close Date{" "}
                  {card.closeDate
                    ? new Date(card.closeDate).toLocaleDateString(
                        "en-US",
                        DateFormat
                      )
                    : "No close date"}
                </Text>
              )}
            </Space>
          </Space>
          <Flex align="center" justify="space-between">
            {filters?.cardItems[DealCardItems.Author] ? (
              <AvatarGroup items={card.owners} color={card.color} />
            ) : (
              <div></div>
            )}
            <Dropdown
              menu={{ items: cardDropdownOptions }}
              arrow
              trigger={["click"]}
            >
              <Button
                shape="circle"
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                  cardFromDropdownObj.current.card = { ...card };
                }}
              >
                <FiMoreVertical />
              </Button>
            </Dropdown>
          </Flex>
        </Space>
      </Card>
    );
  }, [card, filters]);
  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        className="kanbanCardRect kanbanCard kanbanCardDrag"
        style={style}
      >
        {" "}
      </div>
    );
  }

  return cardElement;
};

export default KanbanCard;
