import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import NoDataFound from "../../../shared/NoDataFound";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import TableLoading from "../../../shared/TableLoading";
import FollowupStepOptions from "./FollowupStepOptions";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import UpdateFollowupStep from "./UpdateFollowupStep";
import { AiFillEdit } from "react-icons/ai";
import {
  StepExecutionConditionBridge,
  StepExecutionType,
} from "../../../shared/enums";
import { FaArrowLeft } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import "./CreateFollowUp.css";

import {DateFormat} from '../../../dateformat'

const CreateFollowUp = () => {
  const { Text, Title } = Typography;
  const { id } = useParams();

  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const [steps, setSteps] = useState();
  const [current, setCurrent] = useState();
  const [loading, setLoading] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState();

  const [data, setData] = useState();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const perPage = 7;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      let followupResponse = await axios.get(`/Folloup/${id}`, {
        signal: signal,
      });

      setData(followupResponse.data);
      form.setFieldValue("name", followupResponse.data.name);
    };

    fetch();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      setLoading(true);
      let response = await axios.get(
        `/Followup/Step/List?FollowUpId=${id}&page=${page}&pageSize=${perPage}`,
        {
          signal: signal,
        }
      );
      setSteps(response.data.items);
      setTotal(response.data.total);
      setLoading(false);
    };

    fetch();

    return () => controller.abort();
  }, [page]);

  const handleDeleteConfirmed = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    let response = await axios.delete(`Followup/Step/${item.id}`);

    if (response.data.success) {
      setSteps((prev) => prev.filter((d) => d.id != item.id));
    } else {
      message.error(response.data.message);
    }
  };

  const handleEnabledUpdate = async (id, enabled) => {
    let response = await axios.put("/Followup/Step/Enable", {
      id: id,
      enabled: enabled,
    });

    if (response.data.success) {
      setSteps((prev) =>
        prev.map((c) => (c.id == id ? { ...c, enabled: enabled } : c))
      );
    } else {
      message.error(response.data.message);
    }
  };

  const [form] = Form.useForm();

  const [addOpen, setAddOpen] = useState(false);

  return (
    <>
      <CreateANewStepModal id={id} open={addOpen} setOpen={setAddOpen} />

      <DeleteConfirmationModal
        item={current}
        onDeleteConfirm={handleDeleteConfirmed}
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
      />

      {updateOpen && (
        <UpdateFollowupStep
          open={updateOpen}
          setOpen={setUpdateOpen}
          item={current}
          onUpdated={(item) => {
            setSteps((prev) => prev.map((c) => (c.id == item.id ? item : c)));
            setUpdateOpen(false);
          }}
        />
      )}
      <Space className="w-100" size={24} direction="vertical">
        <Card size="small">
          <Flex align="center" justify="space-between">

          <Flex align="center" justify="start" gap={6}>
            <Button
              onClick={() => navigate(-1)}
              type="text"
              icon={<FaArrowLeft />}
              className="flex-button"
            ></Button>
            <EditableName
              name={data?.name}
              id={id}
              onUpdate={(e) => setData((prev) => ({ ...prev, name: e }))}
            />
          </Flex>

          <Button
                type="primary"
                size="large"
                className="new-buttons large-font"
                onClick={() => setAddOpen(true)}
              >
                Create step
              </Button>
          </Flex>

        </Card>

        <Space className="w-100" direction="vertical">
     
          <Card size="small">
            <Row>
              <Col span={10}>
                <Text className="column-header">Name</Text>
              </Col>
              
              <Col span={5}>
                <Text className="column-header">Enabled</Text>
              </Col>
              <Col span={5}>
                <Text className="column-header">Condition</Text>
              </Col>
              <Col span={4}>
                <Text className="column-header"></Text>
              </Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !steps || steps.length == 0 ? (
            <Card>
              <NoDataFound
                addText="New step"
                onAdd={() => navigate("/followup/create/step")}
                description="Click the New Campaign button on the campaigns/email page to create a new email campaign"
                title="You have not created any steps yet"
              />
            </Card>
          ) : (
            <>
              {steps.map((c, i) => (
                <Card key={i} size="small">
                  <Row>
                    <Col span={10} className="center-table-column">
                    <Space size={0} direction="vertical">

                      <Text className="step-table-title"
                        type="link"
                        onClick={() =>
                          navigate(`/Followup/details/${id}/step/${c.id}`)
                        }
                        >
                        {c.name}
                      </Text>
                      <Text className="step-table-createdAt">
                        {new Date(c.createdAt).toLocaleDateString("en-us", DateFormat )}
                        {c.lastEdit && ` • Last updated ${new Date(c.lastEdit).toLocaleDateString("en-us", DateFormat)}`}
                      </Text>
                        </Space>
                    </Col>
                    <Col span={5} className="center-table-column">
                      <Switch
                        onChange={(e) => handleEnabledUpdate(c.id, e)}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={c.enabled}
                      />
                    </Col>
                    <Col span={5} className="center-table-column">

                      {(c.executeAfterDays || c.executeAfterDays == 0) && c.bridge && c.executionCondition ? <>
                      <Tag className="step-table-condition-tag">{c.executeAfterDays} days</Tag>
                      {c.bridge == StepExecutionConditionBridge.And ? (
                        <Tag className="step-table-condition-tag">And</Tag>
                      ) : c.bridge == StepExecutionConditionBridge.Or ? (
                        <Tag className="step-table-condition-tag">Or</Tag>
                      ) : c.bridge == StepExecutionConditionBridge.Not ? (
                        <Tag className="step-table-condition-tag">Not</Tag>
                      ) : (
                        ""
                      )}
                      
                      {c?.executionCondition ==
                      StepExecutionType.UserSignedUp ? (
                        <Tag className="step-table-condition-tag">Sign up</Tag>
                      ) : c?.executionCondition ==
                      StepExecutionType.UserSubscribed ? (
                        <Tag className="step-table-condition-tag">Subscribe</Tag>
                      ) : c?.executionCondition ==
                      StepExecutionType.UserUnsubscribed ? (
                        <Tag className="step-table-condition-tag">Unsubscribing</Tag>
                      ) : c?.executionCondition ==
                      StepExecutionType.UserDeleted ? (
                        <Tag className="step-table-condition-tag">Delete</Tag>
                      ) : c?.executionCondition ==
                      StepExecutionType.TrialExceded ? (
                        <Tag className="step-table-condition-tag">Trial exceded</Tag>
                      ) : (
                        ""
                      )}</>
                     : <Tag className="step-table-no-condition-tag">No condition</Tag>}
                      </Col>
                      <Col span={4} className="center-table-column">
                    <Flex className="w-100" align="center" justify="end">

                      <FollowupStepOptions
                        onUpdate={(d) => navigate(`/Followup/details/${c.followupId}/step/${c.id}`)}
                        onDelete={(d) => {
                          setCurrent(d);
                          setDeleteOpen(true);
                        }}
                        id={c}
                        />
                        </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          )}

          <Card size="small">
            <Flex align="center" justify="end">
              <Pagination
                total={total}
                current={page}
                pageSize={perPage}
                onChange={(p) => setPage(p)}
              />
            </Flex>
          </Card>
        </Space>
      </Space>
    </>
  );
};

const CreateANewStepModal = ({ open, setOpen, id }) => {
  const [form] = Form.useForm();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const submit = async (e) => {
    setLoading(true);

    const response = await axios.post(`/Followup/Step`, {
      name: e.name,
      followupId: id,
    });

    if (response.data.success) {
      navigate(`/Followup/details/${id}/step/${response.data.id}`);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const { Text, Title } = Typography;
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title level={4} className="zero-margin-padding">
          Create a new step
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)} size="large">
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Save Changes
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={submit}>
        <Form.Item>
          <Text>Name</Text>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
              {
                max: 128,
              },
              { min: 3 },
            ]}
          >
            <Input
              size="large"
              className="w-100 "
              placeholder="Enter step name"
              count={{
                show: true,
                max: 128,
              }}
            />
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const EditableName = ({ id, name, onUpdate }) => {
  const { Title } = Typography;

  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const handleEditChange = (show) => {
    setEdit(show);
    form.setFieldValue("name", name);
  };

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.put(`/Followup`, {
      name: e.name,
      id: id,
    });

    if (response.data.success) {
      setEdit(false);
      onUpdate(e.name);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <>
      {edit ? (
        <>
          <Form form={form} onFinish={submit}>
            <Flex align="center" justify="start" gap={6}>
              <Form.Item
                name="name"
                className="no-error"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 128,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter name"
                  count={{
                    show: true,
                    max: 128,
                  }}
                />
              </Form.Item>

              <Button
                loading={loading}
                onClick={() => form.submit()}
                type="text"
                className="flex-button icon"
                icon={<MdCheck />}
              ></Button>
              <Button
                type="text"
                onClick={() => handleEditChange(false)}
                className="flex-button icon"
                icon={<IoMdClose />}
              ></Button>
            </Flex>
          </Form>
        </>
      ) : (
        <>
          <Title className="zero-margin-padding" level={4}>
            {name}
          </Title>

          <Button
            size="large"
            type="link"
            onClick={() => handleEditChange(true)}
            className="flex-button"
            icon={<AiFillEdit className="edit-icon" />}
          ></Button>
        </>
      )}
    </>
  );
};

export default CreateFollowUp;
