import { TemplateContextProvider } from "./TemplateContext";
import TemplateList from "./TemplateList";

const TemplateListLayout = () => {
  return (
    <TemplateContextProvider>
      <TemplateList />
    </TemplateContextProvider>
  );
};

export default TemplateListLayout;
