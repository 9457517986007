import { CompanyListContextProvider } from "./CompanyListContext";
import CompanyList from "./CompanyList";

const CompanyLayout = ({dataToAdd}) => {
  return (
    <CompanyListContextProvider>
      <CompanyList dataToAdd={dataToAdd}/>
    </CompanyListContextProvider>
  );
};

export default CompanyLayout;
