import Login from "./components/Authentication/Login";
import Home from "./pages/home/Home";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import Unauthorized from "./components/Authentication/Unauthorized";
import RequireAuth from "./components/Authentication/RequireAuth";
import PersistLogin from "./components/Authentication/PersistLogin";
import { Routes, Route } from "react-router-dom";
import CreateTemplate from "./pages/templates/Create/CreateTemplate";
import EditTemplate from "./pages/templates/Edit/EditTemplate";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import UserList from "./pages/users/UserManagement/UserList";
import CurrentUser from "./pages/users/UserManagement/CurrentUser";
import Security from "./pages/users/Auth/Security";
import CampaignDetails from "./pages/campaigns/Details/Details";
import CreateSimpleTemplate from "./pages/templates/Create/CreateSimpleTemplate";
import EditSimpleEmail from "./pages/templates/Edit/EditSimpleEmail";
import ResetPassword from "./components/Authentication/ResetPassword";
import WhatsAppTemplateList from "./pages/templates/Whatsapp/WhatsappTemplateList";
import NewWhatsAppSender from "./pages/users/Senders/whatsapp/NewWhatsAppSender";
import CreateWhatsAppTemplate from "./pages/templates/Whatsapp/CreateWhatsAppTemplate";
import SignatureList from "./pages/templates/Signatures/SignatureList";
import DealDetails from "./pages/deals/DealDetails/DealDetails";
import PoliciesWrapper from "./pages/policies/PoliciesWrapper";
import { Policy } from "./shared/enums";
import ProductList from "./pages/products/ProductList";
import CompanyList from "./pages/companies/CompanyList";
import SmsTemplates from "./pages/templates/Sms/SmsTemplates";
import SubscriptionList from "./pages/subscriptions/SubscriptionList";
import ManagePrices from "./pages/prices/ManagePrices";
import ManageCoupons from "./pages/prices/ManageCoupons";
import CouponDetails from "./pages/prices/CouponDetails";
import CampaignLayout from "./pages/campaigns/CampaignLayout";
import CreateCampaign from "./pages/campaigns/CreateUpdate/CreateCampaign";
import ProductLayout from "./pages/products/ProductLayout";
import CompanyLayout from "./pages/companies/CompanyLayout";
import TaskLayout from "./pages/tasks/TaskLayout";
import TaskList from "./pages/tasks/TaskList";
import SmsLayout from "./pages/templates/Sms/SmsLayout";
import ApplicationLayout from "./components/ApplicationLayout";
import FullHeightApplicationLayout from "./components/FullHeightApplicationLayout";
import ApiProxyLayout from "./pages/apiproxy/ApiProxyLayout";
import RegisterLayout from "./components/Authentication/Register/UserSteps/RegisterLayout";
import FirstStep from "./components/Authentication/Register/UserSteps/FirstStep";
import SecondStep from "./components/Authentication/Register/UserSteps/SecondStep";
import CompanyStepLayout from "./components/Authentication/Register/CompanySteps/CompanyStepLayout";
import RequireConfirmEmail from "./components/Authentication/RequireConfirmEmail";
import RequireLevel2Data from "./components/Authentication/RequireLevel2Data";
import FollowupLayout from "./pages/followup/FollowUpLayout";
import FollowupList from "./pages/followup/FollowupList";
import CreateFollowUp from "./pages/followup/create/CreateFollowup";
import CreateFollowupLayout from "./pages/followup/create/CreateFollowupLayout";
import NotificationTemplatesLayout from "./pages/templates/Notifications/NotificationTemplatesLayout";
import NotificationTemplatesList from "./pages/templates/Notifications/NotificationTemplatesList";
import CreateFollowUpStepLayout from "./pages/followup/create/CreateFollowupStepLayout";
import ContactLayout from "./pages/contacts/ContactLayout";
import ContactList from "./pages/contacts/ContactList";
import ImportContactLayout from "./pages/contacts/Import/ImportContactLayout";
import CreateTemplateLayout from "./pages/newemailtemplates/Create/CreateTemplateLayout";
import TemplateListLayout from "./pages/newemailtemplates/TemplateListLayout";
import TemplateList from "./pages/newemailtemplates/TemplateList";
import EmptyApplicationLayout from "./components/EmptyApplicationLayout";
import ImportTemplate from "./pages/newemailtemplates/ImportTemplate";
import CampaignSteps from "./pages/campaigns/CreateUpdate/CampaignSteps";
import CreateNotificationTemplate from "./pages/templates/Notifications/Create/CreateNotificationTemplate";
import CreateNotificationTemplateLayout from "./pages/templates/Notifications/Create/CreateNotificationTemplateLayout";
import CreateNewStep from "./pages/followup/create/CreateNewStep";
import CreateTemplateComponent from "./pages/templateComponents/CreateTemplateComponent";
import TemplateComponentListLayout from "./pages/templateComponents/TemplateComponentListLayout";
import TemplateComponentList from "./pages/templateComponents/TemplateComponentsList";
import VideoCallLayout from "./pages/videocall/VideoCallLayout";
import MeetingCallLayout from "./pages/videocall/MeetingCallLayout";
import DialerLayout from "./pages/dialer/DialerLayout";
import BuyPhoneNumber from "./pages/users/Senders/sms/Buy/BuyPhoneNumber";
import PhoneLayout from "./pages/dialer/PhoneLayout";
import UserDetails from "./pages/users/UserManagement/Details/UserDetails";
import ContactDetails from "./pages/contacts/Details/ContantDetails";
import TemplatesLayout from "./pages/templates/TemplatesLayout";
import LeadsLayout from "./pages/deals/LeadsLayout";
import SmsPhoneLayout from "./pages/SmsPhone/SmsPhoneLayout";

const ROLES = {
  ApplicationOwner: "ApplicationOwner",
  SuperAdmin: "SuperAdmin",
  Admin: "Admin",
  Moderator: "Moderator",
  Basic: "Basic",
};

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<RegisterLayout />}>
            <Route path="" element={<FirstStep />} />
          </Route>

          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="ResetPassword" element={<ResetPassword />} />

          <Route path="meeting/:id" element={<MeetingCallLayout />}></Route>
          <Route
            path="policy"
            element={<PoliciesWrapper view={Policy.LICENCE} />}
          />
          <Route
            path="policy/license"
            element={<PoliciesWrapper view={Policy.LICENCE} />}
          />
          <Route
            path="policy/terms-of-usage"
            element={<PoliciesWrapper view={Policy.TERMS} />}
          />
          <Route
            path="policy/prviacy-policy"
            element={<PoliciesWrapper view={Policy.PRIVACY} />}
          />
          <Route
            path="policy/affiliates"
            element={<PoliciesWrapper view={Policy.AFFILIATES} />}
          />

          <Route element={<PersistLogin />}>
            <Route path="confirm-email" element={<SecondStep />} />
            <Route path="create" element={<CompanyStepLayout />}></Route>
            <Route
              element={
                <RequireAuth allowedRoles={[ROLES.SuperAdmin, ROLES.Basic]} />
              }
            >
              <Route element={<RequireConfirmEmail />}>
                <Route element={<RequireLevel2Data />}>
                  <Route element={<EmptyApplicationLayout />}>
                    <Route
                      element={<ImportTemplate />}
                      path="/templates/email/import/:id"
                    ></Route>
                  </Route>
                  <Route element={<FullHeightApplicationLayout />}>
                    <Route
                      path="/phone/sms"
                      element={<SmsPhoneLayout />}
                    ></Route>

                    <Route path="/dial/number" element={<PhoneLayout />}>
                      <Route path="" element={<DialerLayout />} />
                    </Route>
                    <Route
                      path="/call/:id"
                      element={<VideoCallLayout />}
                    ></Route>
                    <Route
                      path="/Templates/Create/Whatsapp/:id"
                      element={<CreateWhatsAppTemplate />}
                    />
                    <Route
                      path="/Templates/Create/Component/:id"
                      element={
                        <CreateTemplateComponent></CreateTemplateComponent>
                      }
                    />

                    <Route
                      path="/Templates/CreateSimple/:id"
                      element={<CreateSimpleTemplate></CreateSimpleTemplate>}
                    />
                    <Route
                      path="/Templates/CreateSimple"
                      element={<CreateSimpleTemplate></CreateSimpleTemplate>}
                    />
                    <Route
                      path="/Templates/EditSimpleEmail/:id"
                      element={<EditSimpleEmail></EditSimpleEmail>}
                    />
                    <Route
                      path="/Templates/Create/:id"
                      element={<CreateTemplate></CreateTemplate>}
                    />
                    <Route
                      path="/Templates/Create"
                      element={<CreateTemplate></CreateTemplate>}
                    />
                    <Route
                      path="/Templates/Edit/:id"
                      element={<EditTemplate></EditTemplate>}
                    />
                  </Route>

                  <Route element={<ApplicationLayout />}>
                    <Route
                      path="/Template/Components"
                      element={<TemplateComponentListLayout />}
                    >
                      <Route path="" element={<TemplateComponentList />} />
                    </Route>

                    <Route
                      path="/Users"
                      allowedRoles={[ROLES.SuperAdmin]}
                      element={<UserList />}
                    />

                    <Route
                      path="/user/details/:authorId"
                      element={<UserDetails />}
                    />

                    <Route
                      element={
                        <RequireAuth allowedRoles={[ROLES.ApplicationOwner]} />
                      }
                    >
                      <Route
                        path="/Manage/Prices"
                        allowedRoles={[ROLES.ApplicationOwner]}
                        element={<ManagePrices />}
                      />
                    </Route>

                    <Route
                      path="/Manage/Coupons"
                      allowedRoles={[ROLES.ApplicationOwner]}
                      element={<ManageCoupons />}
                    />
                    <Route
                      path="/Manage/Coupon/:id"
                      element={<CouponDetails />}
                      allowedRoles={[ROLES.ApplicationOwner]}
                    />

                    <Route
                      element={
                        <RequireAuth
                          allowedRoles={[ROLES.Basic, ROLES.SuperAdmin]}
                        />
                      }
                    >
                      <Route path="/" element={<Home />} />

                      <Route
                        path="/phone-number/buy"
                        element={<BuyPhoneNumber />}
                      />

                      {/******** CONTACTS ********/}
                      <Route path="/contacts" element={<ContactLayout />}>
                        <Route
                          path="import"
                          element={<ImportContactLayout />}
                        />
                        <Route path=":id" element={<ContactDetails />} />
                        <Route path="" element={<ContactList />} />
                      </Route>
                      {/******** END ********/}

                      <Route
                        path="/Subscriptions"
                        element={<SubscriptionList />}
                      />

                      <Route
                        path="/Templates/Edit/Whatsapp/:id"
                        element={<CreateWhatsAppTemplate />}
                      />

                      <Route
                        path="/Templates/Whatsapp"
                        element={<WhatsAppTemplateList />}
                      />
                      <Route
                        path="/Templates/NewWhatsAppSender"
                        element={<NewWhatsAppSender />}
                      ></Route>

                      <Route
                        path="/Designs"
                        element={<TemplatesLayout />}
                      ></Route>

                      <Route path="/Templates/Email">
                        <Route
                          path="Create"
                          element={<CreateTemplateLayout />}
                        />
                        <Route path="" element={<TemplateListLayout />}>
                          <Route path="" element={<TemplateList />} />
                        </Route>
                      </Route>

                      <Route path="/Templates/Sms" element={<SmsLayout />}>
                        <Route path="" element={<SmsTemplates />}></Route>
                      </Route>

                      <Route
                        path="/Templates/Notification/Edit/:id"
                        element={<CreateNotificationTemplateLayout />}
                      >
                        <Route
                          path=""
                          element={<CreateNotificationTemplate />}
                        />
                      </Route>
                      <Route
                        path="/Templates/Notification"
                        element={<NotificationTemplatesLayout />}
                      >
                        <Route
                          path=""
                          element={<NotificationTemplatesList />}
                        />
                      </Route>

                      <Route
                        path="/Templates/SignatureList"
                        element={<SignatureList />}
                      />

                      <Route
                        path="/Campaigns"
                        element={<CampaignLayout />}
                      ></Route>

                      <Route
                        path="/Campaigns/Edit/:id"
                        element={<CreateCampaign />}
                      >
                        <Route
                          path="Desing"
                          element={<CreateTemplateLayout />}
                        />
                        <Route path="" element={<CampaignSteps />}></Route>
                      </Route>

                      <Route
                        path="/Campaigns/Details/:id"
                        element={<CampaignDetails />}
                      />

                      <Route path="Followup" element={<FollowupLayout />}>
                        <Route
                          path="details/:id"
                          element={<CreateFollowupLayout />}
                        >
                          <Route
                            path="step/:stepId"
                            element={<CreateFollowUpStepLayout />}
                          >
                            <Route
                              path="Design"
                              element={<CreateTemplateLayout />}
                            />
                            <Route path="" element={<CreateNewStep />}></Route>
                          </Route>
                          <Route path="" element={<CreateFollowUp />}></Route>
                        </Route>
                        <Route path="" element={<FollowupList />}></Route>
                      </Route>

                      <Route
                        path="/Deals/Details/:id"
                        element={<DealDetails />}
                      ></Route>

                      <Route path="/Deals" element={<LeadsLayout />}>
                        <Route path="Tasks" element={<TaskLayout />}>
                          <Route path="" element={<TaskList />} />
                        </Route>

                        <Route path="Products" element={<ProductLayout />}>
                          <Route path="" element={<ProductList />} />
                        </Route>

                        <Route path="Companies" element={<CompanyLayout />}>
                          <Route path="" element={<CompanyList />}></Route>
                        </Route>
                      </Route>
                      <Route path="/Users/profile" element={<CurrentUser />} />

                      <Route path="/Users/Security" element={<Security />} />

                      <Route
                        path="/settings"
                        element={<ApiProxyLayout />}
                      ></Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
