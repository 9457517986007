import { Flex, message, Spin, Typography } from "antd";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import * as signalR from '@microsoft/signalr';
import { CompanyStepsContext } from "./CompanyStepsContext";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";

import "./SetupStep.css"
const SetupStep = () => {
    const {Text} = Typography

    const axios = useAxiosPrivate();

    const [connection, setConnection] = useState();
    const {setAuth, auth} = useAuth();
    
    const [submited, setSubmited]= useState(false)
    const {
        phone,
        basics,
        company,
        organisation,
      } = useContext(CompanyStepsContext);

      const navigate = useNavigate();

      const [currentMessage, setCurrentMessage] = useState(() => ({
        message: "Please wait",
        description: "Please hold on for a moment while we complete this process. We appreciate your patience as we ensure everything is accurately set up for you. This won't take long, and we’ll notify you as soon as it’s done."
      }));

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_URL}/signup`)
            .withAutomaticReconnect()
            .build();

        async function startConnection() {
            try {
                await connection.start();
                
                const id = connection.connectionId;

                if(!submited && !auth.step != 3){

                    await submit(id);
                }

                setConnection(id);
            } catch (error) {
                console.error('Error connecting to SignalR:', error);
                setTimeout(startConnection, 5000);
            }
        }

        connection.onclose(() => {
            startConnection();
        });

        connection.on("ProccessUpdated", (e) => {
            setCurrentMessage(JSON.parse(e));
        })

        startConnection();

        return () => {
            connection.stop();
        };
    }, []);


    const submit = async (connId) => {
        setSubmited(true)
        let response = await axios.post("/Account/Assign-User-Data", {
            firstname: basics.firstName,
            lastname: basics.lastName,
            companyName: basics.companyName,
            website: basics.companyWebsite,
            companyHasWebsite: basics.companyHasWebsite,
            address: company.address,
            zipCode: company.zip,
            city: company.city,
            country: company.country,
            teamMemberNo: organisation.noTeam,
            contactsNo: organisation.noAudiences,
            onlineStore: organisation.onlineStore,
            recieveMessages: organisation.recieveUpdates,
            phoneNumber: phone,
            connId: connId
          });
      
          if (response.data.success) {
            setAuth((prev) => ({ ...prev, step: 3 }));
            navigate("/campaigns");
          } else {
            message.error(response.data.message);
          }
    }
  return (
    <Flex align="center" justify="center" className="w-100 h-100" vertical>
      <Spin size="large" />
      <Text className="job-message">{currentMessage?.message}</Text>
      <Text className="job-description">{currentMessage?.description}</Text>

    </Flex>
  );
};

export default SetupStep;
