import React from "react";
import { Space } from "antd";
import { DealsDetailsContextProvider } from "../../../context/DealsDetailsContext";
import DealDetailsMain from "./DealDetailsMain/DealDetailsMain";
import DealDetailsHeader from "./DealDetailsHeader/DealDetailsHeader";

const DealDetails = () => {
  return (
    <DealsDetailsContextProvider>
      <Space className="w-100" direction="vertical">
        <DealDetailsHeader />
        <DealDetailsMain />
      </Space>
    </DealsDetailsContextProvider>
  );
};

export default DealDetails;
