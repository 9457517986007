import {
  Button,
  Card,
  Col,
  Flex,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import ContactStats from "./ContactStats";
import { MdCall, MdEmail } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContactToDateFilter from "./Filters/ContactToDateFilter";
import { IoClose } from "react-icons/io5";
import { DateFormat } from "../../dateformat";
import { useContext } from "react";
import { AudienceContext } from "./AudienceContext";
import NoDataFound from "../../shared/NoDataFound";
import TableLoading from "../../shared/TableLoading";
import ContactOptions from "./ContactOptions";
import ContactTagsFilter from "./Filters/ContactTagsFilter";
import ProfileAvatar from "../../components/ProfileAvatar";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AddTagToContactSider from "./AddTagToContactSider";
import CreateContactDrawer from "./Create/CreateContactDrawer";
import EditContactDrawer from "./Edit/EditContactDrawer";
import "./ContactList.css";
import SendEmailModal from "../../shared/SendEmailModal";
import { AiFillEdit } from "react-icons/ai";
import SimplePagination from "../../shared/SimplePagination";
const ContactList = () => {
  const navigate = useNavigate();

  const axios = useAxiosPrivate();

  const {
    perPage,
    setPerPage,
    loading,
    contacts,
    setContacts,
    page,
    setPage,
    stats,
    setStats,
  } = useContext(AudienceContext);

  const { Text, Title } = Typography;
  const [current, setCurrent] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    const response = await axios.delete(`/Contacts/${item}`);

    if (response.data.success) {
      setContacts((prev) => prev.filter((c) => c.id != item));
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
    } else {
      message.error(response.data.message);
    }
  };

  const deleteFromTag = async (tagId, contactId) => {
    const response = await axios.delete(
      `/Contacts/Remove-From-Tag/${tagId}/${contactId}`
    );

    if (response.data.success) {
      setContacts((prev) =>
        prev.map((c) =>
          c.id == contactId
            ? {
                ...c,
                tags: c.tags.filter((t) => t.id != tagId),
              }
            : c
        )
      );
    } else {
      message.error(response.data.message);
    }
  };

  const [editTagsOpen, setEditTagsOpen] = useState(false);

  const onAssigned = (id, newTags) => {
    setContacts((prev) =>
      prev.map((c) =>
        c.id == id
          ? {
              ...c,
              tags: newTags,
            }
          : c
      )
    );
  };

  const onEdit = (item) => {
    setContacts((prev) => prev.map((c) => (c.id == item.id ? item : c)));
  };

  const [addOpen, setAddOpen] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);

  const handleSendClicked = (e) => {
    setCurrent(e);
    setSendOpen(true);
  };

  return (
    <>
      <SendEmailModal
        contactId={current?.id}
        open={sendOpen}
        setOpen={setSendOpen}
      />
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current?.id}
      />

      <EditContactDrawer
        onEdit={onEdit}
        item={current}
        open={editOpen}
        setOpen={setEditOpen}
      />

      <AddTagToContactSider
        onUpdate={onAssigned}
        setOpen={setEditTagsOpen}
        open={editTagsOpen}
        contact={current}
      />

      <CreateContactDrawer
        open={addOpen}
        setOpen={setAddOpen}
        onAdd={(e) => {
          setStats((prev) => ({
            ...prev,
            total: prev.total + 1,
          }));

          setContacts((prev) => [e, ...prev]);
        }}
      />
      <Space direction="vertical" className="w-100" size={24}>
        <Flex align="center" justify="space-between">
          <Title className="zero-margin-padding">Contacts</Title>

          <Flex align="center" justify="end" gap={6}>
            <Button
              type="primary"
              size="large"
              onClick={() => setAddOpen(true)}
              className="new-buttons large-font"
            >
              Create contact
            </Button>

            <Button
              type="primary"
              size="large"
              onClick={() => navigate("/contacts/import")}
              className="new-buttons large-font"
            >
              Import contacts
            </Button>
          </Flex>
        </Flex>

        <ContactStats />

        <Flex align="center" justify="space-between" gap={6}>
          <Flex align="center" justify="start" gap={6}>
            <ContactToDateFilter />
            <ContactTagsFilter />
          </Flex>
          <SimplePagination
            page={page}
            pageSize={perPage}
            total={stats?.total}
            setPage={setPage}
          />
        </Flex>

        <Space className="w-100" direction="vertical">
          {loading ? (
            <TableLoading />
          ) : !contacts || contacts.length == 0 ? (
            <Card size="small">
              <NoDataFound
                addText="New contacts"
                description="Click the New Contact button on the Contacts page to create a new contact"
                title="You have not created any contacts yet"
              />
            </Card>
          ) : (
            <>
              {contacts?.map((c, ind) => (
                <Card
                  size="small"
                  className="no-shadow gray-border-hoverable"
                  key={ind}
                >
                  <Row gutter={[24, 24]} style={{ height: "100%" }}>
                    <Col span={12} className="center-table-column">
                      <Flex align="center" justify="start" gap={12}>
                        <ProfileAvatar
                          size={55}
                          font={25}
                          name={`${c.firstname} ${c.lastname}`}
                        />
                        <Space className="w-100" direction="vertical" size={0}>
                          <Flex gap={6}>
                            <Text
                              type="link"
                              className="contact-title"
                              onClick={() => navigate(`/contacts/${c.id}`)}
                            >
                              {c.firstname ? c.firstname : "-"}{" "}
                              {c.lastname ? c.lastname : "-"}
                            </Text>
                            {c.emailStatus == 1 ? (
                              <Tag
                                color="green"
                                className="flex-button"
                                style={{
                                  fontWeight: 600,
                                  borderWidth: "2px",
                                }}
                              >
                                SAFE
                              </Tag>
                            ) : (
                              ""
                            )}
                          </Flex>
                          <Text>
                            {`#${(page - 1) * perPage + ind + 1}`} {c.email} {`• ${c.phone ?? "No phone"}`}
                          </Text>
                          <Text ellipsis>
                            {new Date(c.createdAt).toLocaleDateString(
                              "en-us",
                              DateFormat
                            )}

                            {c?.lastUpdate &&
                              ` • Last update ${new Date(
                                c?.lastUpdate
                              ).toLocaleDateString("en-us", DateFormat)}`}
                          </Text>
                        </Space>
                      </Flex>
                    </Col>
                    <Col span={8} className="center-table-column">
                      {c.tags?.slice(0, 1).map((d, i) => (
                        <Text key={i} className="contact-tag">
                          <Flex gap={12} align="center" justify="space-between">
                            {d.name}

                            <Popconfirm
                              title="Remove contact from tag"
                              description="Are you sure to delete this tag-contact association?"
                              onConfirm={() => deleteFromTag(d.id, c.id)}
                              okText="Yes, remove it"
                              cancelText="No"
                            >
                              <Button
                                className="flex-button contact-tag-button"
                                danger
                                type="text"
                                size="small"
                                icon={<IoClose className="icon" />}
                              ></Button>
                            </Popconfirm>
                          </Flex>
                        </Text>
                      ))}
                      {c.tags?.length > 1 ? (
                        <Text className="contact-tag">
                          + {c.tags.length - 1} more...
                        </Text>
                      ) : (
                        ""
                      )}

                      <Button
                        className="flex-button contact-tag-add-button"
                        onClick={() => {
                          setCurrent(c);
                          setEditTagsOpen(true);
                        }}
                        icon={<FiPlus className="icon" />}
                      />
                    </Col>
                    <Col span={4}>
                      <Flex
                        gap={6}
                        align="center"
                        justify="end"
                        className="w-100 h-100"
                      >
                        <Tooltip title="Edit">
                          <Button
                            size="large"
                            onClick={() => {
                              setCurrent(c);
                              setEditOpen(true);
                            }}
                            icon={
                              <AiFillEdit className="campaign-options-icon" />
                            }
                            className="flex-button"
                            type="link"
                          ></Button>
                        </Tooltip>

                        <Tooltip title="Send email">
                          <Button
                            size="large"
                            icon={<MdEmail className="campaign-options-icon" />}
                            className="flex-button"
                            onClick={() => handleSendClicked(c)}
                            type="text"
                          ></Button>
                        </Tooltip>

                        <Tooltip title="Call">
                          <Button
                            size="large"
                            icon={<MdCall className="campaign-options-icon" />}
                            className="flex-button"
                            onClick={() =>
                              navigate(`/dial/number?number=${c.phone}`)
                            }
                            type="text"
                          ></Button>
                        </Tooltip>

                        <ContactOptions
                          contact={c}
                          onSend={handleSendClicked}
                          setCurrent={setCurrent}
                          openEdit={() => setEditOpen(true)}
                          openDelete={() => setDeleteOpen(true)}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <SimplePagination
                showChanger
                page={page}
                pageSize={perPage}
                total={stats?.total}
                setPage={setPage}
                setPerPage={setPerPage}
              />
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default ContactList;
