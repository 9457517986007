import { Button, Dropdown } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { BiRename } from "react-icons/bi";

const SmsSenderOptions = ({ onMakeDefault, onDelete, id, setCurrent, showDelete, onAssignName}) => {
    const items = [
      {
        key: 0,
        label: <p className="drop-down-text">Assign Name</p>,
        icon: <BiRename className="drop-down-icon"/>,
        onClick:() => {
          onAssignName(id)
        }
      },
      {
        key: 1,
        label: <p className="drop-down-text">Make default</p>,
        icon: <IoMdSettings className="drop-down-icon"/>,
        onClick:() => {
          onMakeDefault(id)
        }
      },
      {
        key: 2,
        label: <p className="drop-down-text">Delete</p>,
        icon: <BsTrashFill className="drop-down-icon" />,
        danger: true,
        onClick: () => {
          setCurrent(id)
          showDelete(true)
        }
      }
    ];
  
    return (
      <>
        <Dropdown trigger={["click"]} menu={{ items }}>
          <Button
            type="text"
            size="large"
            className="flex-button"
            icon={
              <IoEllipsisHorizontal className="icon" />
            }
          ></Button>
        </Dropdown>
      </>
    );
  };
  
  export default SmsSenderOptions;