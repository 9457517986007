import { Button, Card, Drawer, Flex, Select, Space, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const AddSignatureDrawer = ({ open, setOpen, onSave }) => {
  const { Text } = Typography;

  const axios = useAxiosPrivate();

  const [signatures, setSignatures] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const signal = controller.signal;

    const fetch = async () => {
      setLoading(true);
      const response = await axios.post(
        "/Signature/GetPaged",
        {
          pageSize: 999,
          page: 1,
        },
        {
          signal: signal,
        }
      );

      setSignatures(response.data.data);
      setLoading(false);
    };

    fetch();

    return () => controller.abort();
  }, []);

  const [selectedSignature, setSelectedSignature] = useState();

  const generateSignatureSpan = () => {
    return `<div id='signature'>${selectedSignature}</div>`
  }

  const save = () => {
    onSave(generateSignatureSpan());
  }
  return (
    <Drawer
      title="Add your signature"
      open={open}
      onClose={() => setOpen(false)}
    >
      <Flex
        className="w-100 h-100"
        align="center"
        justify="space-between"
        vertical
      >
        <Space direction="vertical" className="w-100" size="large">
          <Space direction="vertical" className="w-100" size={0}>
            <Text className="bold">Select signature</Text>
            <Select
              loading={loading}
              options={signatures?.map((c) => ({
                value: c.id,
                label: c.name,
                text: c.text
              }))}
              placeholder="Enter your signature"
              onChange={(e)=> {
                setSelectedSignature(signatures?.find(c => c.id == e).text)
              }}
              className="w-100"
              size="large"
            />
          </Space>

          {selectedSignature && (
            <Space size={0} direction="vertical" className="w-100">
              <Text className="bold">Preview</Text>
              <Card size="small" className="no-shadow">
                <div className="zero-margin-padding-children"
                  dangerouslySetInnerHTML={{ __html: selectedSignature }}
                ></div>
              </Card>
            </Space>
          )}
        </Space>

        <Flex align="center" className="w-100" justify="end" gap={6}>
          <Button size="large" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          <Button disabled={!selectedSignature} type="primary" size="large" onClick={save}>
            Insert signature
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  );
};
export default AddSignatureDrawer;
