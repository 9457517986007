import {
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Flex,
  Pagination,
  Row,
  Space,
  Typography,
} from "antd";
import "./Planner.css";
import {
  MdNavigateBefore,
  MdNavigateNext,
  MdNotifications,
  MdPeopleAlt,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import QueryString from "qs";
import getTaskTypeIcon from "../../shared/TaskTypeIcon";
import getTaskTypeText from "../../shared/TaskTypeText";

const Planner = () => {
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(dayjs());

  const axiosPrivate = useAxiosPrivate();

  const onPanelChange = (value) => {
    setCurrentDate(value);
  };

  const handlePrevMonth = () => {
    setPage(1)
    setCurrentDate(currentDate.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setPage(1)
    setCurrentDate(currentDate.add(1, "month"));
  };

  const handleContactsClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    navigate("/contacts");
  };

  const handleCCampaignsClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    navigate("/campaigns");
  };

  const onDateSelect = (value) => {
    setPage(1)
    setCurrentDate(value);
  };

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const perPage = 3;
  const [total, setTotal] = useState(0);
  const [events, setEvents] = useState({});
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      const response = await axiosPrivate.get(`/DealTasks/calendar`, {
        params: {
          date: currentDate.toDate(),
          page: page,
          pageSize: perPage,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
        signal: signal,
      });

      setTotal(response.data.total);
      setData(response.data);

      const formattedEvents = response.data.calendar.reduce((acc, date) => {
        console.log(date)
        const formattedDate = dayjs(new Date(date.date)).format('YYYY-MM-DD');
        acc[formattedDate] = true;
        return acc;
      }, {});

      setEvents(formattedEvents);
    };

    fetch();

    return () => controller.abort();
  }, [currentDate, page]);

  const handleTaskClick = (id) => {
    navigate(`/Deals/Details/${id}`);
  };

  const dateFullCellRender = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    const isCurrentMonth = date.month() === currentDate.month(); // Check if it's in the current month
    const isSelectedDate = date.isSame(currentDate, 'day'); // Check if this is the selected date
    const hasEvent = events[formattedDate];

    const selectedStyle = {
      outline: '5px solid #7586e0',
      outlineOffset: "-3px",
      borderRadius: '5px',
    };

    return isCurrentMonth ? (
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
          ...(isSelectedDate ? selectedStyle : {}),
        }}
      >
        <Badge dot={hasEvent && !isSelectedDate}>

        <div style={{fontWeight:"600", padding:"5px"}}>{date.date()}</div>
        </Badge>
      </div>
    ) : null; 
  };

  return (
    <Card size="small" bodyStyle={{ padding: 0, margin: 0 }}>
      <Row gutter={[0, 0]}>
        <Col span={6}>
          <Calendar
            fullscreen={false}
            className="w-100"
            dateFullCellRender={dateFullCellRender}
            value={currentDate}
            onPanelChange={onPanelChange}
            onSelect={onDateSelect}
            headerRender={() => (
              <Flex
                className="planed-calendar-header"
                align="center"
                justify="space-between"
              >
                <Button
                  type="link"
                  size="large"
                  className="bold flex-button"
                  onClick={handlePrevMonth}
                >
                  <MdNavigateBefore className="planer-move-button-icons" />
                </Button>

                <Title level={5} className="zero-margin-padding">
                  {currentDate.format("MMMM YYYY")}
                </Title>
                <Button
                  type="link"
                  size="large"
                  className="bold flex-button"
                  onClick={handleNextMonth}
                >
                  <MdNavigateNext className="planer-move-button-icons" />
                </Button>
              </Flex>
            )}
          />
        </Col>
        <Col span={18}>
          <div className="planed-right-side">
            <Flex
              vertical
              gap={24}
              justify={data?.items?.length > 0 ? "" : "space-between"}
              className="w-100 h-100"
            >
              <Flex align="center" justify="space-between">
                <Space className="w-100" size={0} direction="vertical">
                  <Title className="zero-margin-padding" level={3}>
                    Planned for {currentDate.format("MMMM, DD YYYY")}
                  </Title>
                  {data?.items?.length == 0 && (
                    <Text>
                      Nothing planned for {currentDate.format("MMMM, DD YYYY")}
                    </Text>
                  )}
                </Space>

                <Flex align="center" justify="end">
                  <Pagination
                    pageSize={perPage}
                    size="small"
                    current={page}
                    onChange={(p) => setPage(p)}
                    total={total}
                  />
                </Flex>
              </Flex>

              {data?.items?.length > 0 ? (
                <>
                  <Space direction="vertical" className="w-100">
                    {data.items.map((c, i) => (
                      <Card
                        onClick={() => handleTaskClick(c?.deal?.id)}
                        size="small"
                        className="planer-task-card no-shadow"
                      >
                        <Flex align="start" justify="space-between">
                          <Space direction="vertical">
                            <Text className="planer-task-name">
                              {c.name ?? "-"}
                            </Text>
                            <Flex align="center" justify="start" gap={6}>
                              <div className="planer-task-type">
                                {getTaskTypeIcon(c.taskType)}{" "}
                                {getTaskTypeText(c.taskType)}
                              </div>
                              {c.owners?.slice(-1)?.map((d, c) => (
                                <div className="panler-task-owner">
                                  {d.email}
                                </div>
                              ))}
                              {c.owners?.length > 1 && (
                                <div className="panler-task-owner">
                                  +{c.owners?.length - 1}
                                </div>
                              )}
                              {c.overDue && (
                                <div className="planer-overdue-task">
                                  OVERDUE
                                </div>
                              )}
                            </Flex>
                          </Space>

                          <Text>
                            Scheduled for{" "}
                            {new Date(c.dueDate).toLocaleDateString()}{" "}
                            {c.dueTimeDateTime
                              ? `at ${new Date(
                                  c.dueTimeDateTime
                                ).toLocaleTimeString()}`
                              : ""}
                          </Text>
                        </Flex>
                      </Card>
                    ))}
                  </Space>
                </>
              ) : (
                <Row gutter={[24, 24]}>
                  <Col span={12}>
                    <Card
                      onClick={handleContactsClick}
                      size="small"
                      className="no-shadow h-100 planer-card"
                    >
                      <MdPeopleAlt className="planed-icon" />
                      <Title className="zero-margin-padding" level={4}>
                        Do you have new contacts to organize?
                      </Title>
                      <Text>
                        Use tags to send personalized messages and organize your
                        contacts.
                      </Text>
                      <Flex align="center" justify="end">
                        <Button
                          type="link"
                          size="large"
                          className="bold flex-button"
                          onClick={handleContactsClick}
                        >
                          Tag your contacts <MdNavigateNext className="icon" />
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      onClick={handleCCampaignsClick}
                      size="small"
                      className="no-shadow h-100 planer-card"
                    >
                      <MdNotifications className="planed-icon" />
                      <Title className="zero-margin-padding" level={4}>
                        Expand your reach with campaigns
                      </Title>
                      <Text>
                        Use all types of campaigns to grow your reach.
                      </Text>
                      <Flex align="center" justify="end">
                        <Button
                          type="link"
                          onClick={handleCCampaignsClick}
                          size="large"
                          className="bold flex-button"
                        >
                          Go to your campaigns{" "}
                          <MdNavigateNext className="icon" />
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              )}
            </Flex>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Planner;
