import {
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  message,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import QueryString from "qs";
import TableLoading from "../../shared/TableLoading";
import NoDataFound from "../../shared/NoDataFound";
import { useNavigate } from "react-router-dom";
import { DateFormatWithHour } from "../../dateformat";
import { MdCall } from "react-icons/md";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { BsTrashFill } from "react-icons/bs";
import { VscCallIncoming, VscCallOutgoing } from "react-icons/vsc";
import "./RecentCalls.css";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import SimplePagination from "../../shared/SimplePagination";

const RecentCalls = ({ onCallClicked, showMainCard = true }) => {
  const { Text, Title } = Typography;

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const [page, setPage] = useState(1);
  const perPage = 7;

  const [total, setTotal] = useState(0);

  const [data, setData] = useState();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetch = async () => {
      setLoading(true);
      const response = await axios.get("/call-events", {
        params: {
          page: page,
          pageSize: perPage,
        },
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
        signal: signal,
      });

      setData(response.data.items);
      setTotal(response.data.total);
      setLoading(false);
    };

    fetch();

    return () => controller.abort();
  }, [page]);

  const navigate = useNavigate();

  const handleCallClick = (id) => {
    if (id) {
      navigate(`/contacts/${id}`);
    }
  };

  const deleteCall = async (id) => {
    const response = await axios.delete(`/call-events/${id}`);

    if (response.data.success) {
      setTotal((prev) => prev - 1);
      setData((prev) => prev.filter((c) => c.id != id));
    } else {
      message.error(response.data.message);
    }
  };

  const transcribe = async (e) => {
    const response = await axios.post(`/call-events/transcribe`, {
      id: e,
    });
  };

  return (
    <>
      {showMainCard ? (
        <Card className={`w-100 no-round recent-calls-wrapper`}>
          <Space className="w-100" size={24} direction="vertical">
            <Flex align="center" justify="space-between">
              <Space className="w-100" size={0} direction="vertical">
                <Title level={3} className="zero-margin-padding">
                  Recent calls
                </Title>

                <Text className="dialer-info-text">
                  Keep this page open to recieve incoming calls.
                </Text>
              </Space>
            </Flex>
            <Space direction="vertical" className="w-100">
              <SimplePagination
                page={page}
                pageSize={perPage}
                total={total}
                setPage={setPage}
              />

              {loading ? (
                <TableLoading />
              ) : data && data?.length > 0 ? (
                <>
                  {data?.map((c, i) => (
                    <Card key={i} size="small" className="no-shadow">
                      <Space
                        className="w-100"
                        direction="vertical"
                        size="large"
                      >
                        <Row>
                          <Col span={14} className="center-table-column">
                            <Flex align="center" justify="start" gap={6}>
                              <div>
                                {!c.answer && c.direction == "incoming" && (
                                  <div className="call-icon-wrapper">
                                    <VscCallIncoming className="call-icon call-error" />
                                  </div>
                                )}
                                {c.answer && c.direction == "incoming" && (
                                  <div className="call-icon-wrapper">
                                    <VscCallIncoming className="call-icon call-success" />
                                  </div>
                                )}

                                {!c.answer && c.direction == "outgoing" && (
                                  <div className="call-icon-wrapper">
                                    <VscCallOutgoing className="call-icon call-error" />
                                  </div>
                                )}
                                {c.answer && c.direction == "outgoing" && (
                                  <div className="call-icon-wrapper">
                                    <VscCallOutgoing className="call-icon call-success" />
                                  </div>
                                )}
                              </div>
                              <Space
                                size={0}
                                direction="vertical"
                                className="w-100"
                              >
                                <Text
                                  onClick={() => handleCallClick(c.fromId)}
                                  type="link"
                                  className="campaign-title"
                                >
                                  {c.fromId ? c.fromName : "Unknown"}
                                </Text>
                                <Text
                                  type="link"
                                  className="campaign-recipients"
                                >
                                  {new Date(c.createdAt * 1000).toLocaleString(
                                    "en-us",
                                    DateFormatWithHour
                                  )}{" "}
                                  •{" "}
                                  {c.direction == "incoming"
                                    ? "Incoming Call"
                                    : "Outgoing call"}
                                </Text>
                                <Text
                                  type="link"
                                  className="campaign-recipients"
                                >
                                  Mobile: {c.fromPhone}
                                </Text>
                              </Space>
                            </Flex>
                          </Col>

                          <Col span={6} className="center-table-column">
                            <Text strong>{c.toPhoneNumber}</Text>
                          </Col>
                          <Col span={4}>
                            <Flex
                              className="w-100 h-100"
                              align="center"
                              justify="end"
                              gap={6}
                            >
                              <Tooltip title={`Call ${c.fromPhone}`}>
                                <Button
                                  type="text"
                                  size="large"
                                  onClick={() => onCallClicked(c.fromPhone)}
                                  icon={
                                    <MdCall
                                      className="icon"
                                      style={{ color: "#1cc88a" }}
                                    />
                                  }
                                ></Button>
                              </Tooltip>

                              <Dropdown
                                trigger={["click"]}
                                menu={{
                                  items: [
                                    {
                                      label: (
                                        <p className="drop-down-text">Delete</p>
                                      ),
                                      key: "3",
                                      danger: true,
                                      onClick: () => {
                                        deleteCall(c.id);
                                      },
                                      icon: (
                                        <BsTrashFill className="drop-down-icon" />
                                      ),
                                    },
                                  ],
                                }}
                                type="link"
                              >
                                <Button
                                  className="flex-button"
                                  type="text"
                                  size="large"
                                  icon={
                                    <IoEllipsisHorizontal
                                      style={{ fontSize: 26 }}
                                    />
                                  }
                                ></Button>
                              </Dropdown>
                            </Flex>
                          </Col>
                        </Row>

                        {c.recording && (
                          <Space
                            direction="vertical"
                            size={0}
                            className="w-100"
                          >
                            <Text strong>Call Recording</Text>

                            <div>
                              <AudioPlayer
                                src={`data:audio/mp3;base64,${c.recording}`}
                              />
                            </div>
                          </Space>
                        )}
                      </Space>
                    </Card>
                  ))}
                  <SimplePagination
                    page={page}
                    pageSize={perPage}
                    total={total}
                    setPage={setPage}
                  />
                </>
              ) : (
                <Card size="small">
                  <NoDataFound
                    title="No calls found on your database"
                    description="You have yet to make any calls using tapzap"
                    addText="Check you audience"
                    onAdd={() => navigate("/contacts")}
                  />
                </Card>
              )}
            </Space>
          </Space>
        </Card>
      ) : (
        <Space className="w-100" size={24} direction="vertical">
          <Space direction="vertical" className="w-100">
            <SimplePagination
              page={page}
              pageSize={perPage}
              total={total}
              setPage={setPage}
            />

            {loading ? (
              <TableLoading />
            ) : data && data?.length > 0 ? (
              <>
                {data?.map((c, i) => (
                  <Card key={i} size="small" className="no-shadow gray-border-hoverable">
                    <Space className="w-100" direction="vertical" size="large">
                      <Row>
                        <Col span={14} className="center-table-column">
                          <Flex align="center" justify="start" gap={6}>
                            <div>
                              {!c.answer && c.direction == "incoming" && (
                                <div className="call-icon-wrapper">
                                  <VscCallIncoming className="call-icon call-error" />
                                </div>
                              )}
                              {c.answer && c.direction == "incoming" && (
                                <div className="call-icon-wrapper">
                                  <VscCallIncoming className="call-icon call-success" />
                                </div>
                              )}

                              {!c.answer && c.direction == "outgoing" && (
                                <div className="call-icon-wrapper">
                                  <VscCallOutgoing className="call-icon call-error" />
                                </div>
                              )}
                              {c.answer && c.direction == "outgoing" && (
                                <div className="call-icon-wrapper">
                                  <VscCallOutgoing className="call-icon call-success" />
                                </div>
                              )}
                            </div>
                            <Space
                              size={0}
                              direction="vertical"
                              className="w-100"
                            >
                              <Text
                                onClick={() => handleCallClick(c.fromId)}
                                type="link"
                                className="campaign-title"
                              >
                                {c.fromId ? c.fromName : "Unknown"}
                              </Text>
                              <Text type="link" className="campaign-recipients">
                                {new Date(c.createdAt * 1000).toLocaleString(
                                  "en-us",
                                  DateFormatWithHour
                                )}{" "}
                                •{" "}
                                {c.direction == "incoming"
                                  ? "Incoming Call"
                                  : "Outgoing call"}
                              </Text>
                              <Text type="link" className="campaign-recipients">
                                Mobile: {c.fromPhone}
                              </Text>
                            </Space>
                          </Flex>
                        </Col>

                        <Col span={6} className="center-table-column">
                          <Text strong>{c.toPhoneNumber}</Text>
                        </Col>
                        <Col span={4}>
                          <Flex
                            className="w-100 h-100"
                            align="center"
                            justify="end"
                            gap={6}
                          >
                            <Dropdown
                              trigger={["click"]}
                              menu={{
                                items: [
                                  {
                                    label: (
                                      <p className="drop-down-text">Delete</p>
                                    ),
                                    key: "3",
                                    danger: true,
                                    onClick: () => {
                                      deleteCall(c.id);
                                    },
                                    icon: (
                                      <BsTrashFill className="drop-down-icon" />
                                    ),
                                  },
                                ],
                              }}
                              type="link"
                            >
                              <Button
                                className="flex-button"
                                type="text"
                                size="large"
                                icon={
                                  <IoEllipsisHorizontal
                                    style={{ fontSize: 26 }}
                                  />
                                }
                              ></Button>
                            </Dropdown>
                          </Flex>
                        </Col>
                      </Row>

                      {c.recording && (
                        <Space direction="vertical" size={0} className="w-100">
                          <Text strong>Call Recording</Text>

                          <div>
                            <AudioPlayer
                              src={`data:audio/mp3;base64,${c.recording}`}
                            />
                          </div>
                        </Space>
                      )}
                    </Space>
                  </Card>
                ))}
                <SimplePagination
                  page={page}
                  pageSize={perPage}
                  total={total}
                  setPage={setPage}
                />
              </>
            ) : (
              <Card size="small">
                <NoDataFound
                  title="No calls found on your database"
                  description="You have yet to make any calls using tapzap"
                  addText="Check you audience"
                  onAdd={() => navigate("/contacts")}
                />
              </Card>
            )}
          </Space>
        </Space>
      )}
    </>
  );
};

export default RecentCalls;
