import { EmailSenderContextProvider } from "./EmailSenderContext";
import SendersList from "./SendersList";

const EmaiLSenderLayout = () => {
  return (
    <>
      <div className="border-top"></div>
      <EmailSenderContextProvider>
        <SendersList />
      </EmailSenderContextProvider>
    </>
  );
};
export default EmaiLSenderLayout;
