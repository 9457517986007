import { Flex, Tag } from "antd";
import { useContext } from "react";
import { TaskContext } from "./TaskContext";

const TaskStats = () => {
  const { stats } = useContext(TaskContext);

  return (
    <Flex align="center" justify="start">
      <Tag size="small" className="large-font-text">
        {stats?.total ?? 0} Total
      </Tag>
      <Tag size="small" className="large-font-text">
        {stats?.overdue ?? 0} Overdue
      </Tag>
      <Tag size="small" className="large-font-text">
        {stats?.toDo ?? 0} To Do
      </Tag>
      <Tag size="small" className="large-font-text">
        {stats?.email ?? 0} Email
      </Tag>
      <Tag size="small" className="large-font-text">
        {stats?.call ?? 0} Call
      </Tag>
      <Tag size="small" className="large-font-text">
        {stats?.meeting ?? 0} Meeting
      </Tag>
      <Tag size="small" className="large-font-text">
        {stats?.lunch ?? 0} Lunch
      </Tag>
      <Tag size="small" className="large-font-text">
        {stats?.deadline ?? 0} Deadline
      </Tag>
    </Flex>
  );
};

export default TaskStats;
