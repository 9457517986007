import {
  Button,
  Card,
  Flex,
  List,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import AddCompanyDrawer from "./AddCompanyDrawer";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { AiFillSave } from "react-icons/ai";
import DealCompanyOptions from "./DealCompanyOptions";
import DeleteConfirmationModal from "../../../../../components/DeleteConfirmationModal";

const DealCompanies = () => {
  const { companies, setCompanies } = useDealsDetailsCtx();
  const [showDrawer, setShowDrawer] = useState(false);
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();
  const { id } = useParams();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [current, setCurrent] = useState();

  const onDelete = async (companyId, deleteConfirmed) => {
    if (!deleteConfirmed) {
      return;
    }

    const response = await axios.post(`/Deal/DeleteDealCompany`, {
      dealId: id,
      companyId: companyId,
    });

    if (response.data.success) {
      setCompanies((prev) => prev.filter((contact) => contact.id != companyId));
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onDeleteConfirm={onDelete}
        onClose={() => setDeleteOpen(false)}
        item={current}
      />
    <Space className="w-100" direction="vertical">

      <Flex align="center" justify="space-between">
        <Title style={{ margin: 5 }} level={4}>
          Associated companies
        </Title>
        <Button
        size="large"
          className="large-font new-button"
          onClick={() => setShowDrawer(true)}
        >
          Edit
        </Button>
      </Flex>
      <Card
        className="no-head-space zero-margin-padding"
        style={{ boxShadow: "none" }}
      >
        {companies?.length == 0 ? (
          <div style={{ padding: 15 }}>
            <Text strong className="text-light">
              No company found
            </Text>
          </div>
        ) : (
          <List
            style={{ borderRadius: 20, border: "none" }}
            dataSource={companies}
            renderItem={(item, index) => (
              <List.Item>
                <Flex
                  align="center"
                  justify="space-between"
                  style={{ width: "100%", padding: "0px 15px" }}
                >
                  <Space direction="vertical" size={0}>
                    <Text strong>{item.name}</Text>

                    <Flex align="center" justify="start" gap={6} wrap="wrap">
                      {item.phone && <Text>{item.phone}</Text>}
                      {item.phone && item.industry && <Text>|</Text>}
                      {item.industry && <Text>{item.industry}</Text>}
                      {item.industry && item.webSite && <Text>|</Text>}

                      {item.webSite && (
                        <Text
                          type="link"
                          onClick={() => window.open(item.webSite, "_blank")}
                        >
                          {item.webSite}
                        </Text>
                      )}
                    </Flex>
                  </Space>

                  <DealCompanyOptions
                    id={item.id}
                    onConfirm={(e) => {
                      setCurrent(e);
                      setDeleteOpen(true);
                    }}
                  />
                </Flex>
              </List.Item>
            )}
          ></List>
        )}

        <AddCompanyDrawer
          open={showDrawer}
          setOpen={setShowDrawer}
          icon={<AiFillSave />}
          className="flex-button"
        />
      </Card>
      </Space>
    </>
  );
};

export default DealCompanies;
