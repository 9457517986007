import { Flex } from "antd";
import Balance from "./Balance";
import Notifications from "./Notifications";
import UserProfile from "./UserProfile";
import LanguageSelector from "./LanguageSelector";

const UserProfileMenu = () => {
  return (
    <>
      <Flex align="center" justify="end" gap={12}>
        <Balance />

        <LanguageSelector />

        <Notifications />

        <UserProfile />
      </Flex>
    </>
  );
};

export default UserProfileMenu;
