import { Button, Card, Col, Flex, List, Row, Space, Typography } from "antd";
import { useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { removeButtonsFromHtml } from "../../../shared/HtmlOps";
const Audience = ({ details, handlePrint }) => {

  const { Title, Text, Link } = Typography;
  return (
    <>
      <Title level={3}>Data</Title>
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <List 
            bordered
            dataSource={[
              {
                name: "Audience",
                value: details.audience,
              },
              {
                name: "Subject",
                value: details.subject,
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <Flex
                  style={{ width: "100%" }}
                  align="center"
                  justify="space-between"
                >
                  <Text  className="bold">{item.name}</Text>

                  <div className="zero-margin-padding-children" dangerouslySetInnerHTML={{__html: removeButtonsFromHtml(item.value)}}></div>
                </Flex>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <List 
            bordered
            dataSource={[
              {
                name: "Delivered",
                value: new Date(details.delivered).toDateString(),
              },
              {
                name: "Subject",
                option: true,
                html: true
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                {item.option && <EmailOptions handlePrint={handlePrint} url={details?.template?.templatePreviewPath}/>}
                {!item.option && (
                  <Flex
                    style={{ width: "100%" }}
                    align="center"
                    justify="space-between"
                  >
                    
                    <Text className="bold">{item.name}</Text>

                    <Text>{item.value}</Text>
                  </Flex>
                )}
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

const EmailOptions = ({handlePrint, url}) => {
  const [downloading, setDownloading] = useState(false)

  const handleDownload = async () => {
    setDownloading(true)
    const response = await fetch(`${process.env.REACT_APP_FILE_URL}${url}`);
    const blob = await response.blob();
    const link = document.createElement('a');
    const blobUrl = window.URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = 'downloaded-image.jpg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
    setDownloading(false)
  }

  return (
    <Space>
      <Button size="small" onClick={() => handleDownload()}>Download</Button>•
      <Button size="small" onClick={() => handlePrint()}>Print</Button>
    </Space>
  );
};

export default Audience;
