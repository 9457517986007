import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, Flex, Form, Input, Typography, Modal, Tooltip } from "antd";
import { MdCancel } from "react-icons/md";
import { FaArrowLeft, FaCheck, FaSignature, FaUserAlt } from "react-icons/fa";
import UseAiDrawer from "../../campaigns/CreateUpdate/UseAIDrawer";
import AddSignatureDrawer from "../Create/AddSignatureDrawer";
import AddPersonalisationDrawer from "../Create/AddPersonalisationDrawer";
import { Editor } from "@tinymce/tinymce-react";

const EditSimpleEmail = () => {
  const { id } = useParams();
  const editorRef = useRef();

  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState('');

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();


  const handleSave = async (e) => {
    setLoading(true);

    var formData = new FormData();

    formData.append("Id", id);
    formData.append("Html", "");
    formData.append("Name", e.name);
    formData.append("Style", "");
    formData.append("IsSimple", true);
    formData.append("SimpleHtml", editorState);

    var response = await axiosPrivate.put("/Template", formData);

    if (!response || !response.data.success) {
      return;
    }

    setLoading(false);
    navigate(-1);
  };

  useEffect(() => {
    const fetch = async () => {

    const response = await axiosPrivate.get(`/Template/${id}`);

    setEditorState(response.data.html);

    form.setFieldsValue({
      name: response.data.name,
      description: response.data.description,
    });
  }

  fetch();
  }, []);

  const [editOpen, setEditOpen] = useState(false);

  const [aiOpen, setAiOpen] = useState(false);

  const saveAi = (e) => {
    setEditorState(e);
    setAiOpen(false);
  };

  const [signatureOpen, setSignatureOpen] = useState(false);

  const onSave = (e) => {
   
    const editor = editorRef.current;
    if (editor) {
      const span = editor.dom.select('div#signature')[0];
      if (span) {
        editor.dom.remove(span);
      }

      const newContent = editor.getContent({ format: 'html' }) + e;
      editor.setContent(newContent);

      editor.fire('change');
    }

    setSignatureOpen(false);
  };

  const [addPersonalisationOpen, setAddPersonalisationOpen] = useState(false);
  
  const onAddPersonalisation = (e) =>{
    if (editorRef.current) {
      const editor = editorRef.current;
      editor.insertContent(e);
    }

    setAddPersonalisationOpen(false);
  }
  
  return (
    <>
    
      <Form onFinish={handleSave} form={form}>
        <UpdateModal
          open={editOpen}
          setOpen={setEditOpen}
          form={form}
          loading={loading}
        />
      </Form>

      <AddPersonalisationDrawer  onSave={onAddPersonalisation} open={addPersonalisationOpen} setOpen={setAddPersonalisationOpen}/>

<AddSignatureDrawer
  onSave={onSave}
  open={signatureOpen}
  setOpen={setSignatureOpen}
/>
<UseAiDrawer
  text={editorState}
  open={aiOpen}
  setOpen={setAiOpen}
  onSave={saveAi}
/>

<div
  style={{
    height: "60px",
    width: "100%",
    background: "#fff",
  }}
>
  <Flex
    align="center"
    justify="space-between"
    style={{
      height: "60px",
      borderTop: `1px solid #cbd5e0`,
      margin: "0px 10px",
    }}
  >
    <Flex align="center" justify="start" gap={6}>
      <Tooltip title="Go back">
        <Button
          className="flex-button"
          size="large"
          icon={<FaArrowLeft />}
          onClick={() => navigate(-1)}
        ></Button>
      </Tooltip>
      <Tooltip title="Add signature">
        <Button
          size="large"
          className="flex-button"
          onClick={() => setSignatureOpen(true)}
          icon={<FaSignature className="icon" />}
        ></Button>
      </Tooltip>
    </Flex>
    <Flex align="center" justify="end" gap={6}>
      <Tooltip title="Insert personalisation">
        <Button
          size="large"
          className="flex-button"
          onClick={() => setAddPersonalisationOpen(true)}
          icon={<FaUserAlt />}
        ></Button>
      </Tooltip>

      <Tooltip title="Use Artificial Intelligence">
        <Button
          size="large"
          className="bold"
          onClick={() => setAiOpen(true)}
        >
          Use AI
        </Button>
      </Tooltip>
      <Button
        size="large"
        type="primary"
        className="bold"
        onClick={() => setEditOpen(true)}
      >
        Save changes
      </Button>
    </Flex>
  </Flex>
</div>
<Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        className="subject-text-box"
        apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
        onEditorChange={setEditorState}
        value={editorState}
        init={{
          plugins: "",
          toolbar: true,
          menubar: true,
          statusbar: true,
          height: "100%",
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help'
        }}
      />
    </>
  );
};

const UpdateModal = ({ open, setOpen, form, loading }) => {
  const { Text, Title } = Typography;
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Save template changes
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button
            className="new-buttons large-font"
          onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="new-buttons large-font"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Text>Name</Text>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "A name is required" },
          {
            max: 50,
            message: "Name should contain at most 20 characters",
          },
        ]}
      >
        <Input
          count={{
            show: true,
            max: 50,
          }}
          size="large"
          placeholder="Enter a name for your design"
          allowClear
        />
      </Form.Item>
    </Modal>
  );
};

export default EditSimpleEmail;
