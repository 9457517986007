import { Button, Flex, Space, Typography } from "antd";
import { useContext, useState } from "react";
import CreateSenderModal from "./CreateSenderModal";
import SenderTable from "./SenderTable";
import { EmailSenderContext } from "./EmailSenderContext";

const SendersList = () => {
  const perPage = 7;

  const { Text, Title } = Typography;
  const [total, setTotal] = useState();
  const [add, setAdd] = useState(false);
  const [data, setData] = useState();
  const handleDataAdded = (addedData) => {
    setTotal(total + 1);
    if (data.length < perPage) {
      setData([...data, addedData]);
    }
  };

  const { stats } =
    useContext(EmailSenderContext);

  return (
    <>
      <CreateSenderModal
        isOpen={add}
        setIsOpen={setAdd}
        dataAdded={handleDataAdded}
      />

      <Space className="w-100 margin-top-12" direction="vertical" size={24}>
        <Flex align="center" justify="space-between" wrap="wrap">
          <Space direction="vertical" size={0}>
            <Title level={4} style={{ margin: 0 }}>
              Email Senders ({stats?.total ?? 0})
            </Title>
            <Text>All the senders who will send your emails</Text>
          </Space>
          <Button
            onClick={() => setAdd(true)}
            type="primary"
            size="large"
             className="new-buttons large-font"
          >
            Create sender
          </Button>
        </Flex>
        <SenderTable
        />
      </Space>
    </>
  );
};

export default SendersList;
