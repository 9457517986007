import React, { createContext, useEffect, useState } from "react";
import { CardStatus } from "../shared/enums";
import dayjs from "dayjs";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

export const DealsDetailsContext = createContext({
  dealStatus: {
    status: CardStatus.New,
    estRevenue: 0,
    inThisStage: new Date(),
  },
  dealDetails: {
    name: "",
    amount: 0,
    closeDate: moment(),
    lastUpdated: moment(),
    createdOn: moment(),
  },
  contacts: [
    {
      id: 0,
      name: "",
      email: "",
      phoneNumber: 0,
    },
  ],
  companies: [
    {
      name: "",
      phone:"",
      webSite:"",
      indsutry:"",
      id: 0,
    },
  ],
  activites: [
    {
      ind: 0,
      id: 0,
      name:"",
      notes: '',
      owners: [],
      reminder:false,
      dueDate: "",
      dueTime:"",
      createdAt: "",
      type:"",
      done: '',
      completedDate:"",
      overDue: false,
      highPriority: false
    },
  ],
  files: [{
    name:'',
    id:"",
    ind:0,
    creatorName:"",
    creatorProfile:'',
    createdAt:''
  }],
  products: [
    {
      name:"",
      id:0
    }
  ],
  hisotry:[{

  }],
  currentActivityFilter:1,
  setCurrentActivityFilter:() => {},
  setContacts: () => {},
  setActivities: () => {},
  setDealStatus: () => {},
  setCompanies: () => {},
  setDealDetails: () => {},
  setFiles: () => {},
  setHistory: () => {}
});

export const DealsDetailsContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const {id} = useParams();

  const axios = useAxiosPrivate();
  
  const [currentActivityFilter, setCurrentActivityFilter] = useState(() => 1)
 
  const [dealStatus, setDealStatus] = useState({
    status: CardStatus.New,
    estRevenue: 0,
    inThisStage: new Date(),
  });

  const [dealDetails, setDealDetails] = useState({
    name: "",
    amount: 0,
    closeDate: dayjs(),
    lastUpdated: dayjs(),
    createdOn: dayjs(),
  });

  const [contacts, setContacts] = useState([
    {
      id: 1,
      name: "Astrit Bislimi",
      email: "test@test.com",
      phoneNumber: 122121212112,
    },
    {
      id: 2,
      name: "Astrit Bislimi22",
      email: "test@test.com",
      phoneNumber: 122121212112,
    },
  ]);

  const [companies, setCompanies] = useState([
    {
      name: "121212112",
      id: 12,
    },
  ]);

  const [products, setProducts] = useState([]);
  const [activites,setActivities] = useState([]);

  const [files, setFiles] = useState([])

  const [history, setHistory] = useState([]);

  useEffect(() =>{

    const fetch = async () => {

      const historyResponse = await axios.get(`/DealActivity/${id}/${currentActivityFilter}`);
      setHistory(historyResponse.data.items);
    }
    fetch();
  },[id, currentActivityFilter] )

  useEffect(() => {

    const fetch = async () => {

      const detailsResponse = await axios.get(`/Deal/id/${id}`)
      
      if(!detailsResponse.data.success){
        navigate('/deal/notfound')
        return;
      }
      
      setDealDetails({
      name: detailsResponse.data.title,
      amount: detailsResponse.data.amount,
      note: detailsResponse.data.note,
      closeDate: dayjs(detailsResponse.data.closeDate ?? new Date()),
      lastUpdated: dayjs(detailsResponse.data.lastUpdate ?? new Date()),
      createdOn:dayjs(detailsResponse.data.createdOn  * 1000 ?? new Date()),
      createdDate: new Date(detailsResponse.data.createdOn * 1000)
    })
    
    setDealStatus({
      status: detailsResponse.data.stage,
      estRevenue: 0,
      inThisStage: new Date(detailsResponse.data.inStageSince),
    })
    
    const emailsResponse = await axios.get(`/Deal/DealContacts/${id}`);
    
    setContacts(emailsResponse.data?.items?.map(d => ({
      id: d.id,
      name: `${d.name} ${(d.lastname) ? d.lastname: '' }`,
      email: d.email,
      phoneNumber: d.phone,
    })))
    
    const compainesResponse= await axios.get(`/Deal/GetDealCompanies/${id}`);
    setCompanies(compainesResponse.data?.items?.map(d => ({
      name: d.name,
      id: d.id,
      phone: d.phoneNumber,
      webSite: d.website,
      industry:d.industry
    })))
    
    const activitesResponse = await axios.get(`/DealTasks/${id}`)
    setActivities(activitesResponse.data.items?.map((d, ind) => ({
      ind:ind,
      id: d.id,
      name:d.name,
      notes:d.notes,
      owners:d.owners,
      reminder:d.reminder,
      dueDate:moment(d.dueDate),
      dueTime:moment(d.dueTime),
      createdAt: d.createdAt,
      type:d.taskType,
      done: d.done,
      completedDate: d.completedDate,
      overDue: d.overDue,
      highPriority: d.highPriority,
      dueToday: d.dueToday,
      isParent: d.isParent,
      isFollowUp: d.isFollowUp
    })))
    
    const filesResponse = await axios.get(`/DealFile/${id}`);
    
    setFiles(filesResponse.data.files?.map((f, ind) => ({
      name:f.name,
      id: f.id,
      ind: ind+ 1,
      creatorName: f.createdByName,
      creatorProfile: f.createdByProfile,
      createdAt: f.createdAt
    })));
    
    const products = await axios.get(`/Deal/Products/${id}`, {});
    
    setProducts(products.data.products?.map(c => ({
      name: c.name,
      id: c.id,
      createdAt: c.createdAt,
      creatorName: c.creatorName,
      creatorProfilePicture: c.creatorProfilePicture
    })))
  }

  fetch();
  }, [id])
  
  return (
    <DealsDetailsContext.Provider
    value={{
      dealStatus,
      setDealStatus,
      dealDetails,
      setDealDetails,
      contacts,
        setContacts,
        companies,
        setCompanies,
        activites,
        currentActivityFilter,
        setActivities,
        files,
        setFiles,
        products,
        setProducts,
        history,
        setHistory,
        setCurrentActivityFilter
      }}
    >
      {children}
    </DealsDetailsContext.Provider>
  );
};
