import {
  Card,
  Typography,
  Button,
  Space,
  Flex,
  Row,
  Col,
  Pagination,
} from "antd";
import CreateMedia from "./CreateMedia";
import { useState, useContext } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { DateFormat } from "../../dateformat";
import MediaItemDropDown from "./MediaItemDropDown";
import { FiPlus } from "react-icons/fi";
import NoDataFound from "../../shared/NoDataFound";
import { MediaContext } from "./MediaContext";
import TableLoading from "../../shared/TableLoading";

const MediaList = () => {
  const { Title, Text } = Typography;

  const {
    page,
    setPage,
    stats,
    setStats,
    documents,
    setDocuments,
    loading,
  } = useContext(MediaContext);

  const [isAddOpen, setIsAddOpen] = useState(false);

  const perPage = 7;

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
    setItemToDelete(item);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setItemToDelete(null);
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }

    if (item && item.length > 0) {
      await removeRangeDocuments();
      return;
    }

    await removeDocument(item);
  };

  const removeDocument = async (id) => {
    const response = await axiosPrivate.delete(`/Document/${id}`);
    if (response && response.data.data) {
      setDocuments(documents.filter((image) => image.id != id));
      setStats((prev) => ({
        ...prev,
        total: documents.filter((f) => f.id != id).length,
      }));
    }
  };

  const removeRangeDocuments = async () => {
    var response = await axiosPrivate.post("/Document/DeleteRange", {
      ids: selectedDocuments,
    });
    if (response && response.data.success) {
      setDocuments(documents.filter((f) => !selectedDocuments.includes(f.id)));
      setSelectedDocuments([]);
    }
  };

  const downloadDocument = async (id) => {
    const response = await axiosPrivate.get(`/Document/Download/${id}`);

    var blob = new Blob([response.data.data], { type: response.data.mimeType });
    var link = document.createElement("a");
    link.href =
      "data:" + response.data.mimeType + ";base64," + response.data.data; // window.URL.createObjectURL(blob);
    link.download = response.data.fileName;
    link.click();
  };

  const onAdd = (val) => {
    setDocuments([val, ...documents]);

    setStats((prev) => ({ ...prev, total: prev.total + 1 }));
  };

  return (
    <>
      <CreateMedia setIsOpen={setIsAddOpen} isOpen={isAddOpen} onAdd={onAdd} />
      {modalIsOpen && (
        <DeleteConfirmationModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          onDeleteConfirm={handleDeleteConfirm}
          item={itemToDelete}
        />
      )}

      <Space className="w-100" size={24} direction="vertical">
      
            <Flex
              justify="space-between"
              align="center"
              wrap="wrap"
            >
              <Space direction="vertical" size={0} >

              <Title style={{ margin: 0, padding: 0 }} level={4}>
                Media ({stats?.total})
              </Title>
              <Text>View, create & remove media items, which you latter use on your desings.</Text>
              </Space>
              <Flex
                gap={6}
                justify="end"
                align="center"
                wrap="wrap"
              >
                <Button
                  type="primary"
                  size="large"
                  className="new-buttons large-font"
                  onClick={(e) => setIsAddOpen(true)}
                >
                  Create media
                </Button>
              </Flex>
            </Flex>

        <Space direction="vertical" className="w-100">
          <Card size="small" className="no-shadow">
            <Row>
              <Col span={22}>
                <Text className="column-header">Name</Text>
              </Col>
              <Col span={2}>
                <Flex align="center" justify="end"></Flex>
              </Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !documents || documents.length == 0 ? (
            <Card size="small" className="no-shadow">
              <NoDataFound
                onAdd={() => setIsAddOpen(true)}
                title="You have not created any media documents"
                description=" Click the New media button on the documents page to create a
                      new document"
                addText="New document"
              />
            </Card>
          ) : (
            <>
              {documents.map((c, i) => (
                <Card size="small" key={i} className="no-shadow">
                  <Row>
                    <Col span={22} className="center-table-column">
                      <Space size={0} direction="vertical" className="w-100">
                        <Text className="campaign-title">{c.name}</Text>
                        <Text>
                          {new Date(c.createdAt * 1000).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}

                          {c.lastUpdate && (
                            <Text>
                              •{" "}
                              {new Date(c.lastUpdate * 1000).toLocaleDateString(
                                "en-us",
                                DateFormat
                              )}
                            </Text>
                          )}
                        </Text>
                      </Space>
                    </Col>
                    <Col span={2}>
                      <MediaItemDropDown
                        id={c.id}
                        name={c.path}
                        onDelete={(id) => handleOpenModal(id)}
                        onDownload={(id) => downloadDocument(id)}
                      />
                    </Col>
                  </Row>
                </Card>
              ))}

                <Flex align="center" justify="end">
                  <Pagination
                    current={page}
                    onChange={(p) => setPage(p)}
                    pageSize={perPage}
                    total={stats?.total}
                  />
                </Flex>

            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default MediaList;
