import {
  Avatar,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Typography
} from "antd";
import { useContext, useEffect, useState } from "react";
import { DateFormat } from "../../dateformat";
import CompanyOptions from "./CompanyOptions";
import TableLoading from "../../shared/TableLoading";
import AddCompanyModal from "../deals/Modals/AddCompanyModal";
import UpdateComapny from "./UpdateCompany";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../shared/NoDataFound";
import { CompanyListContext } from "./CompanyListContext";
import CompanyStats from "./CompanyStats";
import CompanyToDateFilter from "./Filters/CompanyToDateFilter";
import SimplePagination from "../../shared/SimplePagination";

const CompanyList = ({dataToAdd}) => {
  const { Text } = Typography;

  const {
    page,
    setPage,
    setStats,
    stats,
    companies,
    setCompanies,
    loading,
    setPageSize,
    pageSize
  } = useContext(CompanyListContext);

  const [showAdd, setShowAdd] = useState(false);
  const navigate = useNavigate();

  const [showEdit, setShowEdit] = useState(false);
  const [current, setCurrent] = useState();

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, all: prev.all - 1 }));

    setCompanies((prev) => prev.filter((c) => c.id != id));
  };

  const onAdd = (e) => {
    setStats((prev) => ({ ...prev, all: prev?.all + 1 }));

    setCompanies((prev) => [e, ...prev]);
  };

  const onUpdate = (e) => {
    setCompanies((prev) =>
      prev.map((c) =>
        c.id == e.id
          ? {
              ...c,
              name: e.name,
              phone: e.phone,
              owners: e.owners,
              industry: e.industry,
              website: e.website,
            }
          : c
      )
    );
  };

  useEffect(() => {
    if(dataToAdd){
      onAdd(dataToAdd)
    }
  },[dataToAdd])

  return (
    <>
      <Space direction="vertical" size={24} className="w-100">
        <CompanyStats />

        <Flex align="center" justify="space-between">
          <CompanyToDateFilter />
          <SimplePagination
            page={page}
            pageSize={pageSize}
            total={stats?.all}
            setPage={setPage}
          />
        </Flex>

        <Space direction="vertical" className="w-100">
          {loading ? (
            <TableLoading />
          ) : !companies || companies.length == 0 ? (
            <Card size="small">
              <NoDataFound
                addText="New Company"
                title="You have not created any companies yet"
                description="Click the new company button on Dealing/Companies to create a new company"
                onAdd={() => setShowAdd(true)}
              />
            </Card>
          ) : (
            <>
              {companies.map((c, i) => (
                <Card
                  key={i}
                  size="small"
                  className="gray-border-hoverable no-shadow"
                >
                  <Row>
                    <Col span={20} className="center-table-column">
                      <Space direction="vertical">
                        <Text className="product-title">{c.name}</Text>

                        {c.deals && (
                          <div>
                            {c.deals?.map((d, ind) => (
                              <Text
                                key={ind}
                                type="link"
                                className="deal-tag"
                                onClick={() =>
                                  navigate(`/Deals/Details/${c.id}`)
                                }
                              >
                                {d.name}
                              </Text>
                            ))}

                            {c.noDeals > 2 && (
                              <Text className="deal-tag">
                                +{c.noDeals - 2} more..
                              </Text>
                            )}
                          </div>
                        )}

                        {c.industry && <Text>Industry {c.industry}</Text>}
                        {c.phone && <Text>Phone {c.phone}</Text>}
                        <Text>
                          {`Created at ${new Date(
                            c.createdAt
                          ).toLocaleDateString("en-US", DateFormat)}`}
                        </Text>
                        <Avatar.Group size={"small"}>
                          {c.owners?.map((d, ind) => (
                            <Avatar
                              size={"small"}
                              key={d.ind}
                              src={`${process.env.REACT_APP_FILE_URL}${d.profileUrl}`}
                            >
                              {d?.firstname[0]}
                              {d?.lastname[0]}
                            </Avatar>
                          ))}
                        </Avatar.Group>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Flex align="center" justify="end" className="w-100 h-100">
                        <CompanyOptions
                          onDelete={onDelete}
                          id={c.id}
                          setCurrent={setCurrent}
                          setShowEditing={setShowEdit}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

              <SimplePagination
                page={page}
                pageSize={pageSize}
                setPerPage={setPageSize}
                showChanger
                total={stats?.all}
                setPage={setPage}
              />
            </>
          )}
        </Space>
      </Space>

      <AddCompanyModal open={showAdd} setOpen={setShowAdd} onAdd={onAdd} />

      {showEdit && (
        <UpdateComapny
          show={showEdit}
          setShow={setShowEdit}
          id={current}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default CompanyList;
