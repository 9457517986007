import { Space, Typography } from "antd";
import "./Call.css";

const UserAvatar = ({ isCaller, phone, pulse, name }) => {
    const { Title, Text } = Typography;

    const getInitials = (userName) => {
      if(!userName){
        return "--"
      }
      const namesArray = userName?.trim()?.split(" ");
      if (namesArray?.length === 1) {
        return namesArray[0][0]?.toUpperCase();
      } else {
        return (
          namesArray[0][0]?.toUpperCase() +
          (namesArray[1] ? namesArray[1][0]?.toUpperCase() : "")
        );
      }
    };

    return (
      <Space align="center" size={0} className="w-100" direction="vertical">
        <div className={`call-user-avatar ${isCaller ? "caller" : "called"} ${pulse ? 'pulse' : ''}`}>
          <Title className="zero-margin-padding title">{isCaller ? "U" : getInitials(name)}</Title>
        </div>
        <Space align="center" size={0} direction="vertical">
          <Title className="zero-margin-padding">
            {isCaller ? "You" : name ?? "Unknown"}
          </Title>
          <Text strong className="text">
            Mobile: {phone}
          </Text>
        </Space>
      </Space>
    );
  };

  export default UserAvatar