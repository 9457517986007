import { useContext } from "react";
import { NotificationTemplatesContext } from "./NotificationTemplatesContext";
import { Flex, Tag } from "antd";

const NotificationTemplatesStats = () => {
  const { stats } = useContext(NotificationTemplatesContext);

  return (
    <Flex align="center" justify="start">
      <Tag bordered={false} size="small" className="large-font-text">
        {stats?.total ?? 0} Total
      </Tag>
      <Tag bordered={false} size="small" className="large-font-text">
        Saved {stats?.saved ?? 0}
      </Tag>
    </Flex>
  );
};

export default NotificationTemplatesStats;
