import { Flex, Tag } from "antd";
import { useContext } from "react";
import { CompanyListContext } from "./CompanyListContext";

const CompanyStats = () => {
  const { stats } = useContext(CompanyListContext);

  return (
    <>
      <Flex align="center" justify="start">
        <Tag size="small" className="large-font-text">
          {stats?.all ?? 0} Total
        </Tag>
      </Flex>
    </>
  );
};

export default CompanyStats;
