import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import {
  StepExecutionConditionBridge,
  StepExecutionType,
} from "../../../shared/enums";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import QueryString from "qs";

const UpdateFollowupStep = ({ open, setOpen, onUpdated, item }) => {
  const [senders, setSenders] = useState();

  const bridgeOptions = [
    {
      label: "And",
      value: StepExecutionConditionBridge.And,
    },
    {
      label: "Or",
      value: StepExecutionConditionBridge.Or,
    },
    {
      label: "Not",
      value: StepExecutionConditionBridge.Not,
    },
  ];

  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  useEffect(() => {
    const fetch = async () => {

    form.setFieldsValue(item);

    let response = await axios.get(`/Sender/GetSenders`,{
      params:{
        page: 1,
        pageSize: 9999,
        orderBy:"name",
        sortDirection:1,
        from: null,
        to: null
      },
      paramsSerializer: params => {
        return QueryString.stringify(params, {arrayFormat: "repeat", skipNulls: true})
      }
  });

  setSenders(response.data.items.map(c => ({
      value: c.id,
      label: c.nickName
  })))
  }

  fetch();
  }, []);
  const onSubmit = async (e) => {
    setLoading(true);
    let response = await axios.put("Followup/Step", {
      id: item.id,
      name: e.name,
      bridge: e.bridge,
      description: e.description,
      executionCondition: e.executionCondition,
      executeAfterDays: e.executeAfterDays,
      senderId: e.senderId
    });

    if (response.data.success) {
      onUpdated({
        id: item.id,
        ...e,
      });
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <Modal
      title={
        <Title style={{ margin: 0 }} level={4}>
          Update followup step
        </Title>
      }
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={onSubmit}>
        <Text>Name</Text>
        <Form.Item rules={[{ required: true }]} name="name">
          <Input size="large" placeholder="Followup step name" />
        </Form.Item>

        <Text>Description</Text>
        <Form.Item name="description">
          <TextArea
            rows={3}
            size="large"
            placeholder="Followup step description"
          />
        </Form.Item>

        <Row gutter={[24, 24]}>
          <Col span={6}>
            <Text>Days</Text>
            <Form.Item name="executeAfterDays">
              <Input size="large" placeholder="Follwup step days" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Text>Bridge</Text>
            <Form.Item name="bridge">
              <Select
                options={bridgeOptions}
                size="large"
                style={{ width: "100px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Text>Condition</Text>
            <Form.Item name="executionCondition">
              <Select
                className="w-100"
                size="large"
                options={[
                  {
                    label: "User signed up",
                    value: StepExecutionType.UserSignedUp,
                  },
                  {
                    label: "User subscribed",
                    value: StepExecutionType.UserSubscribed,
                  },
                  {
                    label: "User un-subscribed",
                    value: StepExecutionType.UserUnsubscribed,
                  },
                  {
                    label: "User removed account",
                    value: StepExecutionType.UserDeleted,
                  },
                  {
                    label: "Trial exceded",
                    value: StepExecutionType.TrialExceded,
                  },
                ]}
                placeholder="Follwup step condition"
              />
            </Form.Item>
          </Col>
        </Row>

        <Text>Sender</Text>
        <Form.Item
          name="senderId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={senders} size="large" placeholder="Enter email sender" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateFollowupStep;
