import { Card, Col, Flex, message, Row, Space, Tag, Typography } from "antd";
import TaskStats from "./TaskStats";
import { useContext, useEffect, useState } from "react";
import { TaskContext } from "./TaskContext";
import TaskToDateFilter from "./FIlters/TaskToDateFilter";
import TableLoading from "../../shared/TableLoading";
import { DateFormat } from "../../dateformat";
import TaskOptions from "./TaskOptions";
import AvatarGroup from "../deals/Kanban/AvatarGroup";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { TaskStatus } from "../../shared/enums";
import CreateFollowUpTaskModal from "../deals/CreateDealDrawer/CreateFollowUpTaskModal";
import AddTaskDrawer from "../deals/AddTaskDrawer";
import SimplePagination from "../../shared/SimplePagination";
import getTaskTypeText from "../../shared/TaskTypeText";
import NoDataFound from "../../shared/NoDataFound";

const TaskList = ({dataToAdd}) => {
  const axios = useAxiosPrivate();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [followUpOpen, setFollowUpOpen] = useState(false);

  const navigate = useNavigate();
  const { Text } = Typography;

  const [addTaskOpen, setAddTaskOpen] = useState(false);

  const { pageSize, setPageSize, tasks, page, setPage, stats, setStats, setTasks, loading } =
    useContext(TaskContext);
  const onMarkTaskAsDone = async (id) => {
    const response = await axios.post("/DealTasks/MarkTaskAsDone", {
      taskId: id,
    });

    if (response.data.success) {
      setTasks((prev) =>
        prev.map((c) => (c.id == id ? { ...c, done: true } : c))
      );
    } else {
      message.error(response.data.message);
    }
  };

  const onCreateFollowUp = (id) => {
    setCurrentItem(id);
    setFollowUpOpen(true);
  };

  const onReopenTask = async (id) => {
    const response = await axios.put(`/DealTasks/reopen/${id}`, {});

    if (response.data.success) {
      setTasks((prev) =>
        prev.map((c) => (c.id == id ? { ...c, done: false } : c))
      );
    } else {
      message.error(response.data.message);
    }
  };

  const handleDelete = async (item, deleted) => {
    if (!deleted) {
      return;
    }

    const response = await axios.delete(`/DealTasks/${item}`);

    if (response.data.success) {
      setTasks((prev) => prev.filter((c) => c.id != item));

      var task = tasks.find((c) => c.id == item);

      if (task.taskType == TaskStatus.ToDo) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          toDo: prev.toDo - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Email) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          email: prev.email - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Call) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          call: prev.call - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Meeting) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          meeting: prev.meeting - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else if (task.taskType == TaskStatus.Lunch) {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          lunch: prev.lunch - 1,
          overdue:
            new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      } else {
        setStats((prev) => ({
          ...prev,
          total: prev.total - 1,
          deadline: prev.deadline - 1,
          overdue:
            task.dueDate && new Date(task.dueDate) < new Date()
              ? prev.overdue - 1
              : prev.overdue,
        }));
      }
    } else {
      message.error(response.data.message);
    }
  };

  const onFollowupCreated = (task) => {
    setTasks((prev) => [{ ...task.data, isFollowUp: true }, ...prev]);

    if (task.data.taskType == TaskStatus.ToDo) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        toDo: prev.toDo + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Email) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        email: prev.email + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Call) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        call: prev.call + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Meeting) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        meeting: prev.meeting + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else if (task.data.taskType == TaskStatus.Lunch) {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        lunch: prev.lunch + 1,
        overdue:
          new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    } else {
      setStats((prev) => ({
        ...prev,
        total: prev.total + 1,
        deadline: prev.deadline + 1,
        overdue:
          task.data.dueDate && new Date(task.data.dueDate) < new Date()
            ? prev.overdue + 1
            : prev.overdue,
      }));
    }
  };

  const [addTaskLoading, setAddTaskLoading] = useState(false);

  const onSubmit = async (e) => {
    setAddTaskLoading(true);

    const response = await axios.post(`/DealTasks/CreateTask`, {
      taskType: e.type,
      name: e.name,
      dueDate: e.dueDate,
      reminder: e.reminder,
      highPriority: e.taskPriority,
      notes: e.notes,
      ownerIds: e.assignedUser,
      dealId: e.dealId,
      dueTime: e.dueTime,
      reminderType: e.reminderType,
    });

    if (response.data.success) {
      setTasks((prev) => [response.data, ...prev]);
      setAddTaskLoading(false);
    } else {
      message.error(response.data.message);
    }

    setAddTaskLoading(false);
    setAddTaskOpen(false);
  };

  useEffect(() => {
    if(dataToAdd){
      onSubmit(dataToAdd)
    }
  },[dataToAdd])
  return (
    <>
      <AddTaskDrawer
        open={addTaskOpen}
        setOpen={setAddTaskOpen}
        selectDeals={true}
        onSubmit={onSubmit}
        loading={addTaskLoading}
      />
      <CreateFollowUpTaskModal
        open={followUpOpen}
        setOpen={setFollowUpOpen}
        id={currentItem}
        onCreated={onFollowupCreated}
      />
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={currentItem}
      />

      <Space direction="vertical" size={24} className="w-100">
        <TaskStats />
        <Flex align="center" justify="space-between">
          <TaskToDateFilter />

          <SimplePagination
            page={page}
            pageSize={pageSize}
            total={stats?.total}
            setPage={setPage}
          />
        </Flex>
        {loading ? (
          <TableLoading />
        ) : !tasks || tasks.length == 0 ? (
          <NoDataFound />
        ) : (
          <>
            <Space className="w-100" direction="vertical">
              {tasks.map((c, i) => (
                <Card
                  size="small"
                  className={`no-shadow gray-border-hoverable ${
                    c.done ? "light-success" : ""
                  } ${c.overDue ? "light-error" : ""}`}
                  key={i}
                >
                  <Row>
                    <Col span={20}>
                      <Space direction="vertical">
                        <Flex align="center" justify="start" gap={6}>
                          <Text type="link" className="campaign-title">
                            {c.isFollowUp ? "Follow up: " : ""}
                            {c.name}
                          </Text>
                          {c.overDue && (
                            <Tag
                              color="red"
                              style={{ fontWeight: 600, borderWidth: "2px" }}
                            >
                              Overdue
                            </Tag>
                          )}
                          {c.done && (
                            <Tag
                              color="green"
                              style={{ fontWeight: 600, borderWidth: "2px" }}
                            >
                              Done
                            </Tag>
                          )}
                        </Flex>
                        <Text>
                          {`${getTaskTypeText(c.taskType)} • `}
                          {c.highPriority
                            ? "Priority: High"
                            : "Priority: Normal"}{" "}
                          {c.dueDate &&
                            ` • Due date: ${new Date(
                              c.dueDate
                            ).toLocaleDateString("en-US", DateFormat)}`}
                        </Text>
                        <Flex align="center" justify="start" gap={6}>
                          <AvatarGroup
                            size="small"
                            items={c.owners?.map((c) => ({
                              profileUrl: c.profilePath,
                              name: c.name,
                              lastName: c.lastname,
                            }))}
                          />
                          {c.deal && (
                            <Text
                              type="link"
                              onClick={() =>
                                navigate(`/Deals/Details/${c.deal.id}`)
                              }
                            >
                              Deal: {c.deal?.name}
                            </Text>
                          )}
                        </Flex>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Flex
                        align="center"
                        justify="end"
                        className="h-100 w-100"
                      >
                        <TaskOptions
                          onMarkTaskAsDone={onMarkTaskAsDone}
                          taskDone={c.done}
                          isFollowUp={c.isFollowUp}
                          id={c.id}
                          onDelete={(e) => {
                            setDeleteOpen(true);
                            setCurrentItem(e);
                          }}
                          onReopenTask={onReopenTask}
                          onCreateFollowUp={onCreateFollowUp}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
              <SimplePagination
                page={page}
                pageSize={pageSize}
                showChanger
                setPerPage={setPageSize}
                total={stats?.total}
                setPage={setPage}
              />
            </Space>
          </>
        )}
      </Space>
    </>
  );
};

export default TaskList;
