import { SmsSenderListContextProvider } from "./SmsSenderListContext";
import SmsSenderList from "./SmsSenderList";

const SmsSenderListLayout = () => {
  return (
    <>
      <div className="border-top"></div>
      <SmsSenderListContextProvider>
        <SmsSenderList />
      </SmsSenderListContextProvider>
    </>
  );
};

export default SmsSenderListLayout;
