import {
  Button,
  Card,
  Col,
  Flex,
  Input,
  InputNumber,
  message,
  Modal,
  Result,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
  FaAmbulance,
  FaArrowLeft,
  FaFax,
  FaGlobeAmericas,
  FaPhoneAlt,
  FaSms,
} from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { UNSAFE_useScrollRestoration, useNavigate } from "react-router-dom";
import TableLoading from "../../../../../shared/TableLoading";
import NoDataFound from "../../../../../shared/NoDataFound";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import QueryString from "qs";
import "./BuyPhoneNumber.css";
import { MdFilterAlt, MdFilterAltOff, MdHd, MdMms } from "react-icons/md";

const features = [
  {
    name: "Voice",
    icon: <FaPhoneAlt />,
    value: "voice",
  },
  {
    name: "Fax",
    icon: <FaFax />,
    value: "fax",
  },
  {
    name: "HD voice",
    icon: <MdHd />,
    value: "hd_voice",
  },
  {
    name: "Emergency",
    icon: <FaAmbulance />,
    value: "emergency",
  },
  {
    name: "Sms",
    icon: <FaSms />,
    value: "sms",
  },
  {
    name: "International sms",
    icon: <FaGlobeAmericas />,
    value: "international_sms",
  },
  {
    name: "MMS",
    icon: <MdMms />,
    value: "mms",
  },
];

const types = [
  {
    value: "local",
    label: "Local",
  },
  {
    value: "toll_free",
    label: "Toll Free",
  },
  {
    value: "mobile",
    label: "Mobile",
  },
  {
    value: "national",
    label: "National",
  },
  {
    value: "shared_cost",
    label: "Shared cost",
  },
];

const phoneNumberOptions = [
  {
    value: "contains",
    label: "Contains",
  },
  {
    value: "starts_with",
    label: "Starts with",
  },
  {
    value: "ends_with",
    label: "Ends with",
  }
]

const BuyPhoneNumber = () => {
  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const [showAdvanced, setShowAdvanced] = useState(false);

  const [numbers, setNumbers] = useState();
  const [total, setTotal] = useState();

  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();

  const [countries, setCountries] = useState()

  const [selectedCountries, setSelectedCountries] = useState();
  const [selectedFeatures, setSelectedFeatures] = useState()
  const [selectedType, setSelectedType] = useState()
  const [limit, setLimit] = useState(150);
  const [selectedPhoneNumberOption, setSelectedPhoneNumberOptions] = useState();
  const [phrase, setPhrase] = useState()

  const [bestEffort, setBestEffort] = useState(false);
  const [quickShip, setQuickShip] = useState(false);
  const [resNumbers, setResNumbers] = useState(false);
  const [exHeldNumbers, setExHeldNumbers] = useState(false);

  useEffect(() => {
    setLoading(true);

    const contoller = new AbortController();
    const signal = contoller.signal;
    const fetch = async () => {
      const response = await axios.get("/phone-numbers/buy", {
        signal: signal,
        paramsSerializer: (params) => {
          return QueryString.stringify(params, {
            arrayFormat: "repeat",
            skipNulls: true,
          });
        },
      });


      const countryResponse = await axios.get(`/Address/country-codes`, {
        signal: signal
      })

      setCountries(countryResponse.data.items.map(c => ({
        value: c.code,
        label: c.name
      })))

      setNumbers(response.data.data);
      setLoading(false);
    };

    fetch();

    return () => contoller.abort();
  }, []);

  const search = async () => {
    setLoading(true);

    const response = await axios.get("/phone-numbers/buy", {
      params: {
        country: selectedCountries,
        features: selectedFeatures,
        type: selectedType,
        limit: limit
      },
      paramsSerializer: (params) => {
        return QueryString.stringify(params, {
          arrayFormat: "repeat",
          skipNulls: true,
        });
      },
    });

    setNumbers(response.data.data);
    setLoading(false);
  };

  const [buyOpen, setBuyOpen] = useState();
  const [current, setCurrent] = useState();

  const handleBuyClick = async (
    number,
    features,
    type,
    setUpPrice,
    monthlyPrice,
    currency
  ) => {
    setCurrent({
      number,
      features,
      type,
      setUpPrice,
      monthlyPrice,
      currency,
    });

    setBuyOpen(true);
  };

  const [showFilters, setShowFilters] = useState(false);

  
  return (
    <>
      {current && (
        <ConfirmBuyModal open={buyOpen} setOpen={setBuyOpen} number={current} />
      )}
      <Space size={24} direction="vertical" className="w-100">
        <Card size="small">
          <Flex align="center" justify="space-between" gap={6} wrap="wrap">
            <Flex align="center" justify="start" gap={6} wrap="wrap">
              <Button
                icon={<FaArrowLeft />}
                type="text"
                onClick={() => navigate(-1)}
                className="flex-button"
              ></Button>
              <Title level={4} style={{ margin: 0 }}>
                Buy numbers
              </Title>
            </Flex>

            <Button
              size="large"
              className="flex-button"
              shape="circle"
              onClick={() => setShowFilters(!showFilters)}
            >
              {!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
            </Button>
          </Flex>

          {showFilters && (
            <div style={{ margin: 24 }}>
              <Space size={24} className="w-100" direction="vertical">
                <Row gutter={[24, 24]}>
                  <Col span={6}>
                    <Space size={0} direction="vertical" className="w-100">
                      <Text>
                        <Text className="bold">Country</Text> (Required)
                      </Text>

                      <Select
                        className="w-100"
                        size="large"
                        options={countries}
                        onChange={(e) => setSelectedCountries(e)}
                        showSearch
                        filterOption={(input, option) =>
                          option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder="Select phone number country"
                      />
                    </Space>
                  </Col>
                  <Col span={6}>
                    <Space size={0} direction="vertical" className="w-100">
                      <Text className="bold">Features</Text>

                      <Select
                        className="w-100"
                        maxTagCount="responsive"
                        size="large"
                        placeholder="Any feature"
                        mode="multiple"
                        onChange={e => setSelectedFeatures(e)}
                        options={features.map((c) => ({
                          label: (
                            <Flex
                              className="w-100 h-100"
                              align="center"
                              justify="start"
                              gap={6}
                            >
                              {c.icon} <Text>{c.name}</Text>
                            </Flex>
                          ),
                          value: c.value,
                        }))}
                      />
                    </Space>
                  </Col>

                  <Col span={4}>
                    <Space size={0} direction="vertical" className="w-100">
                      <Text className="bold">Type</Text>

                      <Select
                        className="w-100"
                        size="large"
                        onChange={e => setSelectedType(e)}
                        placeholder="All types"
                        options={types}
                      />
                    </Space>
                  </Col>
                </Row>

                <Button
                  type="text"
                  className="flex-button bold"
                  onClick={() => setShowAdvanced((prev) => !prev)}
                >
                  Advanced Search <IoMdArrowDropdown className="icon" />
                </Button>

                {showAdvanced && (
                  <Space direction="vertical" className="w-100" size={24}>
                    <Row gutter={[24, 24]}>
                      <Col span={6}>
                        <Space size={0} direction="vertical" className="w-100">
                          <Text className="bold">Phone number</Text>

                          <Select
                            className="w-100"
                            size="large"
                            options={phoneNumberOptions}
                            placeholder="All types"
                            onChange={(e) => setSelectedPhoneNumberOptions(e)}
                            value={selectedPhoneNumberOption}
                          />
                        </Space>
                      </Col>
                      <Col span={6}>
                        <Space size={0} direction="vertical" className="w-100">
                          <Text className="bold">Phrase or Numbers </Text>

                          <Input
                            className="w-100"
                            size="large"
                            placeholder="Phrase or Numbers"
                            value={phrase}
                            onChange={(e) => setPhrase(e.target.value)}
                          />
                        </Space>
                      </Col>

                      <Col span={6}>
                        <Space size={0} direction="vertical" className="w-100">
                          <Text className="bold">Result Limit</Text>

                          <InputNumber
                            className="w-100"
                            size="large"
                            value={limit}
                            min={10}
                            onChange={(e) => setLimit(e)}
                            placeholder="50"
                          />
                        </Space>
                      </Col>
                    </Row>

                    <Flex align="center" justify="start" gap={24} wrap>
                      <Flex align="center" justify="start" gap={6}>
                        <Switch checked={bestEffort} onChange={(e) => setBestEffort(e)}/>
                        <Text>Best Effort</Text>
                      </Flex>
                      <Flex align="center" justify="start" gap={6}>
                        <Switch checked={quickShip} onChange={(e) => setQuickShip(e)}/>
                        <Text>Quickship</Text>
                      </Flex>
                      <Flex align="center" justify="start" gap={6}>
                        <Switch checked={resNumbers} onChange={(e) => setResNumbers(e)}/>
                        <Text>Reservable Numbers</Text>
                      </Flex>
                    </Flex>
                    <Flex align="center" justify="start" gap={24} wrap>

                    <Flex align="center" justify="start" gap={6}>
                        <Switch checked={exHeldNumbers} onChange={(e) => setExHeldNumbers(e)}/>
                        <Text>Exclude Held Numbers</Text>
                      </Flex>
                      </Flex>
                  </Space>
                )}

                <Flex align="center" justify="end">
                  <Button
                    size="large"
                    type="primary"
                    onClick={search}
                    loading={loading}
                  >
                    Search numbers
                  </Button>
                </Flex>
              </Space>
            </div>
          )}
        </Card>
        <Space className="w-100" direction="vertical">
          <Card size="small">
            <Row gutter={[6, 6]}>
              <Col span={7}>
                <Text className="column-header">Number</Text>
              </Col>
              <Col span={3}>
                <Text className="column-header">Type</Text>
              </Col>
              <Col span={8}>
                <Text className="column-header">Features</Text>
              </Col>
              <Col span={2}>
                <Text className="column-header">Upfront</Text>
              </Col>
              <Col span={2}>
                <Text className="column-header">Monthly</Text>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Card>

          {loading ? (
            <TableLoading />
          ) : !numbers || numbers?.length == 0 ? (
            <Card size="small">
              <NoDataFound
                addText="Reset filters"
                title="No phone numbers found"
                description="Current filters resulted in no phone numbers being found, change the filters and try again."
              />
            </Card>
          ) : (
            <>
              {numbers?.map((c, i) => (
                <Card size="small">
                  <Row gutter={[6, 6]}>
                    <Col span={7} className="center-table-column">
                      <Space size={0} direction="vertical" className="w-100">
                        <Text className="BuyPhoneNumber-list-title">
                          {c.phone_number}
                        </Text>
                        <Text className="BuyPhoneNumber-list-created-at">
                          {c.region_information.map((d, s) => (
                            <Text
                              key={s}
                              className="BuyPhoneNumber-list-created-at"
                            >
                              {d.region_name}
                              {s == c.region_information.length - 1 ? "" : ", "}
                            </Text>
                          ))}
                        </Text>
                      </Space>
                    </Col>
                    <Col span={3} className="center-table-column">
                      <Text className="BuyPhoneNumber-type">
                        {c.phone_number_type}
                      </Text>
                    </Col>
                    <Col span={8} className="center-table-column">
                      <Flex align="center" justify="start" gap={6}>
                        {c.features.map((f, d) => (
                          <Text>
                            {f.name == "voice" ? (
                              <div className="feature-wrapper">
                                <Tooltip title="Voice">
                                  <FaPhoneAlt className="icon BuyPhoneNumber-icon" />
                                </Tooltip>
                              </div>
                            ) : f.name == "fax" ? (
                              <div className="feature-wrapper">
                                <Tooltip title="Fax">
                                  <FaFax className="icon BuyPhoneNumber-icon" />
                                </Tooltip>
                              </div>
                            ) : f.name == "hd_voice" ? (
                              <div className="feature-wrapper">
                                <Tooltip title="Hd voice">
                                  <MdHd className="icon BuyPhoneNumber-icon" />
                                </Tooltip>
                              </div>
                            ) : f.name == "emergency" ? (
                              <div className="feature-wrapper">
                                <Tooltip title="Emergency">
                                  <FaAmbulance className="icon BuyPhoneNumber-icon" />
                                </Tooltip>
                              </div>
                            ) : f.name == "sms" ? (
                              <div className="feature-wrapper">
                                <Tooltip title="Sms">
                                  <FaSms className="icon BuyPhoneNumber-icon" />
                                </Tooltip>
                              </div>
                            ) : f.name == "international_sms" ? (
                              <div className="feature-wrapper">
                                <Tooltip title="International SMS">
                                  <FaGlobeAmericas className="icon BuyPhoneNumber-icon" />
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="feature-wrapper">
                                <Tooltip title={f.name}>
                                  <MdMms className="icon BuyPhoneNumber-icon" />
                                </Tooltip>
                              </div>
                            )}{" "}
                          </Text>
                        ))}
                      </Flex>
                    </Col>
                    <Col span={2} className="center-table-column">
                      <Text className="BuyPhoneNumber-price BuyPhoneNumber-text">
                        {`${Math.round(c.cost_information.upfront_cost, 2)} ${
                          c.cost_information.currency
                        } `}
                      </Text>
                    </Col>

                    <Col span={2} className="center-table-column">
                      <Text className="BuyPhoneNumber-price BuyPhoneNumber-text ">
                        {` ${Math.round(c.cost_information.monthly_cost, 2)} ${
                          c.cost_information.currency
                        }`}
                      </Text>
                    </Col>
                    <Col span={2}>
                      <Flex
                        align="center"
                        justify="end"
                        className="w-100 h-100"
                      >
                        <Button
                          onClick={() =>
                            handleBuyClick(
                              c.phone_number,
                              c.features,
                              c.phone_number_type,
                              c.cost_information.upfront_cost,
                              c.cost_information.monthly_cost,
                              c.cost_information.currency
                            )
                          }
                          type="primary"
                        >
                          Buy Number
                        </Button>
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

const ConfirmBuyModal = ({ open, setOpen, number }) => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const buy = async () => {
    setLoading(true);

    const response = await axios.post(`/phone-numbers/buy-number`, {
      number: number.number,
      type: number.type,
      features: number.features?.map((c) => c.name).join(","),
    });

    if (response.data.success) {
      setSuccess(true);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  const handleCancel = () => {
    if (success) {
      navigate(`/Senders/sms`);
    } else {
      setOpen(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <>
          {loading || success ? (
            false
          ) : (
            <Flex align="center" justify="center" gap={6}>
              <Button size="large" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                size="large"
                type="primary"
                onClick={buy}
                loading={loading}
              >
                Buy number
              </Button>
            </Flex>
          )}
        </>
      }
    >
      {success ? (
        <Result
          status="success"
          title="Successfully Purchased Number"
          subTitle={`Phone number ${
            number.number
          } was successfully purchased for ${Math.round(
            parseFloat(number.monthlyPrice) + parseFloat(number.setUpPrice),
            2
          )} ${number.currency}`}
          extra={[
            <Button size="large" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              type="primary"
              size="large"
              onClick={() => navigate(`/dial/number`)}
            >
              Call someone
            </Button>,
          ]}
        />
      ) : (
        <>
          <Space size={0} direction="vertical" className="w-100">
            <div className="w-100 text-center">
              <Text>Buy this {number.type} phone number</Text>
            </div>
            <Title
              style={{ marginTop: 0, marginBottom: 0 }}
              className="zero-marign-padding text-center w-100"
            >
              {number.number}
            </Title>
          </Space>
          {loading ? (
            <Flex style={{ height: 80 }} align="center" justify="center">
              {" "}
              <Spin size="large" />
            </Flex>
          ) : (
            <Row gutter={[12, 12]} style={{ marginTop: 24, marginBottom: 24 }}>
              <Col span={8}>
                <Card size="small" className="no-shadow price-boxes">
                  <Space
                    direction="vertical"
                    align="center"
                    className="w-100"
                    size={0}
                  >
                    <Text>Monthly price</Text>
                    <Title className="zero-margin-padding" level={4}>
                      {Math.round(number.monthlyPrice, 2)} {number.currency}
                    </Title>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" className="no-shadow price-boxes">
                  <Space
                    direction="vertical"
                    align="center"
                    className="w-100"
                    size={0}
                  >
                    <Text>Setup price</Text>
                    <Title className="zero-margin-padding" level={4}>
                      {Math.round(number.setUpPrice, 2)} {number.currency}
                    </Title>
                  </Space>
                </Card>
              </Col>
              <Col span={8}>
                <Card size="small" className="no-shadow price-boxes">
                  <Space
                    direction="vertical"
                    align="center"
                    className="w-100"
                    size={0}
                  >
                    <Text>Total price</Text>
                    <Title className="zero-margin-padding" level={4}>
                      {Math.round(
                        parseFloat(number.monthlyPrice) +
                          parseFloat(number.setUpPrice),
                        2
                      )}{" "}
                      {number.currency}
                    </Title>
                  </Space>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </Modal>
  );
};
export default BuyPhoneNumber;
