import React, { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Flex, Row, Space, Tabs, Typography } from "antd";
import EmailStats from "./EmailStats";
import Optimizer from "./Optimizer";
import { useReactToPrint } from "react-to-print";
import Responses from "./Responses";
import SecondPage from "./SecondPage";
import { DateFormat } from "../../../dateformat";
import { FaArrowLeft } from "react-icons/fa";
import { MdOutlineZoomOutMap } from "react-icons/md";
import "./Details.css";
import TableLoading from "../../../shared/TableLoading";
import PreviewTemplateModal from "../../newemailtemplates/PreviewTemplateModal";
import { EmailTemplateTypes } from "../../../shared/enums";

const CampaignDetails = () => {
  const componentRef = useRef();
  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const { Title, Text } = Typography;

  const { id } = useParams();
  const axios = useAxiosPrivate();
  const [campaign, setCampaign] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await axios.get(
        `/Campaign/GetFullCampaignDetails?Id=${id}`
      );
      setCampaign(response.data.data);
      setLoading(false);
    };

    fetch();
  }, []);

  const handlePrint = () => {
    print();
  };

  const items = [
    {
      key: "2",
      label: "Campaign Data",
      children: <SecondPage campaign={campaign} handlePrint={handlePrint} />,
    },
    {
      key: "3",
      label: "Optimizer & Suggestions",
      disabled: campaign?.type == 2,
      children: <Optimizer id={campaign?.template?.id} />,
    },
    {
      key: "4",
      label: "Audience Responses",
      children: <Responses id={campaign?.id} />,
    },
  ];

  const navigate = useNavigate();

  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <>
      <PreviewTemplateModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        id={campaign?.template?.id}
        type={EmailTemplateTypes.UserCreated}
      />
      <Card>
        <Space className="w-100" direction="vertical" size={24}>
          <Flex align="center" justify="space-between">
            <Flex align="center" justify="start" gap={6}>
              <Button
                onClick={() => navigate(-1)}
                type="text"
                icon={<FaArrowLeft />}
                className="flex-button"
              ></Button>

              <Title className="zero-margin-padding" level={3}>
                {campaign?.name}
              </Title>
            </Flex>
            <Space>
              <div className="email-campaign-details-audience">
                {`Created at ${new Date(campaign?.createdAt).toLocaleDateString(
                  "en-US",
                  DateFormat
                )}`}
              </div>

              <div className="email-campaign-details-audience">
                {campaign?.totalContacts ?? 0} Recipients
              </div>

              <div className="email-campaign-details-audience">
                {campaign?.authorName}
              </div>
            </Space>
          </Flex>
          <Flex align="center" justify="center">
            <Flex
              className="email-campaign-details-wrapper"
              align="center"
              justify="center"
            >
              <Space direction="vertical" size={24} className="w-100">
                {loading && (
                  <div className="w-100">
                    {" "}
                    <TableLoading />
                  </div>
                )}

                {campaign && (
                  <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
                      <Space
                        ref={componentRef}
                        style={{ width: "100%" }}
                        direction="vertical"
                        size={24}
                      >
                        {campaign?.type == 0 && <EmailStats id={campaign.id} />}

                        <Tabs size="large" items={items} />
                      </Space>
                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                      <div style={{ position: "sticky", top: 85 }}>
                        {campaign.type == 0 && campaign.template ? (
                          <>
                            <Card
                              className="no-shadow template-card"
                              size="small"
                            >
                              <div className="preivew-wrapper">
                                <div className="preview-overlay">
                                  <Flex
                                    className="margin-10"
                                    align="center"
                                    justify="end"
                                  >
                                    <Button
                                      size="large"
                                      onClick={() => setPreviewOpen(true)}
                                      icon={
                                        <MdOutlineZoomOutMap className="icon" />
                                      }
                                      className="flex-button"
                                    ></Button>
                                  </Flex>
                                </div>
                                {campaign.template.isSimple ? (
                                  <Flex
                                    wrap="wrap"
                                    align="center"
                                    justify="center"
                                    className="simple-wrapper"
                                  >
                                    <Title
                                      level={3}
                                      className="center-text zero-margin-padding"
                                    >
                                      No preview available
                                    </Title>
                                  </Flex>
                                ) : (
                                  <img
                                    className="img-preview"
                                    src={`${process.env.REACT_APP_FILE_URL}${campaign.template.templatePreviewPath}`}
                                  />
                                )}
                              </div>
                              <Text className="title">
                                <span className="tag">#1 </span>
                                {campaign.template.name}
                              </Text>
                            </Card>
                          </>
                        ) : (
                          <Card style={{ height: "400px" }}>
                            <Flex
                              align="center"
                              justify="center"
                              style={{ height: "300px" }}
                              direction="vertical"
                            >
                              <Space
                                direction="vertical"
                                size={0}
                                style={{ textAlign: "center" }}
                              >
                                <Title level={4} style={{ margin: 0 }}>
                                  Missing design
                                </Title>
                                <Text className="text-light">
                                  The design might have been moved or deleted
                                </Text>
                              </Space>
                            </Flex>
                          </Card>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Space>
            </Flex>
          </Flex>
        </Space>
      </Card>
    </>
  );
};

export default CampaignDetails;
