import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useContext, useMemo } from "react";
import KanbanCard from "./KanbanCard";
import { useDroppable } from "@dnd-kit/core";
import { Typography, Divider, Flex } from "antd";
import { DealListContext } from "../DealListContext";
import "./KanbanColumn.css"

const KanbanColumn = ({
  column,
  cards,
  setKanbanCards,
  cardDropdownOptions,
  cardFromDropdownObj,
}) => {
  const { Title, Text } = Typography;
  const { filters } = useContext(DealListContext);

  const cardIds = useMemo(() => cards.map((cards) => cards.id), [cards]);
  const { setNodeRef } = useDroppable({
    id: column.id,
    data: {
      type: "column",
      column,
    },
  });

  return (
    <div
      ref={setNodeRef}
      className="kanbanColumn kanban-column-border"
    >
      <div>
        <div
          className="kanban-column-wrapper"
        >
          <Flex vertical align="center" justify="center">
            <Title style={{ marginBottom: 0 }} level={4}>
              {column.title}
            </Title>
            <Text>
              Est. Revenue:{" "}
              {cards?.reduce((acc, curr) => {
                return acc + curr.amount;
              }, 0)}{" "}
              $
            </Text>
          </Flex>
          <Divider style={{ margin: 0 }} />
        </div>
        {useMemo(() => {
          return (
            <div className="flex-1 flex flex-col sortableContainer">
              <SortableContext
                items={cardIds}
                strategy={verticalListSortingStrategy}
                id="cards"
              >
                {cards.map((card) => (
                  <KanbanCard
                    card={card}
                    key={card.id}
                    cardFromDropdownObj={cardFromDropdownObj}
                    cardDropdownOptions={cardDropdownOptions}
                    setKanbanCards={setKanbanCards}
                  />
                ))}
              </SortableContext>
            </div>
          );
        }, [cards, filters.cardItems])}
      </div>
    </div>
  );
};

export default KanbanColumn;
