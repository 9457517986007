import { Button, Card, Col, Flex, Row, Space, Typography } from "antd";
import "./DealIntegration.css";
import { FaCode, FaTasks } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const DealIntegration = () => {
  const { Title, Text } = Typography;

  const handleDocumentationClick = () => {
    window.location.href = "https://documentation.tapzap.ai";
  };

  const navigate = useNavigate();

  const handleGoToDealsClick = () => {
    navigate(`/Deals/Management`);
  };

  return (
    <Card size="small">
      <Flex align="center" justify="space-between">
        <Title level={4} className="zero-margin-padding">
          Deals integrations
        </Title>

        <Button
          onClick={handleGoToDealsClick}
          size="large"
          className="bold"
          type="link"
        >
          Go to deals
        </Button>
      </Flex>

      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card
            onClick={handleDocumentationClick}
            className="no-shadow deal-integration-card"
            size="small"
          >
            <Space
              className="w-100"
              size={0}
              direction="vertical"
              align="center"
            >
              <FaCode className="deal-integraion-home-icon" />
              <Text className="deal-integration-card-title">
                API Implementation
              </Text>

              <div className="text-center">
                <Text className="text-center">
                  Implement tapzap deals on your own application, create, update
                  and remove deals through your application.
                </Text>
              </div>
            </Space>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            onClick={handleDocumentationClick}
            size="small"
            className="no-shadow deal-integration-card"
          >
            <Space
              className="w-100"
              size={0}
              direction="vertical"
              align="center"
            >
              <FaTasks className="deal-integraion-home-icon" />
              <Text className="deal-integration-card-title">Default tasks</Text>
              <div className="text-center">
                <Text className="text-center">
                  Get a new task and assign it to preselected members, each time
                  a new deal is added through tapzaps api
                </Text>
              </div>
            </Space>
          </Card>
        </Col>
      </Row>

      <Flex align="center" justify="center" className="w-100">
        <Button
          onClick={handleDocumentationClick}
          className="bold"
          type="link"
          size="large"
        >
          View documentation
        </Button>
      </Flex>
    </Card>
  );
};

export default DealIntegration;
