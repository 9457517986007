import { useContext } from "react";
import { Flex, Tag } from "antd";
import { AudienceContext } from "./AudienceContext";

const ContactStats = () => {
  const { stats } = useContext(AudienceContext);

  return (
    <>
      <Flex align="center" justify="start" style={{marginTop:12}}>
        <Tag bordered={false} size="small" className="large-font-text">
          {stats?.total ?? 0} Total
        </Tag>

        <Tag bordered={false} color="green" size="small" className="large-font-text">
          {stats?.safe ?? 0} Safe
        </Tag>

        <Tag bordered={false}  color="gray" size="small" className="large-font-text">
          {stats?.unknown ?? 0} Unknown
        </Tag>

        <Tag bordered={false} color="orange" size="small" className="large-font-text">
          {stats?.invalid ?? 0} Invalid
        </Tag>

        <Tag bordered={false} size="small" color="red" className="large-font-text">
          {stats?.risky ?? 0} Risky 
        </Tag>
      </Flex>
    </>
  );
};

export default ContactStats;
