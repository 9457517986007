import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  Input,
  InputNumber,
  Modal,
  Popover,
  Result,
  Row,
  Select,
  Space,
  Typography,
  message,
  Grid
} from "antd";
import { useContext, useEffect, useState } from "react";
import { BsInfoLg } from "react-icons/bs";
import { FaArrowRight, FaClock, FaDollarSign } from "react-icons/fa";
import { MdEmail, MdOutlineArrowDropDown, MdSms } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DateFormat } from "../../../dateformat";
import { FiPlus } from "react-icons/fi";
import { FaCartShopping } from "react-icons/fa6";
import { loadStripe } from "@stripe/stripe-js";
import { BalanceContext } from "../../../context/BalanceContext";

const { useBreakpoint } = Grid;

const Balance = () => {
  const { balances, setBalances } = useContext(BalanceContext);
  const screens = useBreakpoint();

  const { Text, Title } = Typography;

  const items = [
    {
      key: 4,
      label: (
        <div
          className="blue-gradient w-100"
          style={{ marginLeft: "-12px", marginRight: "-12px", marginTop: "-6px", borderRadius: 20 }}
        >
          <Flex
            align="center"
            justify="center"
            className="w-100"
            vertical
            // style={{ height: 150 }}
          >
            <Title
              style={{
                color: "white",
                margin: 0,
                padding: 0,
              }}
              level={3}
            >
              Swaps & Coupons
            </Title>
            <div className="w-100" style={{ textAlign: "center" }}>
              <Text style={{ color: "white", textAlign: "center" }}>
                Take advantage of credits swaps and coupons.
                <br /> Swap your credit or increase your credit using
                coupons.
              </Text>
            </div>

            <Row
              className="w-100"
              align="center"
              gutter={10}
              style={{ marginTop: 15, marginBottom: 10 }}
            >
              <Col xs={12}>
                <Button
                  block
                  type="text"
                  style={{ color: "white" }}
                  size="large"
                >
                  Swap credits
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  size="large"
                  block
                  className="flex-button"
                  icon={<FaArrowRight />}
                  onClick={() => setCouponOpen(true)}
                >
                  Apply coupon
                </Button>
              </Col>
            </Row>
          </Flex>
        </div>
      ),
    },
    {
      key: "1",
      label: (<Card size="small" style={{ marginLeft: "-12px", marginRight: "-12px", borderRadius: 20 }}>
        <div style={{ height: 50, width: screens.xs ? '100%' : 400, padding: 10 }}>
          <Flex align="center" justify="space-between">
            <Space>
              <Avatar size="large" icon={<MdSms />}></Avatar>
              <Space direction="vertical" size={0}>
                <Title style={{ margin: 0 }} level={4}>
                  SMS credits
                </Title>
                <Space>
                  <Text>
                    {balances.sms?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  {balances.lastSmsFill && (
                    <>
                      <Text>|</Text>
                      <Text className="icon-flex">
                        <FaClock />
                        {new Date(balances.lastSmsFill).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}
                      </Text>
                    </>
                  )}
                  <Text>|</Text>
                  <Text ellipsis style={{ width: "70px" }}>
                    ≈ {Math.round(balances.sms / balances.smsPrice)}
                  </Text>
                </Space>
              </Space>
            </Space>

            <Button
              icon={<FiPlus style={{ fontSize: 20 }} />}
              shape="circle"
              size="large"
              className="flex-button"
              onClick={() => {
                setOpen(true);
                setType(3);
              }}
            ></Button>
          </Flex>
        </div>
      </Card>
      ),
    },
    {
      key: "2",
      label: (
        <Card size="small" style={{ marginLeft: "-12px", marginRight: "-12px", borderRadius: 20 }}>
          <div style={{ height: 50, width: screens.xs ? '100%' : 400, padding: 10 }}>
            <Flex align="center" justify="space-between">
              <Space>
                <Avatar size="large" icon={<MdEmail />}></Avatar>
                <Space direction="vertical" size={0}>
                  <Title style={{ margin: 0 }} level={4}>
                    Email credits
                  </Title>
                  <Space>
                    <Text>
                      {balances.email?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Text>

                    {balances.lastEmailFill && (
                      <>
                        <Text>|</Text>
                        <Text className="icon-flex">
                          <FaClock />
                          {new Date(balances.lastEmailFill).toLocaleDateString(
                            "en-US",
                            DateFormat
                          )}
                        </Text>
                      </>
                    )}

                    <Text>|</Text>
                    <Text>
                      ≈ {Math.round(balances.email / balances.emailPrice)}
                    </Text>
                  </Space>
                </Space>
              </Space>

              <Button
                icon={<FiPlus style={{ fontSize: 20 }} />}
                size="large"
                shape="circle"
                className="flex-button"
                onClick={() => {
                  setOpen(true);
                  setType(1);
                }}
              ></Button>
            </Flex>
          </div>
        </Card>
      ),
    },
    {
      key: "3",
      label: (<Card size="small" style={{ marginLeft: "-12px", marginRight: "-12px", borderRadius: 20 }}>
        <div style={{ height: 50, width: screens.xs ? '100%' : 400, padding: 10 }}>
          <Flex align="center" justify="space-between">
            <Space>
              <Avatar size="large" icon={<IoLogoWhatsapp />}></Avatar>
              <Space direction="vertical" size={0}>
                <Title style={{ margin: 0 }} level={4}>
                  Whatsapp credits
                </Title>
                <Space>
                  <Text>
                    {balances.whatsapp?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                  {balances.lastWhatsappFill && (
                    <>
                      <Text>|</Text>
                      <Text className="icon-flex">
                        <FaClock />
                        {new Date(balances.lastWhatsappFill).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}
                      </Text>
                    </>
                  )}
                  <Text>|</Text>
                  <Text>
                    ≈ {Math.round(balances.whatsapp / balances.whatsappPrice)}
                  </Text>
                </Space>
              </Space>
            </Space>

            <Button
              className="flex-button"
              size="large"
              shape="circle"
              onClick={() => {
                setOpen(true);
                setType(2);
              }}
              icon={<FiPlus style={{ fontSize: 20 }} />}
            ></Button>
          </Flex>
        </div></Card>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [type, setType] = useState();
  const [couponOpen, setCouponOpen] = useState(() => false);
  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <div
          style={{
            background: "#f4f7fe",
            padding: "5px 10px",
            borderRadius: "50px",
            cursor: "pointer",
            
          }}
        >
          <Flex align="center" justify="center" gap={5}>
            <Avatar
              size="small"
              icon={
                <FaDollarSign
                  style={{ color: "black" }}
                />
              }
              style={{ background: "white" }}
            />
            {!screens.xs && (
              <Text strong>
                Plan and usage
              </Text>
            )}

            <MdOutlineArrowDropDown style={{ fontSize: 22, marginLeft: -5 }} />
          </Flex>
        </div>
      </Dropdown>
      <BuyModal open={open} setOpen={setOpen} type={type} />
      <CouponModal open={couponOpen} setOpen={setCouponOpen} />
    </>
  );
};

const BuyModal = ({ open, setOpen, type }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

  const [prices, setPrices] = useState();
  const axios = useAxiosPrivate();
  const [amount, setAmount] = useState(() => 100);
  const [loading, setLoading] = useState(false);

  const { Title, Text } = Typography;

  useEffect(() => {

    const fetchBalances = async () => {
      const response = await axios.get("/Balances/prices");
      setPrices(response.data);

    }
    fetchBalances()
  }, []);

  const handlePayment = async () => {
    setLoading(true);
    const response = await axios.post("/Balances", {
      balanceType: type,
      amount: amount,
    });

    if (response.data.success) {
      const stripe = await stripePromise;

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      });

      if (result.error) {
        message.error(result.error.message);
      }
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        open={open}
        title=""
        onCancel={() => setOpen(false)}
        footer={
          <Flex
            align="center"
            justify="center"
            gap={10}
            style={{ marginTop: 40 }}
          >
            <Button
              type="primary"
              size="large"
              className="bold-button flex-button"
              onClick={() => handlePayment()}
              loading={loading}
              icon={<FaCartShopping />}
              disabled
            >
              Continue to payments
            </Button>

            <Popover
              trigger={["click"]}
              content={
                <Text>
                  Currently the monthly limit for{" "}
                  <Text strong>
                    {type == 3
                      ? "SMS MESSAGES"
                      : type == 1
                        ? "EMAILS"
                        : "WHATSAPP MESSAGES"}
                  </Text>{" "}
                  is{" "}
                  <Text strong>
                    {type == 3
                      ? prices?.smsLimit
                      : type == 1
                        ? prices?.emailLimit
                        : prices?.whatsappLimit}
                  </Text>{" "}
                  per month
                </Text>
              }
            >
              <Button
                shape="circle"
                size="large"
                className="flex-button"
                icon={<BsInfoLg style={{ fontSize: 20 }} />}
              ></Button>
            </Popover>
          </Flex>
        }
      >
        <Flex align="center" justify="center">
          <Space direction="vertical" align="center">
            <Text strong className="text-light">
              {amount ?? 0} *{" "}
              {type == 3
                ? `${prices?.smsPricesPer100}`
                : type == 1
                  ? `${prices?.emailPricesPer100}`
                  : `${prices?.whatsappPricesPer100}`}
            </Text>
            <Title
              style={{
                textAlign: "center",
                marginBottom: 0,
                fontSize: 67,
                marginTop: 0,
              }}
            >
              {type == 3 &&
                `${(prices?.smsPricesPer100 * amount).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}

              {type == 1 &&
                `${(prices?.emailPricesPer100 * amount).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`}

              {type == 2 &&
                `${(prices?.whatsappPricesPer100 * amount).toLocaleString(
                  "en-US",
                  {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}`}
            </Title>
            <Text strong>
              TOTAL PRICE OF{" "}
              {type == 3
                ? "SMS MESSAGES"
                : type == 1
                  ? "EMAILS"
                  : "WHATSAPP MESSAGES"}
            </Text>
            <InputNumber
              style={{ width: "100%", marginTop: 30 }}
              size="large"
              className="center-number-input"
              placeholder="Enter number of desired emails"
              value={amount}
              onChange={(e) => setAmount(e)}
            />
          </Space>
        </Flex>
      </Modal>
    </>
  );
};

const CouponModal = ({ open, setOpen }) => {
  const { Text, Title } = Typography;

  const { balances, setBalances } = useContext(BalanceContext);

  const [code, setCode] = useState(() => "");
  const [type, setType] = useState(() => 1);

  const [state, setState] = useState("entry");

  const [loading, setLoading] = useState(() => false);
  const axios = useAxiosPrivate();

  const [successValues, setSuccessValues] = useState(() => ({
    value: 0,
    name: "",
    type: 0,
  }));

  const submit = async () => {
    setLoading(true);

    const response = await axios.post(`/Coupon/submit`, {
      code: code,
      balanceType: type,
      forAudience: false,
    });

    if (response.data.success) {
      if (response.data.submited) {
        setState("success");
        setSuccessValues((prev) => ({
          value: response.data.value,
          couponName: response.data.couponName,
          type: response.data.balanceType,
        }));

        if (type == 1) {
          setBalances((prev) => ({
            ...prev,
            email: prev.email + response.data.value,
            lastEmailFill: new Date(),
          }));
        } else if (type == 2) {
          setBalances((prev) => ({
            ...prev,
            whatsapp: response.data.value + prev.whatsapp,
            lastWhatsappFill: new Date(),
          }));
        } else {
          setBalances((prev) => ({
            ...prev,
            sms: prev.sms + response.data.value,
            lastSmsFill: new Date(),
          }));
        }
      } else {
        message.error(response.data.message);
      }
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const resetState = () => {
    setState("entry");
    setCode("");
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Submit coupon
        </Title>
      }
      footer={
        state != "success" ? (
          <Flex align="center" justify="end" gap={12}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={() => submit()}>
              Submit code
            </Button>
          </Flex>
        ) : null
      }
    >
      {state == "entry" && (
        <Space direction="vertical" className="w-100">
          <Space size={0} direction="vertical" className="w-100">
            <Text>Code</Text>
            <Input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              size="large"
              placeholder="Enter coupon code"
            />
          </Space>

          <Space size={0} direction="vertical" className="w-100">
            <Text>Type</Text>
            <Select
              onChange={(e) => setType(e)}
              value={type}
              className="w-100"
              options={[
                {
                  value: 3,
                  label: "SMS",
                },
                {
                  value: 1,
                  label: "Email",
                },
                {
                  value: 2,
                  label: "Whatsapp",
                },
              ]}
              size="large"
              placeholder="Enter coupon code"
            />
          </Space>
        </Space>
      )}

      {state == "success" && (
        <CodeSuccess
          resetState={resetState}
          amount={successValues.value}
          name={successValues.couponName}
          type={successValues.type}
          setIsOpen={setOpen}
        />
      )}
    </Modal>
  );
};

const CodeSuccess = ({ amount, name, type, setIsOpen, resetState }) => {
  return (
    <>
      <Result
        status="success"
        title={`Coupon ${name} worth ${amount}$ was successful submited into your account`}
        subTitle={`Your ${type == 1 ? "Email" : type == 2 ? "SMS" : "Whatsapp"
          } credits were increased.`}
        extra={[
          <Button
            size="large"
            onClick={() => {
              setIsOpen(false);
              resetState();
            }}
          >
            Nice
          </Button>,
        ]}
      />
    </>
  );
};
export default Balance;
