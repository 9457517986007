import { useContext } from "react";
import { TemplateContext } from "../TemplateContext";
import DateFilter from "../../../shared/DateFilter";

const EmailTemplateToFilter = () => {
    const { filters, setFilters } = useContext(TemplateContext);
  return (
    <DateFilter filters={filters} setFilters={setFilters}/>
  );
}

export default EmailTemplateToFilter