import { useContext, useState } from "react";
import { Select } from "antd";
import { CampaignContext } from "../CampaignContext";
import { CampaignStatusType } from "../../../shared/enums";
import { MdArrowDropDown } from "react-icons/md";

const CampaignStatusSelect = () => {
  const { filters, setFilters } = useContext(CampaignContext);

  const [optons, setOptions] = useState([
    {
      value: CampaignStatusType.All,
      label: <p className="drop-down-text">All statuses</p>,
    },
    {
      value: CampaignStatusType.NotStarted,
      label: <p className="drop-down-text">Not started</p>,
    },
    {
      value: CampaignStatusType.OnGoing,
      label: <p className="drop-down-text">On going</p>,
    },
    {
      value: CampaignStatusType.Finished,
      label: <p className="drop-down-text">Finished</p>,
    },
    {
      value: CampaignStatusType.Invalid,
      label: <p className="drop-down-text">Invalid</p>,
    },
  ]);

  const handleChange = (e) => {
      setFilters((prev) => ({
        ...prev,
        status: e,
      }));
  };
  return (
      <Select
        size="large"
        defaultValue={filters?.status}
        placeholder="Select statuses to filter"
        onChange={(e) => handleChange(e)}
        options={optons}
        style={{width:200}}
        suffixIcon={
          <MdArrowDropDown style={{ fontSize: 20, color: "#283371" }} />
        }
      />
  );
};

export default CampaignStatusSelect;
