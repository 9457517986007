import { Button, Card, Flex, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { MdContactEmergency } from "react-icons/md";
import "./Contacts.css";
import { RiContactsFill } from "react-icons/ri";
import { FaCalendarAlt } from "react-icons/fa";

const Contacts = () => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const singal = controller.singal;

    const fetch = async () => {
      const response = await axios.get(`/Contacts/Total-Days`, {
        signal: singal,
      });

      setData(response.data);
      setLoading(false);
    };

    fetch();
    return () => controller.abort();
  }, []);
  const navigate = useNavigate();

  const navigateToContacts = () => {
    navigate("/contacts");
  };

  const navigateToImport = () => {
    navigate("contacts/import");
  };
  return (
    <Card size="small">
      <Space direction="vertical" size={24} className="w-100">
        <Flex align="center" justify="space-between" className="w-100">
          <Title level={4} className="zero-margin-padding">
            Your contacts
          </Title>

          <Button
            onClick={navigateToImport}
            type="link"
            className="bold"
            size="large"
          >
            Import contacts
          </Button>
        </Flex>

        <Space direction="vertical" size={12} className="w-100">
          <Card size="small" className="no-shadow contacts-home-card">
            <Flex align="center" justify="space-between">
              <Space size={0} direction="vertical">
                <Title className="zero-margin-padding" level={3}>
                  {data?.count}
                </Title>
                <Text>Total contacts</Text>
              </Space>
              <RiContactsFill className="contacts-home-icon" />
            </Flex>
          </Card>

          <Card className="no-shadow contacts-home-card" size="small">
            <Flex align="center" justify="space-between">
              <Space size={0} direction="vertical">
                <Title className="zero-margin-padding" level={3}>
                  {data?.last30DaysCount}
                </Title>
                <Text>In the last 30 days</Text>
              </Space>

              <FaCalendarAlt className="contacts-home-icon" />
            </Flex>
          </Card>
        </Space>
        <Flex align="center" justify="center">
          <Button
            onClick={navigateToContacts}
            type="link"
            className="bold"
            size="large"
          >
            Go to contacts
          </Button>
        </Flex>
      </Space>
    </Card>
  );
};
export default Contacts;
