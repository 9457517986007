import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json", 
    "Content-Encoding" :"identity" },
    withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json", 
             "Content-Encoding" :"identity" },
             withCredentials: true,
});