import React, { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Card,
  Col,
  Flex,
  Pagination,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { DateFormat } from "../../../dateformat";
import AvatarGroup from "../Kanban/AvatarGroup";
import ListOptions from "./ListOptions";
import TableLoading from "../../../shared/TableLoading";
import WonDealModal from "../WonDealModal";
import LostDealModal from "../LostDealModal";
import { CardStatus } from "../../../shared/enums";
import CreateDealDrawer from "../CreateDealDrawer/CreateDealDrawer";
import useDealsManagementCtx from "../../../hooks/useDealsManagementCtx";
import { DealListContext } from "../DealListContext";
import { SearchContext } from "../../../context/SearchContext";
import NoDataFound from "../../../shared/NoDataFound";
import "./List.css";
import SimplePagination from "../../../shared/SimplePagination";
const List = ({ showAdd, setShowAdd, dataToAdd }) => {
  const { setLoading, setIsDrawerOpen } = useDealsManagementCtx();
  const { setStats } = useContext(DealListContext);

  const { filters } = useContext(DealListContext);
  const { debouncedValue } = useContext(SearchContext);

  const { Text, Title } = Typography;
  const navigate = useNavigate();

  const [wonModal, setWonModal] = useState(() => false);
  const [lostModal, setLostModal] = useState(() => false);
  const [current, setCurrent] = useState();

  const axios = useAxiosPrivate();
  const [deals, setDeals] = useState();
  const [listLoading, setListLoading] = useState(() => false);
  const [page, setPage] = useState(() => 1);
  const perPage = 5;
  const [count, setCount] = useState(() => 0);

  const {authorId } = useParams();

  useEffect(() => {
    const fetch = async () => {
      setListLoading(true);
      const response = await axios.post(`/Deal/List`, {
        page: 1,
        authorId: authorId,
        pageSize: perPage,
        from: filters.createdFromDate,
        to: filters.createdToDate,
        productIds: filters.selectedProducts,
        companyIds: filters.selectedCompanies,
        dealStages: filters.selectedStatuses.map((c) => c.id),
        name: debouncedValue,
      });

      setCount(response.data.count);
      setDeals(response.data.data);
      setListLoading(false);
    };
    fetch();
  }, [filters, debouncedValue]);

  useEffect(() => {
    const fetch = async () => {
      setListLoading(true);
      const response = await axios.post(`/Deal/List`, {
        page: page,
        pageSize: perPage,
        authorId: authorId,
        from: filters.createdFromDate,
        to: filters.createdToDate,
        productIds: filters.selectedProducts,
        companyIds: filters.selectedCompanies,
        dealStages: filters.selectedStatuses.map((c) => c.id),
        name: debouncedValue,
      });

      setCount(response.data.count);
      setDeals(response.data.data);
      setListLoading(false);
    };
    fetch();
  }, [page]);

  const getStage = (stage) => {
    if (stage == 1) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="gray">
          New
        </Tag>
      );
    } else if (stage == 2) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="blue">
          Qualifying
        </Tag>
      );
    } else if (stage == 3) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="cyan">
          Demo Scheduled
        </Tag>
      );
    } else if (stage == 4) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="lime">
          Pending Commitment
        </Tag>
      );
    } else if (stage == 5) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="geekblue">
          In Negotiation
        </Tag>
      );
    } else if (stage == 6) {
      return (
        <Tag className="deal-list-tag" bordered={false} color="green">
          Won
        </Tag>
      );
    } else {
      return (
        <Tag className="deal-list-tag" color="red" bordered={false}>
          Lost
        </Tag>
      );
    }
  };

  const handleDelete = (id) => {
    setCount((prev) => prev - 1);

    setDeals((prev) => prev.filter((c) => c.id != id));
  };

  const onSetToWon = async (e) => {
    const response = await axios.post("/Deal/UpdateDealStatus", {
      id: current,
      newStage: CardStatus.Won,
      closeDate: e.closeDate,
      totalRevenue: e.revenue,
      reason: e.closedReason,
    });

    if (response.data.success) {
      const deal = deals.find((c) => c.id == current);
      setStats((prev) => ({
        ...prev,
        new: deal.stage == CardStatus.New ? prev.new - 1 : prev.new,
        qualifying:
          deal.stage == CardStatus.Qualifying
            ? prev.qualifying - 1
            : prev.qualifying,
        demoScheduled:
          deal.stage == CardStatus.DemoScheduled
            ? prev.demoScheduled - 1
            : prev.demoScheduled,
        pendingCommitment:
          deal.stage == CardStatus.PendingCommitment
            ? prev.pendingCommitment - 1
            : prev.pendingCommitment,
        inNegotiation:
          deal.stage == CardStatus.InNegotiation
            ? prev.inNegotiation - 1
            : prev.inNegotiation,
        won: prev.won + 1,
        lost: deal.stage == CardStatus.Closed ? prev.lost - 1 : prev.lost,
      }));

      setDeals((prev) =>
        prev.map((c) => (c.id == current ? { ...c, stage: CardStatus.Won } : c))
      );
      setWonModal(false);
    } else {
      message.error(response.data.message);
    }
  };

  const onSetToLost = async (e) => {
    const response = await axios.post("/Deal/UpdateDealStatus", {
      id: current,
      newStage: CardStatus.Closed,
      lostReason: e.reason,
      additionalComments: e.additionalComments,
    });

    if (response.data.success) {
      const deal = deals.find((c) => c.id == current);

      setStats((prev) => ({
        ...prev,
        new: deal.stage == CardStatus.New ? prev.new - 1 : prev.new,
        qualifying:
          deal.stage == CardStatus.Qualifying
            ? prev.qualifying - 1
            : prev.qualifying,
        demoScheduled:
          deal.stage == CardStatus.DemoScheduled
            ? prev.demoScheduled - 1
            : prev.demoScheduled,
        pendingCommitment:
          deal.stage == CardStatus.PendingCommitment
            ? prev.pendingCommitment - 1
            : prev.pendingCommitment,
        inNegotiation:
          deal.stage == CardStatus.InNegotiation
            ? prev.inNegotiation - 1
            : prev.inNegotiation,
        won: deal.stage == CardStatus.Won ? prev.won - 1 : prev.won,
        lost: prev.lost + 1,
      }));

      setDeals((prev) =>
        prev.map((c) =>
          c.id == current ? { ...c, stage: CardStatus.Closed } : c
        )
      );
      setLostModal(false);
    } else {
      message.error(response.data.message);
    }
  };

  const onSubmit = async (e) => {
    setLoading(true);
    const response = await axios.post("/Deal/CreateDeal", {
      emailId: e.contacts,
      companyIds: e.companies,
      stage: e.status,
      ownerIds: e.assignedUser,
      amount: e.amount,
      closeDate: e.closeDate,
      title: e.title,
      productIds: e.products,
    });

    if (response.data.success) {
      setCount((prev) => prev + 1);
      setDeals((prev) => [
        ...prev,
        {
          title: response.data.title,
          stage: response.data.stage,
          products: response.data.products,
          closeDate: response.data.closeDate,
          owners: response.data.owners,
          amount: response.data.amount,
          id: response.data.id,
        },
      ]);

      setShowAdd(false);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if(dataToAdd){
      onSubmit(dataToAdd)
    }
  },[dataToAdd])
  return (
    <>
      <WonDealModal
        open={wonModal}
        onClose={() => setWonModal(false)}
        onSubmit={onSetToWon}
      />
      <LostDealModal
        open={lostModal}
        onClose={() => setLostModal(false)}
        onSubmit={onSetToLost}
      />

      <Space direction="vertical" className="w-100">
        {listLoading ? (
          <TableLoading />
        ) : !deals || deals.length == 0 ? (
          <NoDataFound />
        ) : (
          <>
            <SimplePagination
              page={page}
              pageSize={perPage}
              total={count}
              setPage={setPage}
            />
            {deals.map((c, i) => (
              <Card
                key={i}
                size="small"
                className="no-shadow gray-border-hoverable"
              >
                <Row>
                  <Col span={20}>
                    <Space direction="vertical" size={0}>
                      <Flex align="center" justify="start" gap={6}>
                        <Title
                          className="zero-margin-padding deal-list-title"
                          onClick={() => navigate(`/Deals/Details/${c.id}`)}
                        >
                          {c.title}
                        </Title>
                        {getStage(c.stage)}
                      </Flex>

                      <Flex align="center" justify="start" gap={6}>
                        {c.closeDate && (
                          <Text>
                            Close Date:{" "}
                            {new Date(c.closeDate).toLocaleDateString(
                              "en-US",
                              DateFormat
                            )}
                          </Text>
                        )}

                        <Text>
                          • Amount:{" "}
                          {c?.amount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Text>
                      </Flex>
                      <Text>
                        {c.products?.length > 0 && (
                          <Text>
                            Products:
                            {c.products?.length <= 1
                              ? ` ${c.products[0].name}`
                              : ` ${c.products[0].name}, `}
                          </Text>
                        )}
                        {c.products?.length > 1 ? (
                          <Text>+{c.products?.length - 1} more...</Text>
                        ) : (
                          ""
                        )}
                      </Text>

                      <AvatarGroup size="small" items={c.owners} />
                    </Space>
                  </Col>

                  <Col span={4}>
                    <Flex className="w-100 h-100" align="center" justify="end">
                      <ListOptions
                        id={c.id}
                        deal={c}
                        onDelete={handleDelete}
                        setWonModal={setWonModal}
                        setLostModal={setLostModal}
                        setCurrent={setCurrent}
                      />
                    </Flex>
                  </Col>
                </Row>
              </Card>
            ))}

            <SimplePagination
              page={page}
              pageSize={perPage}
              total={count}
              setPage={setPage}
            />
          </>
        )}
      </Space>

      <CreateDealDrawer
        onSubmit={onSubmit}
        isDrawerOpen={showAdd}
        setIsDrawerOpen={setShowAdd}
      />
    </>
  );
};

export default List;
