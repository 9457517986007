import {
  Button,
  Card,
  Col,
  Flex,
  message,
  Pagination,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import TemplateStats from "./TemplateStats";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailTemplateToFilter from "./Filters/EmailTemplateToFilter";
import { DateFormat } from "../../dateformat";
import { TemplateContext } from "./TemplateContext";
import TableLoading from "../../shared/TableLoading";
import NoDataFound from "../../shared/NoDataFound";
import "./TemplateList.css";
import EmailTemplateOptions from "./EmailTemplateOptions";
import PreviewTemplateModal from "./PreviewTemplateModal";
import { EmailTemplateTypes } from "../../shared/enums";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import ShareTemplateModal from "./ShareTemplateModal";
import { FaEye } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import SimplePagination from "../../shared/SimplePagination";

const TemplateList = () => {
  const { Text } = Typography;

  const { stats, setStats, templates, setTemplates, loading, page, setPage, pageSize, setPageSize } =
    useContext(TemplateContext);
  const navigate = useNavigate();

  const handleClick = (id, simple) => {
    if (simple) {
      navigate(`/Templates/EditSimpleEmail/${id}`);
    } else {
      navigate(`/Templates/Edit/${id}`);
    }
  };

  const [previewOpen, setPreviewOpen] = useState(false);

  const [current, setCurrent] = useState();

  const openPreview = (id, simple) => {
    setCurrent(id);
    setPreviewOpen(true);
  };

  const axios = useAxiosPrivate();

  const handleDubplicate = async (id) => {
    const response = await axios.post(`/Templates/Email/Duplicate`, {
      id: id,
    });

    if (response.data.success) {
      message.success("Selected design was duplicated!");

      setTemplates((prev) => [response.data, ...prev]);
      setStats((prev) => ({ ...prev, total: prev.total + 1 }));
    } else {
      message.error(response.data.message);
    }
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDelete = async (item, confirmed) => {
    if (!confirmed) {
      return;
    }

    const response = await axios.delete(`/Templates/Email/${item}`);
    if (response.data.success) {
      setStats((prev) => ({ ...prev, total: prev.total - 1 }));
      setTemplates((prev) => prev.filter((c) => c.id != item));
    } else {
      message.error(response.data.message);
    }
  };

  const handleDeleteClicked = (id) => {
    setCurrent(id);
    setDeleteOpen(true);
  };

  const [shareOpen, setShareOpen] = useState(false);
  const handleShare = (id) => {
    setShareOpen(true);
    setCurrent(id);
  };
  return (
    <>
      <ShareTemplateModal
        id={current}
        open={shareOpen}
        setOpen={setShareOpen}
      />
      <DeleteConfirmationModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDeleteConfirm={handleDelete}
        item={current}
      />

      <PreviewTemplateModal
        open={previewOpen}
        setOpen={setPreviewOpen}
        id={current}
        type={EmailTemplateTypes.UserCreated}
      />
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <Flex align="center" justify="space-between">
          <TemplateStats />

          <Button
            type="link"
            className="large-font no-left-padding-margin"
            onClick={() => navigate("/Template/Components")}
          >
            Components
          </Button>
        </Flex>

        <Flex align="center" justify="space-between">
          <EmailTemplateToFilter />
          <SimplePagination page={page} pageSize={pageSize} total={stats?.total} setPage={setPage}/>
        </Flex>
        <Space className="w-100" direction="vertical">
          {loading ? (
            <TableLoading />
          ) : !templates || templates?.length == 0 ? (
              <NoDataFound
                description="There is no desing found on your database! To add a new email desing click the button below!"
                title="No email designs found"
                addText="Add desing"
              />
          ) : (
            <>
              {templates?.map((c, i) => (
                <Card
                  className="no-shadow gray-border-hoverable"
                  key={i}
                  size="small"
                >
                  <Row gutter={[6, 6]}>
                    <Col span={20}>
                      <Space direction="vertical" size={0}>
                        <Text
                          onClick={() => handleClick(c.id, c.simple)}
                          type="link"
                          className="template-name"
                        >
                          {c.name}
                        </Text>
                        <Text>
                        {`#${(page - 1) * pageSize + i + 1} • `} 
                          {new Date(c.createdAt * 1000).toLocaleDateString(
                            "en-us",
                            DateFormat
                          )}
                          {c.lastEdited ? (
                            <Text>
                              {" "}
                              • Last edited at{" "}
                              {new Date(c.lastEdited * 1000).toLocaleDateString(
                                "en-us",
                                DateFormat
                              )}
                            </Text>
                          ) : (
                            ""
                          )}
                        </Text>

                        <Text>{c.simple ? "Simple" : "Advanced & Templated"}</Text>
                      </Space>
                    </Col>
                    <Col span={4}>
                      <Flex
                        gap={12}
                        className="h-100"
                        align="center"
                        justify="end"
                      >
                        <Tooltip title="Edit">
                          <Button
                            size="large"
                            className="flex-button"
                            onClick={() => handleClick(c.id, c.simple)}
                            type="link"
                            icon={
                              <AiFillEdit className="template-options-icon" />
                            }
                          ></Button>
                        </Tooltip>

                        <Tooltip title="Preview">
                          <Button
                            size="large"
                            className="flex-button"
                            onClick={() => openPreview(c.id)}
                            type="text"
                            icon={<FaEye className="template-options-icon" />}
                          ></Button>
                        </Tooltip>
                        <EmailTemplateOptions
                          id={c.id}
                          onShare={handleShare}
                          onDelete={handleDeleteClicked}
                          duplicate={handleDubplicate}
                          simple={c.simple}
                          openPreview={openPreview}
                          handleUpdate={handleClick}
                        />
                      </Flex>
                    </Col>
                  </Row>
                </Card>
              ))}

            <SimplePagination showChanger setPerPage={setPageSize} page={page} pageSize={pageSize} total={stats?.total} setPage={setPage}/>
            </>
          )}
        </Space>
      </Space>
    </>
  );
};

export default TemplateList;
