import { Button, Drawer, Flex, Form, message, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const EditCommentDrawer = ({ comment, open, setOpen, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const { Text } = Typography;
  const [form] = Form.useForm();
    const axios = useAxiosPrivate()

  useEffect(() => {
    if (!comment) {
      return;
    }

    form.setFieldValue("comment", comment.comment);
  }, [comment]);

  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.put(`Email-Comment`, {
        comment: e.comment,
        id: comment.id
    })

    if(response.data.success){
        onUpdate(comment.id, e.comment)
        form.resetFields()
        setOpen(false)
    }else{
        message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Drawer title="Edit comment" open={open} onClose={() => setOpen(false)}>
        <Flex
          style={{ height: "100%" }}
          vertical
          align="start"
          justify="space-between"
        >
          <Form form={form} onFinish={onSubmit} className="w-100">
            <Space className="w-100" size={0} direction="vertical">
              <Text>Comment</Text>
              <Form.Item className="w-100"
                name="comment"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 420,
                  },
                  {
                    min: 3,
                  },
                ]}
              >
                <TextArea
                  size="large"
                  rows={4}
                  placeholder="Enter comment"
                  count={{
                    show: true,
                    max: 420,
                  }}
                ></TextArea>
              </Form.Item>
            </Space>
          </Form>

          <Flex className="w-100" align="center" justify="end" gap={6}>
            <Button
               className="new-buttons large-font"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => form.submit()}
               className="new-buttons large-font"
              type="primary"
            >
              Save changes
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default EditCommentDrawer;
