import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
const BlockUser = ({ user, handleBlocked }) => {
  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const blockUser = async () => {
    setLoading(true);
    const response = await axios.post(`/Users/BlockUser`, {
      id: user,
    });

    if (response && response.data.success) {
      message.success("Nice, user locked");
      handleBlocked(user);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Popconfirm
      onConfirm={blockUser}
      title="Ban current user?"
      description="Are you sure you want to block the selected user?"
      okText="Yes, ban!"
    >
      <p className="drop-down-text">Block</p>
    </Popconfirm>
  );
};

export default BlockUser;
