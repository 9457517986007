import { Button, Flex, message, Modal, Typography } from "antd";
import React, { useState } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import { Editor } from "@tinymce/tinymce-react";

const AddNote = ({ open, setOpen }) => {
  
  const { Title } = Typography;

  const { setDealDetails } = useDealsDetailsCtx();

  const [editorState, setEditorState] = useState('');
  const axios = useAxiosPrivate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const saveNote = async () => {
    setLoading(true);

    const response = await axios.post("/Deal/AddNote", {
      dealId: id,
      note: editorState,
    });

    if (response.data.success) {
      setDealDetails((prev) => ({
        ...prev,
        note: editorState,
      }));
      setOpen(false);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      width={500}
      onCancel={() => setOpen(false)}
      title={
        <Title className="zero-margin-padding" level={4}>
          Create note for deal
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button size="large" className="large-font new-button" onClick={() => setOpen(false)}>Cancel</Button>
          <Button size="large" className="large-font new-button" loading={loading} onClick={() => saveNote()} type="primary">
            Save changes
          </Button>
        </Flex>
      }
    >
      <Editor
        className="subject-text-box"
        apiKey="yrslnepj7mnu9r2x56p8a59mpo8686tnuf3hvph3xull4yxw"
        onEditorChange={(e) => setEditorState(e)}
        value={editorState}
        init={{
          plugins: "",
          toolbar: true,
          menubar: false,
          height: 250,
          statusbar: false,
          allow_script_urls: true,
        }}
      />
    </Modal>
  );
};

export default AddNote;
